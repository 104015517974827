import {Defs, LinearGradient, Rect, Stop, Svg} from "react-native-svg";
import React from "react";
import {StyleProp, ViewStyle} from "react-native";

let id_daily = 0

export default function GradientBox(props: {
    width: number,
    height: number,
    stop1: string,
    stop2: string,
    style?: StyleProp<ViewStyle> | undefined
}) {
    id_daily++
    return <Svg width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`} fill="none"
                style={props.style}>
        <Defs>
            <LinearGradient id={id_daily + ''}
                            x1="40" y1="0"
                            x2="40" y2={props.height}
                            gradientUnits="userSpaceOnUse">
                <Stop stopColor={props.stop1}/>
                <Stop offset="1" stopColor={props.stop2}/>
            </LinearGradient>
        </Defs>

        <Rect
            x={0}
            y={0}
            height={props.height}
            width={props.width}
            fill={`url(#${id_daily})`}/>
    </Svg>
}
import {BattleStatus, FantasyInfo, FantasyRateRow, FantasyStatus, Fight, Fighter, UserCard} from '../types';
import React from 'react';
import {Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import TRANSLATES from '../translates/translates';
import {COLORS, MAIN_BAR_COLOR, ORANGE_COLOR} from '../colors';
import TPressable from "../controls/TPressable";
import {OnBoardingStyles} from "../Onboarding";
import {GradientButton} from "../controls/GradientButton";
import ToolTipBox from "../controls/ToolTipBox";
import {getFlagUrl} from "../func";
import WinBlock from "./WinBlock";

interface IProps {
    fight: Fight,
    redClick?: Function,
    blueClick?: Function,
    fantasy?: FantasyInfo,
    team?: FantasyRateRow[],
    userCards?: UserCard[],
    preStatClick?: Function,
    liveStatClick?: Function,
    onBoardStep?: number | null
    hasStat?: boolean
}

interface IState {

}


export default class BattleItem extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    getRecordString(fighter: Fighter) {
        return `${fighter.record.Wins}-${fighter.record.Losses}-${fighter.record.Draws}`;
    }

    renderLiveStatsButton() {
        if (this.props.fight.hasStat === false)
            return null

        return <GradientButton onClick={() => this.props.liveStatClick && this.props.liveStatClick(this.props.fight)}
                               height={35}
                               type={'radial'}
                               stop1="#405CBF"
                               stop2="#7C92E0"
                               width={Dimensions.get('window').width - 64}>
            <Image style={{
                height: 24,
                width: 24
            }} source={require('../icons/column_chart.png')}/>
            <Text style={styles.buttonTextStyle}>{TRANSLATES['LiveStats']}</Text>
        </GradientButton>
    }

    render() {
        let item = this.props.fight;
        let redPoint = this.props.fantasy?.Points?.find(p => p.GAthleteId == item.red.id) as FantasyRateRow;
        let bluePoint = this.props.fantasy?.Points?.find(p => p.GAthleteId == item.blue.id) as FantasyRateRow;

        let btn;

        if (this.props.fantasy) {
            switch (this.props.fight.status) {
                case BattleStatus.Finished:
                case BattleStatus.InProgress:
                    btn = this.renderLiveStatsButton()
                    break
                case BattleStatus.Canceled:
                case BattleStatus.NoContest:
                    btn = <View style={{height: 12}}/>
                    break;
                default:
                    btn = <GradientButton onClick={() => this.props.preStatClick && this.props.preStatClick()}
                                          height={35}
                                          type={'radial'}
                                          style={this.props.fantasy.Status == FantasyStatus.Open && this.props.onBoardStep == 2 && OnBoardingStyles.highlight}
                                          stop1="#405CBF"
                                          stop2="#7C92E0"
                                          width={Dimensions.get('window').width - 64}
                    >
                        <Image style={{
                            height: 24,
                            width: 24
                        }} source={require('../icons/column_chart.png')}/>
                        <Text style={styles.buttonTextStyle}>{TRANSLATES['PreStats']}</Text>
                    </GradientButton>
                    break;
            }
        }
        else if (item.status == BattleStatus.Finished) {
            btn = this.renderLiveStatsButton()
        }

        let redSelected = redPoint ? this.props.team?.find(it => it.AthleteId == redPoint.AthleteId) : null
        let blueSelected = bluePoint ? this.props.team?.find(it => it.AthleteId == bluePoint.AthleteId) : null;

        return <View style={[styles.root, item.status == BattleStatus.InProgress && styles.inProgressBorder]}>
            {this.getStatusText(item, !!(redSelected || blueSelected))}
            <View style={styles.content}>
                {this.renderLeft(redPoint, this.props.fight.red, redSelected)}
                {this.renderRight(bluePoint, this.props.fight.blue, blueSelected)}
            </View>
            {!this.props.fantasy && <View style={{height: 12, width: '100%'}}/>}

             {btn && <View style={{margin: 16}}>
                {btn}
            </View>
             }
        </View>
    }

    getStatusText(item: Fight, hasSelected: boolean) {
        let style = [styles.status] as any[];
        switch (item.status) {
            case BattleStatus.Canceled:
            case BattleStatus.Finished:
            case BattleStatus.NoContest:
                style.push(styles.statusFinished);
                break;
            case BattleStatus.InProgress:
                style.push(styles.statusInProgress);
                break;
        }

        if (this.props.fantasy?.Status == FantasyStatus.Open) {
            if (hasSelected)
                return <Text style={styles.status}>{TRANSLATES['battle-item.pick-made']}</Text>
            else
                return <Text style={[styles.status, styles.statusInProgress]}>{TRANSLATES['battle-item.who-win']}</Text>
        }

        return <Text style={style}>{(TRANSLATES as any)[item.status]}</Text>
    }


    renderPick(a: Fighter, boughtAthlete?: FantasyRateRow | null) {
        // if (!this.props.fantasy)
        // 	return;

        switch (this.props.fight.status) {

            case BattleStatus.NoContest:
                return;
            case BattleStatus.Finished:
                let rk;
                if (this.props.fight.winner == 'draw') {
                    rk = "Draw";
                } else if (this.props.fight.winner) {
                    if (this.props.fight.winner == a.id)
                        rk = 'Win';
                    else if (this.props.fight.winner)
                        rk = 'Loss';
                }

                return <View style={[styles.button, boughtAthlete && styles.selectedButton]}>
                    {rk && <Text style={styles.buttonText}>{(TRANSLATES as any)[rk]}</Text>}
                </View>
        }

        let athleteCard = this.props.userCards?.find(it => it.exCardData == a.key)
        let oppositeFighterId = this.props.fight.red.id == a.id ? this.props.fight.blue.id : this.props.fight.red.id

        let oppositePoint = this.props.team?.find(it => it.GAthleteId == oppositeFighterId)


        let openContent = <>
            <Text
                style={styles.buttonText}>{boughtAthlete ? TRANSLATES['Picked'] : (this.props.fantasy?.Status == FantasyStatus.Open && !oppositePoint && TRANSLATES['Pick'])}</Text>
            {athleteCard && <Text style={[styles.coefText]}>{athleteCard.statusCoef}x</Text>}
        </>

        let isBlue = boughtAthlete != null
        if (!isBlue && this.props.fantasy?.Status == FantasyStatus.Open) {

            if (!oppositePoint)
                isBlue = true
        }

        return <View style={[styles.button, isBlue && styles.selectedButton]}>
            {openContent}
        </View>
    }

    getColorRating(value: number) {
        let colors = [
            '#FF2E00',
            '#FF9400',
            '#FFB800',
            '#FFE500',
            '#9CD12C',
            '#2CD146',
            '#2CD196',
            '#2CC7D1',
            '#2C96D1',
            '#2C6ED1',
            '#405CBF'
        ]


        // 5.0 до 5.3 = цвет 5.0
        if (value < 5.4)
            return colors[0]

        // 5.4 до 5.7 = цвет 5.5
        if (value < 5.8)
            return colors[1]

        // 5.8 до 6.3 = цвет 6.0
        if (value < 6.4)
            return colors[2]

        // 6.4 до 6.7 = цвет 6.5
        if (value < 6.8)
            return colors[3]

        // 6.8 до 7.3 = цвет 7.0
        if (value < 7.4)
            return colors[4]


        // 7.4 до 7.7 = цвет 7.5
        if (value < 7.8)
            return colors[5]

        // 7.8 до 8.2 = цвет 8.0
        if (value < 8.3)
            return colors[6]

        // 8.3 до 8.7 = цвет 8.5
        if (value < 8.8)
            return colors[7]


        // 8.8 до 9.2 = цвет 9:0
        if (value < 9.3)
            return colors[8]


        // 9.3 до 9.7 = цвет 9.5
        if (value < 9.8)
            return colors[9]


        // 9.8 до 10 = цвет 10
        return '#405CBF'
    }

    renderBattleRating(value: number) {
        if (!value)
            return null
        return <ToolTipBox
            text={TRANSLATES['battles.rating']}
            style={{
                padding: 8,
                borderRadius: 10,
                backgroundColor: this.getColorRating(value)
            }}>
            <Text style={{
                color: '#fff',
                fontSize: 10,
                fontWeight: 'bold'
            }}>{value.toFixed(1)}</Text>
        </ToolTipBox>
    }

    renderRight(point: FantasyRateRow, a: Fighter, boughtAthlete?: FantasyRateRow | null) {
        return <View style={{position: 'relative'}}>

            <TPressable name="pickLeftAthlete" style={[styles.itemRight, boughtAthlete && styles.selectedItem]}
                        onPress={() => this.props.blueClick && this.props.blueClick()}>

                {this.props.fantasy && this.props.fantasy?.Status == FantasyStatus.Open && <View style={[{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 7,
                }, this.props.onBoardStep == 1 && OnBoardingStyles.highlight]}>
                    <Text style={styles.cost}>{point?.Points}</Text>
                    <Text style={[styles.costText, styles.marginLeft, boughtAthlete && styles.selectedText]}>
                        {TRANSLATES['FantasyCost']}
                    </Text>

                </View>}
                {this.props.fantasy && <View style={[styles.separator, styles.marginLeft]}/>}
                <View style={[styles.fighterContent, styles.marginLeft, styles.fighterRightContent]}>
                    {this.renderFighterName(a, !!boughtAthlete)}
                    {this.getAthleteImage(a, styles.rightImage)}
                </View>
                {this.renderPick(a, boughtAthlete)}
            </TPressable>
            {boughtAthlete?.CalcLog && this.renderResultCoins(boughtAthlete, true)}
            {this.props.fight.winner == a.id && <WinBlock fight={this.props.fight}/> }
            <View style={{position: 'absolute', right: 0, zIndex: 1, top: -14}}>
                {this.renderBattleRating(this.props.fight.blueRating)}
            </View>
        </View>
    }

    renderResultCoins(boughtAthlete: FantasyRateRow, isBlue: boolean) {
        let coinsText = boughtAthlete.CalcPoints < 0 ? boughtAthlete.CalcPoints : '+' + boughtAthlete.CalcPoints;
        return <TPressable
            style={[styles.resultCoins, this.props.fantasy?.Status == FantasyStatus.Calculated && this.props.onBoardStep == 1 && OnBoardingStyles.highlight]}
            name="resultCoins"
            onPress={() => isBlue ? this.props.blueClick && this.props.blueClick() : this.props.redClick && this.props.redClick()}>
            <Text style={styles.resultCoinsText}>{coinsText} {TRANSLATES['Coins']}</Text>
        </TPressable>
    }

    getAthleteImage(f: Fighter, style: any) {
        return <Image
            source={(f.avatarUrl || f.photoUrl) ? {uri: f.avatarUrl || f.photoUrl} : require('../assets/athlete_shadow.png')}
            style={[styles.athleteImage, style]}/>
    }

    // renderRating(isLeft: boolean, value: number)
    // {
    // 	return <View style={styles.ratingView}>
    // 		<Image source={require('../assets/rating.png')} style={styles.ratingImg}/>
    // 		<Text style={styles.ratingText}>{value}</Text>
    // 	</View>
    // }

    renderLeft(point: FantasyRateRow, a: Fighter, boughtAthlete?: FantasyRateRow | null) {
        return <View>
            <TPressable name="pickRightAthlete"
                        style={[styles.itemLeft, boughtAthlete && styles.selectedItem, this.props.onBoardStep == 3 && OnBoardingStyles.highlight]}
                        onPress={() => this.props.redClick && this.props.redClick()}>
                {this.props.fantasy && this.props.fantasy?.Status == FantasyStatus.Open &&
                    <View style={[{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 7},
                        this.props.onBoardStep == 1 && OnBoardingStyles.highlight]}>
                        <Text style={[styles.costText, styles.marginLeft, boughtAthlete && styles.selectedText]}>
                            {TRANSLATES['FantasyCost']}
                        </Text>
                        <Text style={styles.cost}>{point?.Points}</Text>
                    </View>
                }
                {this.props.fantasy && <View style={[styles.separator, styles.marginRight]}/>}
                <View style={[styles.fighterContent, styles.marginRight, styles.fighterLeftContent]}>
                    {this.getAthleteImage(a, styles.leftImage)}
                    {this.renderFighterName(a, !!boughtAthlete, true)}
                </View>
                {this.renderPick(a, boughtAthlete)}

            </TPressable>

            <View style={{position: 'absolute', left: 0, zIndex: 1, top: -14}}>
                {this.renderBattleRating(this.props.fight.redRating)}
            </View>
            {boughtAthlete?.CalcLog && this.renderResultCoins(boughtAthlete, false)}

            {this.props.fight.winner == a.id && <WinBlock fight={this.props.fight}/> }
        </View>
    }





    renderFighterName(a: Fighter, selected: boolean, isLeftText: boolean = false) {
        let flagUrl = getFlagUrl(a.countryISO)

        let flagDivs = isLeftText ?
            <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Text
                    style={[styles.name, selected && styles.selectedText, isLeftText && styles.rightTextAlign]}>{a.firstName}</Text>
                {flagUrl && <Image source={{uri: flagUrl}} style={{width: 18, height: 10, marginLeft: 4}}/>}
            </View>
            :
            <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                {flagUrl && <Image source={{uri: flagUrl}} style={{width: 18, height: 10, marginRight: 4}}/>}
                <Text
                    style={[styles.name, selected && styles.selectedText, isLeftText && styles.rightTextAlign]}>{a.firstName}</Text>
            </View>

        return <View>
            {/*<Text style={[styles.record, selected && styles.selectedText, isLeftText && styles.rightTextAlign]}>*/}
            {/*	{this.getRecordString(a)}</Text>*/}
            {this.renderRating(a, isLeftText, selected)}
            <Text style={[styles.nickname, selected && styles.selectedText, isLeftText && styles.rightTextAlign]}>
                '{a.nickname}'
            </Text>
            {flagDivs}
            <Text
                style={[styles.name, selected && styles.selectedText, isLeftText && styles.rightTextAlign]}>{a.lastName}</Text>
        </View>;
    }

    renderRating(a: Fighter, isLeft: boolean, selected: boolean) {
        if (!this.props.fantasy)
            return;

        return isLeft ? <View style={[styles.ratingView, {justifyContent: 'flex-end'}]}>
            <Text
                style={[styles.ratingText, selected && {color: '#fff'}]}>{a.rating.total || a.rating.totalRecord}</Text>
            <Image source={require('../assets/rating.png')} style={[styles.ratingImg, {marginLeft: 4}]}/>
        </View> : <View style={[styles.ratingView, {justifyContent: 'flex-start'}]}>
            <Image source={require('../assets/rating.png')} style={[styles.ratingImg, {marginRight: 4}]}/>
            <Text
                style={[styles.ratingText, selected && {color: '#fff'}]}>{a.rating.total || a.rating.totalRecord}</Text>
        </View>
    }

}


const styles = StyleSheet.create({
    resultCoins: {
        marginTop: 8,
        justifyContent: 'center',
        alignItems: 'center',
        height: 20,
        width: '100%',
        backgroundColor: ORANGE_COLOR,
        borderRadius: 10,
    },
    resultCoinsText: {
        fontWeight: '700',
        fontSize: 10,
        color: '#FFFFFF',
    },

    rightTextAlign: {
        textAlign: 'right',
    },
    statusFinished: {
        backgroundColor: MAIN_BAR_COLOR,
    },
    statusInProgress: {
        backgroundColor: ORANGE_COLOR,
    },
    status: {
        backgroundColor: '#CACDDE',
        height: 24,
        lineHeight: 24,
        color: '#ffffff',
        fontSize: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
    leftImage: {
        left: 14,
    },
    rightImage: {
        right: 14,
    },
    athleteImage: {
        top: 0,
        width: 40,
        height: 60,
        position: 'absolute',
    },
    nickname: {
        color: '#797B87',
        fontSize: 8,
    },
    name: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
    },
    record: {
        color: '#2E2D2D', fontSize: 10,
    },
    button: {
        marginTop: 'auto',
        width: 144,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#CACDDE',
    },
    selectedButton: {
        backgroundColor: MAIN_BAR_COLOR,
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: 10,
    },
    separator: {
        height: 1,
        width: 130,
        marginTop: 4,
        opacity: 0.7,
        backgroundColor: '#CACDDE',
    },
    fighterContent: {
        position: 'relative',
        marginLeft: 7,
        marginTop: 3,
        width: 144,
        display: 'flex',
        alignItems: 'flex-end',
    },
    fighterLeftContent: {
        alignItems: 'flex-end',
    },
    fighterRightContent: {
        alignItems: 'flex-start',
    },
    itemLeft: {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundColor: '#EFF1F7',
        borderRadius: 10,
        width: 144,
        height: 119,
        overflow: 'hidden',
        paddingTop: 7,
        borderWidth: 2,
        borderColor: 'transparent'
    },
    marginRight: {
        marginRight: 7,
    },
    marginLeft: {
        marginLeft: 7,
    },
    costText: {
        marginRight: 7,
        color: '#2E2D2D',
        fontSize: 10,
    },
    cost: {
        color: ORANGE_COLOR,
        fontSize: 10,
    },
    itemRight: {
        paddingTop: 7,
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: '#EFF1F7',
        borderRadius: 10,
        width: 144,
        height: 119,
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: 'transparent'
    },
    selectedItem: {
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        color: '#ffffff',
    },
    selectedText: {
        color: '#ffffff',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
    },
    root: {
        display: 'flex',
        overflow: 'hidden',
        marginBottom: 16,
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
    },
    inProgressBorder: {
        borderColor: ORANGE_COLOR,
        borderWidth: 2,
        borderStyle: 'solid'
    },
    textStyle: {
        color: 'white',
        fontSize: 12,
        marginLeft: 8,
    },
    coefText: {
        position: 'absolute',

        right: 5,
        fontSize: 10,
        borderRadius: 20,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: ORANGE_COLOR,
        color: '#FFFFFF',
        fontWeight: '700',
    },
    ratingView: {
        height: 14,
        flexDirection: 'row'
    },
    ratingImg: {
        width: 12,
        height: 12,
    },
    ratingText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 10
    },
    buttonTextStyle: {
        zIndex: 1,
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 14,
        lineHeight: 17,
        textTransform: 'uppercase'
    }
});

import {Text, StyleSheet, View, ScrollView, Dimensions} from 'react-native';

import React from 'react';
import {DistributionPoint, FantasyInfo, FantasyRateRow, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';
import CheckBox from './CheckBox';
import {MAIN_BAR_COLOR} from '../colors';
import FighterAppBar from './FighterAppBar';
import {getData, getHeightExpression, saveData} from '../func';
import StepProgress from '../controls/StepProgress';
import {ServerAPI} from "../ServerAPI";
import BoardStep from "../controls/BoardStep";
import {OnBoardingStyles} from "../Onboarding";
import {CardList} from "../shop/CardShop";


interface IProps {
    athlete: Fighter;
    fantasy: FantasyInfo;
    fantasyRateRow: FantasyRateRow;
    onBackClick: Function
    onNextStepClick: Function
    userCards: UserCard[]
}

interface IState {
    fantasyRateRow: FantasyRateRow,
    athlete: Fighter,
    fantasy: FantasyInfo,
    userCards: UserCard[],
    allPoints: DistributionPoint[],
    onboardStep: number | null
}


const ONBoardSteps = [
    'SelectFighter1_OnBoard1',
    'SelectFighter1_OnBoard2',
    'SelectFighter1_OnBoard3',
    'SelectFighter1_OnBoard4',
    'SelectFighter1_OnBoard5',
    'SelectFighter1_OnBoard6'
]

export default class SelectFighter1 extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            onboardStep: null,
            userCards: props.userCards,
            allPoints: [],
            fantasyRateRow: props.fantasyRateRow,
            athlete: props.athlete,
            fantasy: props.fantasy,
        };
    }

    onBoardNextClick() {
        if (this.state.onboardStep!! + 1 == this.getOnBoardSteps().length)
            this.doneOnboarding()
        else
            this.setState({onboardStep: this.state.onboardStep!! + 1})
    }

    onBoardSkipClick() {
        this.doneOnboarding()
    }

    doneOnboarding() {
        this.setState({onboardStep: null})
        saveData('OnBoardingDone_SelectFighter1', 'done')
    }

    componentDidMount() {
        this.refresh();
        this.updateOnBoarding()
    }

    async updateOnBoarding() {
        let res = await getData('OnBoardingDone_SelectFighter1');

        if (!res)
            this.setState({
                onboardStep: 0
            })
    }

    async refresh() {
        let allPoints = await ServerAPI.getDistributionPoints();
        this.setState({
            allPoints: allPoints
        });
    }

    isPointSelected(item: DistributionPoint) {
        return !!this.state.fantasyRateRow.AdPoints?.find(it => it.Id == item.Id);
    }

    onPointSelected(item: DistributionPoint) {
        let isSelected = this.isPointSelected(item);
        if (!isSelected) {

            let oldSelectedPoint = this.state.fantasyRateRow.AdPoints.find(it => it.Type == 'Additional1')

            let newSelectedCards = this.state.fantasyRateRow.SelectedUserCards
                .map(it => this.state.userCards.find(c => c.id == it))
                .filter(it => it && it.fantasyPointId != oldSelectedPoint?.Key)
                .map(it => it!!.id);

            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    AdPoints: [...this.state.fantasyRateRow.AdPoints.filter(it => it.Type != 'Additional1'), item],
                    SelectedUserCards: newSelectedCards
                }
            });

            let cards = this.state.userCards.filter(it => it.exDescData == 'Additional1')
            if (!cards.length) {
                setTimeout(() => {
                    this.props.onNextStepClick(this.state.fantasyRateRow);
                }, 50)
            }

        } else {
            let newSelectedCards = this.state.fantasyRateRow.SelectedUserCards
                .map(it => this.state.userCards.find(c => c.id == it))
                .filter(it => it && it.fantasyPointId != item.Key)
                .map(it => it!!.id);

            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    AdPoints: this.state.fantasyRateRow.AdPoints.filter(it => it.Name != item.Name),
                    SelectedUserCards: newSelectedCards
                }
            });
        }
    }

    onCardSelected(userCard: UserCard) {
        let isSelected = !!this.state.fantasyRateRow.SelectedUserCards?.find(it => it == userCard.id);
        if (!isSelected) {
            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    SelectedUserCards: [...this.state.fantasyRateRow.SelectedUserCards, userCard.id],
                },
            });
        } else {
            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    SelectedUserCards: this.state.fantasyRateRow.SelectedUserCards.filter(it => it != userCard.id),
                },
            });
        }
    }

    onNextClick() {
        this.props.onNextStepClick(this.state.fantasyRateRow);
    }

    onBackClick() {
        this.props.onBackClick();
    }

    getOnBoardSteps() {
        let boardSteps = [ONBoardSteps[0], ONBoardSteps[1], ONBoardSteps[2], ONBoardSteps[3], ONBoardSteps[4]];
        if (this.state.userCards.filter(it => it.exDescData == 'Additional1').length > 0)
            boardSteps.push(ONBoardSteps[5])

        return boardSteps;
    }

    render() {
        let firstList = this.state.allPoints.filter(it => it.Type == 'Additional1');
        let selectedPoints = firstList.filter(p => this.state.fantasyRateRow.AdPoints.find(it => it.Key == p.Key));

        let userCards = this.state.userCards;
        let selectedPoint = selectedPoints[0];

        let winningItem = this.state.allPoints.find(it => it.Id == 'Win');

        let boardSteps = this.getOnBoardSteps()

        return (
            <View style={styles.root}>

                {this.state.onboardStep != null && <View style={{
                    width: '100%',
                    height: '100%',
                    padding: 16,
                    position: 'absolute',
                    zIndex: 2
                }}>
                    <BoardStep onNextClick={() => this.onBoardNextClick()}
                               currentStep={this.state.onboardStep}
                               position={this.state.onboardStep > 0 && Dimensions.get('window').height < 700 ? 'top' : 'bottom'}
                               stepCount={boardSteps.length}
                               text={(TRANSLATES as any)[boardSteps[this.state.onboardStep]]}
                               onSkipClick={() => this.onBoardSkipClick()}/>

                </View>}


                <ScrollView style={styles.container}>


                    {winningItem &&
                        <CheckBox
                            key={winningItem.Id}
                            enabled={false}
                            mainText={TRANSLATES['Win.main']}
                            points={winningItem.Points}
                            tooltip={TRANSLATES[`${winningItem.Id}.desc`] || winningItem.Description}
                            onBoardTextVis={this.state.onboardStep == 1}
                        />
                    }

                    <View style={[{
                        borderWidth: 2,
                        borderColor: 'transparent'
                    }, this.state.onboardStep == 0 && OnBoardingStyles.highlight]}>
                        <Text style={styles.chooseText}>
                            {TRANSLATES['ChooseWinMethod']}
                        </Text>
                    </View>
                    <View style={{marginTop: 16}}>
                        {firstList.map((p, idx) => <CheckBox
                            key={p.Id}
                            cardCoefText={this.state.userCards.find(it => it.fantasyPointId == p.Key)?.statusCoef}
                            onPress={() => this.onPointSelected(p)}
                            selected={this.isPointSelected(p)}
                            mainText={TRANSLATES[p.Id] || p.Name}
                            points={p.Points}
                            tooltip={TRANSLATES[`${p.Id}.desc`] || p.Description}
                            onBordVis={idx == 0 && this.state.onboardStep == 2}
                            onBordVisHint={idx == 0 && this.state.onboardStep == 4}
                        />)}
                    </View>

                    <Text style={{
                        marginTop: 16,
                        textAlign: 'center',
                        color: '#797B87',
                        paddingLeft: 16,
                        paddingRight: 16
                    }}>
                        {TRANSLATES['Point1Desc']}
                    </Text>

                    <CardList
                        showShop={true}
                        showText={true}
                        onBoardVis={this.state.onboardStep == 6}
                        selectedList={userCards.filter(it => this.state.fantasyRateRow.SelectedUserCards.find(id => it.id == id))}
                        enabledList={userCards.filter(it => it.fantasyPointId == selectedPoint?.Key)}
                        onCardSelected={(uc: UserCard) => this.onCardSelected(uc)}
                        onCardListChanged={() => this.refresh()}
                        list={this.state.userCards.filter(it => it.exDescData == 'Additional1')}/>


                </ScrollView>

                <View style={styles.buttons}>
                    <FButton text={TRANSLATES['Back']} width={160} onClick={() => this.onBackClick()}/>
                    <FButton text={TRANSLATES['Next']} width={160} onClick={() => this.onNextClick()}
                             enabled={selectedPoints.length > 0}
                             backgroundColor={MAIN_BAR_COLOR}
                             color="#FFFFFF"/>
                </View>


            </View>);
    }
}


const styles = StyleSheet.create({
    chooseText: {
        fontWeight: '700',
        fontSize: 15,
        color: '#2E2D2D',
    },
    container: {
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16,
    },
    root: {
        height: '100%'
    },
    buttons: {
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16
    },
});

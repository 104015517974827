import {Image, ImageBackground, StyleSheet, Text, View} from "react-native";
import TRANSLATES from "./translates/translates";
import React, {useContext, useState} from "react";
import {EMPTY_EMAIL, User} from "./types";
import {UserContext} from "./func";
import {RewardOrangeButton} from "./controls/RewardOrangeButton";
import DailyRewardModal from "./profile/DailyRewardModal";
import {ServerAPI} from "./ServerAPI";


function getUserName(user: User) {
    if (!user)
        return '';

    let name = [];

    if (user.FirstName) {
        name.push(user.FirstName);
    }

    if (user.LastName) {
        name.push(user.LastName);
    }

    if (name.length) {
        return name.join(' ');
    }

    if (user.Email?.startsWith(EMPTY_EMAIL))		//пользователь ещё не ввёл свой email
        return '';


    return user.Email;
}

function getProfileImage(user: User) {
    let avatarUrl = user?.AvatarUrl
    if (avatarUrl)
        return <ImageBackground source={{uri: avatarUrl}} resizeMode="contain" style={{
            width: '100%',
            height: '100%',
            borderRadius: 50,
            overflow: 'hidden'
        }}/>
}

export default function UserAvatarInfo(props : {navigation: any}) {

    const {user} = useContext(UserContext);
    const [dailyRewardVis, setDailyRewardVis] = useState<boolean>(false)

    return <View style={styles.part1}>
        <View style={styles.avatar}>
            {getProfileImage(user)}
            <Image style={styles.statusImg} source={require('./assets/status.png')}/>
            <Text style={styles.levelNumStyle}>{user?.currentLevel}</Text>
        </View>

        <View style={styles.name}>
            <Text style={styles.userNameText}>{getUserName(user)}</Text>
            <Text style={styles.statusText}>{(TRANSLATES as any)['Level' + user?.currentLevel]}</Text>
        </View>

        <RewardOrangeButton text={TRANSLATES['ClaimRewards']} onClick={() => {
            ServerAPI.track('claim_rewards')
            setDailyRewardVis(true)
        }} style={{marginLeft: 'auto'}}/>

        {dailyRewardVis && <DailyRewardModal onClose={() => setDailyRewardVis(false)} navigation={props.navigation}/>}

    </View>
}

const styles = StyleSheet.create({
    part1: {
        display: 'flex',
        flexDirection: 'row',
    },
    name: {
        marginLeft: 20,
    },
    avatar: {
        width: 77,
        height: 77,
        backgroundColor: '#FFB619',
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    userNameText: {
        fontWeight: '700',
        fontSize: 20,
        color: '#FFFFFF',
    },
    statusText: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 15,
        marginTop: 8,
    },
    statusImg: {
        width: 72,
        height: 23,
        position: 'absolute',
        bottom: -10,
        left: 2.5
    },
    levelNumStyle: {
        position: 'absolute',
        color: '#fff',
        fontWeight: '700',
        fontSize: 10,
        bottom: -3,
        left: 35
    },
})

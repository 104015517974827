import {Text} from "react-native";
import React from "react";


export function BoldValue(props: { value: string | number, color: string, style?: any }) {

    return <Text style={{
        paddingLeft: 4,
        paddingRight: 4,
        borderRadius: 10,
        backgroundColor: props.color,
        fontSize: 12,
        fontWeight: '700',
        color: "#fff",
        ...props.style
    }}>{props.value}
    </Text>
}
import {StyleSheet, Text, View} from "react-native";
import {COLORS, MAIN_BAR_COLOR, RED} from "../colors";
import React, {ReactNode} from "react";
import {round100} from "../func";
import {BoldValue} from "./components/BoldValue";

export const CompareOneValue = ({
                                    text, redValue, redTotal, blueValue, blueTotal,
                                    max,
                                    useMax = true
                                }: {
    text: string, redValue?: number, redTotal?: number, blueValue?: number, blueTotal?: number, useMax?: boolean,
    max: number
}) => {

    const renderLeftValue = (value: number | undefined, total: number, useMax = true) => {

        let sto = 156;
        let perc = value ? 100 * value / (useMax ? max : total) : 0
        if (isNaN(perc))
            perc = 0;

        return <View style={{flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: sto}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: sto,
                flexDirection: 'row',
                borderRadius: 10,
                overflow: 'hidden',
                justifyContent: 'flex-end'
            }}>
                <View style={{height: 4, width: (100 - perc) + '%', backgroundColor: RED, opacity: 0.5} as any}/>
                <View style={{height: 4, width: perc + '%', backgroundColor: RED} as any}/>
            </View>
        </View>
    }

    const renderRightValue = (value: number | undefined, total: number, useMax = true) => {
        let sto = 156;
        let perc = value ? 100 * value / (useMax ? max : total) : 0;
        if (isNaN(perc))
            perc = 0;
        return <View style={{flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', width: sto}}>
            <View style={{
                marginTop: 8,
                height: 4,
                width: sto,
                flexDirection: 'row',
                borderRadius: 10,
                overflow: 'hidden',
                justifyContent: 'flex-end'
            }}>
                <View style={{height: 4, width: perc + '%', backgroundColor: COLORS.DARK_BLUE} as any}/>
                <View style={{
                    height: 4,
                    width: (100 - perc) + '%',
                    backgroundColor: COLORS.DARK_BLUE,
                    opacity: 0.5
                } as any}/>
            </View>
        </View>
    }

    const renderTextValue = (value: number | undefined,
                             total: number | undefined,
                             isLeft = false,
                             moreValueColor: string | null = null,
                             moreTotalColor: string | null = null,
    ) => {
        let text = ''

        if (value == undefined)
            text = 'N/D'
        else if (total == undefined) {
            text = value.toString()
            if (moreValueColor)
                return <BoldValue value={text} color={moreValueColor}/>

        } else {
            let valueView = moreValueColor ? <BoldValue value={value} color={moreValueColor}/> :
                <Text style={styles.valueText}>{value.toString()}</Text>
            let totalView = moreTotalColor ? <BoldValue value={total} color={moreTotalColor}/> :
                <Text style={styles.valueText}>{total.toString()}</Text>

            return <View style={{alignItems: 'center', flexDirection: 'row', textAlign: isLeft ? 'left' : 'right', display: 'flex'} as any}>
                {valueView}<Text style={{fontWeight: '600'}}>/</Text>{totalView}
            </View>
        }

        return <Text style={[styles.valueText, {textAlign: isLeft ? 'left' : 'right'}]}>
            {text}
        </Text>
    }

    let maxVal = round100(Math.max(redValue || 10, blueValue || 10))

    return <View style={{height: 52}}>
        <View style={{flexDirection: 'row'}}>
            <View style={{
                marginRight: 'auto',
            }}>{renderTextValue(redValue, redTotal, true,
                redValue! > blueValue! ? COLORS.RED : null,
                redTotal! > blueTotal! ? COLORS.RED : null
            )}</View>
            <Text style={[styles.subTitleText, {width: '50%'}]}>{text}</Text>
            <View style={{
                marginLeft: 'auto',
            }}>{renderTextValue(blueValue, blueTotal, false,
                redValue! < blueValue! ? MAIN_BAR_COLOR : null,
                redTotal! < blueTotal! ? MAIN_BAR_COLOR : null
            )}</View>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            {renderLeftValue(redValue, redTotal || maxVal, useMax)}
            {renderRightValue(blueValue, blueTotal || maxVal, useMax)}
        </View>
    </View>

}

const styles = StyleSheet.create({
    subTitleText: {
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'center',
        color: '#797B87'
    },
    valueText: {
        color: '#2E2D2D',
        fontSize: 12,
        fontWeight: '700',
        textAlign: 'center'
    },
})
import {Image, StyleSheet, Text, View} from "react-native";

import React from "react";
import TPressable from "./TPressable";


export default function ListCheckBox(props: {
	items: { text: string, selected: boolean, id: any }[],
	onItemSelected: Function
})
{
	return <View>
		{props.items.map((it, idx) =>
			 <TPressable key={idx} name={idx + ''} onPress={() => props.onItemSelected(it)} style={styles.item}>
				 <Text style={styles.text}>{it.text}</Text>
				 {it.selected && <Image source={require('../assets/galka.png')} style={styles.galka}/>}
			 </TPressable>)}

	</View>

}

const styles= StyleSheet.create({
	galka: {
		width: 15,
		height: 10,
		marginLeft: 'auto'
	},
	root: {
		height: 24,
		flexDirection: 'row',
		backgroundColor: 'rgba(93, 118, 209, 1)',
		alignItems: 'center',
		justifyContent: 'space-around',
		maxWidth: 100,
		borderRadius: 20
	},
	item: {
		flexDirection: 'row',
		height: 48,
		alignItems: 'center',
		justifyContent: 'flex-start', borderBottomWidth: 1,
		borderBottomColor: '#CACDDE', borderStyle: "solid", width: '100%'
	},
	text: {
		fontSize: 15,
		color: 'rgba(46, 45, 45, 1)',
		fontWeight: '700',
	}
})



export const EN = {
	FantasyCost: 'Fantasy cost',
	FeatureEvents: 'Feature events',
	YourFantasyBalance: 'Your fantasy bank balance: ',
	Balance: 'balance',
	ChooseWinMethod: 'Choose the winning method',
	PreStats: 'See pre stats of fighters',
	Cancel: 'Cancel',
	Next: 'Next',
	Back: 'Back',
	Point1Desc: 'These coins will be awarded if the winning method fights the results of the fight',
	MakePicks: 'Make Picks',
	Point2Title: 'Choose additional advantages of a fighter (you can only choose two advantages)',
	Point2Desc: 'For the selected indicators, the player can get coins with a coefficient of 1.5. These coins will be awarded if advantages and indicators match the match statistics.',
	Ready: 'Ready',
	'fantasy.will.balance': 'At the time of the opening of the event, Fantasy balance will be accrued so that you can play our MMA Picks',
	'ClaimRewards':'Claim rewards',
	Yes: 'Yes',
	No: 'No',

	CheckEmailCode: 'Check your email for a code to reset your password',
	NewPassword: 'New password',
	ConfirmNewPassword: 'Confirm new password',
	PasswordsDontMatch: 'Passwords don\'t match',

	'Win.main': 'Winning regardless of the method of winning',
	EarlyVictoryPainfulHold: 'Early winning by submission (painful)',
	EarlyVictoryChokeHold: 'Early winning by submission (choke)',
	SuccessfulThrowPassFeet: 'Advantage in takedowns (transition)',
	TotalLandedWhoMore: 'Advantage in landed strikes',
	ProtectionAgainstThrowingPassLegs: 'Advantage in defense from takedowns',
	TotalHitsWhoMore: 'Advantage in thrown strikes',
	EarlyVictoryKnockoutTKO: 'Early winning by knockout (TKO/KO)',
	WinByPointsAditional: 'Winning by decision',
	EarlyVictoryAditional: 'Early quick winning from 1 to 59 seconds',
	OverallEffectivenessStrikes: 'Advantage in strikes efficiency',
	PositionControlClinchWhoMore: 'Advantage of control in Clinch',
	PositionControlParterreWhoMore: 'Advantage of control in Ground',
	BlockSubmission: 'Advantage in defense from submissions',
	FirstHitPair: 'First strike advantage',
	MostSuccessfulTakedown: 'The most takedown (transition) at the event',
	ForbiddenHit: 'Forbidden hit',
	EarlyDefeat: 'Early defeat',
	BestImpactIntensity: 'The highest strikes intensity at the event',
	MostLanded: 'The most landed strikes at the event',
	MostThrownHits: 'The most thrown strikes at the event',
	LastHitPair: 'Last hit pair',
	UnsuccessfulDefenseAgainstThrowingPassLegs: 'Unsuccessful defense against throwing pass legs',
	UnsuccessfulThrowPassFeet: 'Unsuccessful throw pass feet',
	FastestVictory: 'The fastest win at an event',
	FailSubmission: 'Fail submission',
	SelectCards: 'Apply your cards to increase coins',

	'AthleteRowResult.numberofcoins': 'Number of coins per item - {0} coins',
	'Numbers': 'Numbers',
	'Coefficient': 'Coefficient',
	'Base' : 'Base',

	Pick: 'Pick',
	Picked: 'Picked',
	Step3Title1: 'You will also be able to get bonus coins at the end of the event',
	Step3Title2: 'You will also be able to get penalty coins at the end of the match',
	Step3Desc1: 'These coins will be awarded if bonus indicators the event statistics.',
	Step3Desc2: 'These penalty coins will be awarded if bonus indicators the match statistics.',

	Player: 'Player',
	PTS: 'PTS',
	Coins: 'Coins',
	Play: 'Make Picks',
	Rank: 'Rank',
	Coins2: 'Coins',

	Result: 'Result',
	AdditionalAdv: 'Additional advantages of a fighter',
	BonusPoints: 'Bonus coins at the end of the event',
	PenaltyCoins: 'Penalty coins',
	AcceptedCards: 'Accepted cards',
	TotalPoints: 'Total coins',

	Step4Title1: 'The winning method',
	Step4Title2: 'Additional advantages of a fighter',
	Step4Title3: 'Bonus coins at the end of the event',

	Change: 'Change',
	Remove: 'Remove',
	Event: 'Event',

	NotEnoughCoins: 'Not enough coins',

	CoinsEarned: 'Coins earned',

	Step0: "Let's Start",
	Step1: 'Next Step',
	Step2: 'One More Step',
	Step3: 'Last Step',
	Step4: 'Ready',

	coins: 'coins',
	bronzeCard: 'Bronze card',
	silverCard: 'Silver card',
	goldCard: 'Gold card',

	bronzePack: 'Bronze pack',
	silverPack: 'Silver pack',
	goldPack: 'Gold pack',
	cards4: '4 cards',
	Pack4Desc: 'in each packet',

	PackageOf: 'Package of 4 {status} cards',
	BuyEarn: 'Earn or buy more coins',
	Prelims: 'Prelims',
	Maincard: 'Main Card',
	EarlyPrelims: 'Early Prelims',

	LiveFeed: 'Live Feed',
	LiveStats: 'See live stats of fight',
	CourseFight: 'Course of the Fight',
	Live: 'Live',

	Canceled: 'Canceled',
	Finished: 'Finished',
	InProgress: 'Live',
	'Not started': 'Not started',
	Additional2: 'Additional advantages',
	Additional1: 'Winning method',

	Extra: 'Bonus coins',

	General: 'General',
	Record: 'Record',
	Age: 'Age',
	Country: 'Country',
	Weight: 'Weight',
	Height: 'Height',
	Reach: 'Reach',

	WinBy: 'Win by',

	Stats: 'Stats',
	'ThrownStrikesPerMin': 'Thrown strikes per minute',
	'LandedStrikesPerMin': 'Landed strikes per minute',
	'StrikesEfficiency': 'Strikes efficiency, %',
	'TakedownPer15': 'Takedown per round',
	'AttemptsSubmissionPer15': 'Attempts submission per 15 minutes',
	'SuccessSubmissionPer15': 'Success submission per 15 minutes',

	Win: 'Win',
	Draw: 'Draw',
	Loss: 'Loss',
	NoContest: 'No Contest',

	BuyNewCards: 'Buy new cards',
	Shop: 'Shop',
	Close: 'Close',
	NewCards: 'New cards',
	Control: 'Control',
	ClinchControl: 'Clinch Control',
	GroundControl: 'Ground Control',
	Strikes: 'Strikes',
	LandedThrown: 'Landed/Thrown',
	Total: 'Total',
	byPosition: 'by Position',
	Distance: 'Distance',
	Clinch: 'Clinch',
	Ground: 'Ground',


	byType: 'by Type',
	byTarget: 'by Target',
	Punches: 'Punches',
	Elbows: 'Elbows',
	Kick: 'Kick',
	Knees: 'Knees',
	Head: 'Head',
	Body: 'Body',
	Leg: 'Leg',
	Takedowns: 'Takedowns',
	SuccessAttempts: 'Success / Attempts',
	Submissions: 'Submissions',
	EfStrikes: 'Strikes Efficiency, %',
	StrikesPerMin: 'Strikes per minute',
	Position: 'Position',
	Level1: 'Rookie 1',
	Level2: 'Rookie 2',
	Level3: 'Rookie 3',
	Level4: 'Junior 1',
	Level5: 'Junior 2',
	Level6: 'Junior 3',
	Level7: 'Challenger 1',
	Level8: 'Challenger 3',
	Level9: 'Challenger 3',
	Level10: 'Master 1',
	Level11: 'Master 2',
	Level12: 'Champion',

	Code: 'Code',
	ForgotPassword: 'Forgot Password?',
	SignUp: 'Sign Up',
	HAccount: 'Don’t have account?',
	HAccount2: 'Already have an account?',
	SignIn: 'Sign In',
	CreateAccount: 'CREATE ACCOUNT',
	LoginIn: 'LOG IN',
	SignUpText1: 'By clicking “Create Account” I agree to',
	And: ' and ',
	TermsAndCond: 'Terms and Conditions',
	PrivacyPolicy: 'Privacy Policy',
	BronzeCards: 'Bronze cards',
	SilverCards: 'Silver cards',
	GoldCards: 'Gold cards',

	MyData: 'My data',
	Settings: 'Settings',
	Rules: 'Rules',

	'NumberEvents': 'Number of events',
	'NumberCoins': 'Number of coins earned',
	'HighestPlace': 'The highest place',
	'AveragePlace': 'Average place per game',
	'AverageNumberCoins': 'Average number of coins per game',

	Profile: 'Profile',
	Feedback: 'Feedback',
	Language: 'Language',
	TermService: 'Term of service',
	DeleteAccount: 'Delete account',
	Subscription: 'Subscription',
	MoreDetails: 'More details',
	StatsFightPlus: 'Stats Fight +',
	CancelSubscription: 'Cancel subscription',
	'CancelSubscription.Title1': 'Are you sure you want to cancel your subscription?',
	'CancelSubscription.Title2': 'You will lose access to Technical Arsenal of Fighters and Course of the Fight features',


	Title: 'Title',
	Details: 'Details',
	Submit: 'Submit',
	FeedbackResult: 'Your feedback has been successfully submitted',
	ThankYou: 'Thank you',

	MyProfile: 'My Profile',
	Save: 'Save',
	Username: 'Username',
	EmailAddress: 'Email address',
	Saved: 'Saved',
	RatingStatus: 'Rating',

	Date: 'Date',
	EventName: 'Name event',
	Place: 'Place',
	YourPTS: 'Your PTS',
	YourCoins: 'Your coins',
	'coin.buyCardPackage4': 'Bought cards',
	'coin.buy': 'Bought coins',
	'coin.buyCard': 'Bought cards',
	'coin.daily-reward': 'Claim Rewards',
	Load7Days: 'Show 1 more week',

	'Continue': 'Continue',

	'BattleList_OnBoard1': '1. For one event, you are always given 100 Fantasy bank units, which allows you to pick several fighters',
	'BattleList_OnBoard2': '2. Fantasy cost is the unit cost of a particular fighter. The total Fantasy cost of all selected fighters should not exceed 100',
	'BattleList_OnBoard3': '3. Before picking, you can see the Pre-Stats of the fighters',
	'BattleList_OnBoard4': '4. Pick a fighter by clicking on it',
	'BattleList_OnBoard5': '5. If there is an orange mark on a fighter\'s card, it means that you have a card of this fighter.',

	'GotIt': 'Got it',
	'Skip': 'Skip',

	'FantasyEventList_OnBoard1': '1. On this screen you can see the list of events.',
	'FantasyEventList_OnBoard2': '2. In the past events, you can see the result of the matches, their statistics and the result of the game.',
	'FantasyEventList_OnBoard3': '3. If you see the inscription Live, then the event is already in full swing, come in faster, watch live battle statistics and your intermediate result of the game.',
	'FantasyEventList_OnBoard4': '4. If you see the inscription Play, then rather choose fighters and compete with other MMA fans.\n',

	'SelectFighter1_OnBoard1': '1. You need to choose the method of the intended winning…',
	'SelectFighter1_OnBoard2': '2. Coins for winning are awarded by default',
	'SelectFighter1_OnBoard3': '3. The number of coins that can be obtained',
	'SelectFighter1_OnBoard4': '4. If there is an orange mark on the fighter\'s card, it means that you have a card of this winning method.',
	'SelectFighter1_OnBoard5': '5. Hints that explain the principles of calculation',
	'SelectFighter1_OnBoard6': '6. Be sure to check for winning method cards. If you want to apply it, then you need to click on it and make it active.',

	'SelectFighter2_OnBoard1': '1. You need select two statistical indicators of the fighter, which in your opinion will have a fighter in a fight.',
	'SelectFighter2_OnBoard2': '2. For the selected indicators, you will be able to get the corresponding coin multiplied by 1.5',
	'SelectFighter2_OnBoard3': '3. Regardless of your select you will also be able to get coins for the rest of the indicators if the indicators fight will match statistics of the fight',
	'SelectFighter2_OnBoard4': '4. If there is an orange mark next to the indicator, it means that you have a card of this indicator.',
	'SelectFighter2_OnBoard5': '5. Be sure to check the availability of indicator cards. If you want to apply it, then you need to click on it and make it active.',


	'SelectFighter3_OnBoard1': '1. You will also need to take into account the possibility of receipenaltiesving bonus coins and that can\'t be selected',
	'SelectFighter3_OnBoard2': '2. If there is an orange mark next to the indicator, it means that you have a card of this bonus.',
	'SelectFighter3_OnBoard3': '3. And be sure to also check for bonus cards.',

	'SelectFighter4_OnBoard1': '1. Check your selection',
	'SelectFighter4_OnBoard2': '2. The cards of fighters, promotions and countries are displayed and applied here. Be sure to check!',

	'BattleList2_OnBoard1': '1. Click at what rank you occupy among the other players, how many coins and PTS scored.',
	'BattleList2_OnBoard2': '2. The number of coins you have collected for a particular fighter. Click on the fighter to see the calculation.',

	'FantasyRateRowView_OnBoard1': '1. You can see only those indicators that match your forecast, taking into account the additional select of two statistical indicators (1.5), bonus and penalty coins. Having opened the indicator, you can view the calculation.',


	'LiveStatTable_OnBoard1': '1. G-Fight is our unique AI-based indicator, which in a simple visualization (50%/50%) shows the advantage of one fighter over another in a round.',
	'LiveStatTable_OnBoard2': '2. Time of Positions is the time and percentage of the total time during which the fight took place in a particular position (distance, clinch, ground).',
	'LiveStatTable_OnBoard3': '3. Control of position is the time and percentage of the total position time (clinch or ground) during which one fighter had the advantage of his position over the other.',
	'LiveStatTable_OnBoard4': '4. Thrown Strike. These are all thrown strikes that were made with the effort of one fighter and with the reaction of the opponent to it',
	'LiveStatTable_OnBoard5': '5. Landed Strike. Landed strikes are or accurate strikes that passed opponent\'s defense or strikes that were delivered to "frame block".',
	'LiveStatTable_OnBoard6': '6. Takedown. In this case, we record wrestling techniques, if position has changed during fight without the participation of fighters, then we simply change positions and don\'t record takedown.',
	'LiveStatTable_OnBoard7': '7. Submissions. We record attempt of submission at moment when fighter fixes the "lock" of reception.',

	'G-Fight': 'G-Fight shows the probability of winning a round during a fight, based on the statistics of the fight and MMA rules (ACB and CS standard)',

	'AskNot': 'Allow us to send notifications, and you won\'t miss the opportunity to take part in an exciting our MMA Picks and watch unique Live Stats.',
	'Notifications': 'Notifications',
	'OK': 'OK',

	'UserAlreadyExistsException': 'This email already used',

	'Are your sure': 'Are your sure',
	'DeleteMeConfirm': 'Are you sure you want to remove your account?',

	'YourCollection': 'Your collection',
	'cards': 'cards',
	'Cards': 'Cards',
	'Sorting': 'Sorting',
	'Rating': 'Rating',
	'Group': 'Group',
	'Athlete': 'Athlete',
	'Promotion': 'Promotion',
	'Other': 'Other',
	'StatsFightRating': 'Stats Fight Rating',
	'GeneralInformation': 'General Information',
	'GeneralRating': 'General Rating',
	'Force': 'Force',
	'Speed': 'Speed',
	'Takedown': 'Takedown',
	'Defense': 'Defense',
	'Efficiency': 'Efficiency',
	'TV/Stream': 'TV/Stream',
	'LastFights': 'Last fights',
	'StatsFighterRatingToolTip': 'Stats Fight statistical ratings are the product of  computer algorithm based on more than 100 fighter stats',
	'UserCardToolTip1': 'Cards can only be bought with coins on Shop screen. Coins can be obtained by:',
	'UserCardToolTip2': 'playing MMA Pick',
	'UserCardToolTip3': 'watching ads',
	'UserCardToolTip4': 'buying for real money on Shop screen',
	'NoAds': 'No ads available, try later',
	'NoMoreAdsToday': 'Watch ads will be available tomorrow',


	'YourGyms': 'Your gyms',
	'gym': 'gym',
	'rating': 'rating',
	'GymPageTitle': 'Stats Fight Rating of your General gym',
	'Add': 'Add',
	'AddCardToGym': 'Select card',
	'NoWeightCards': 'You do not have cards for this weight category',
	'GeneralGym' : 'General gym',
	'GymOnBoard': '1. Click on this button to collect Gym from your fighter cards.',
	'GymOnBoard1': '2. Add or change your card by clicking on this field',
	'GymOnBoard2': '3. Next you will see a card of a specific weight category',
	'GymOnBoard3': '4. Having assembled your gym, you will have a rating of your gym. Collect more cards, add them to your gym to increase the rating',

	'course-fight.strikes': 'Strikes',
	'course-fight.technique': 'Technique',
	'course-fight.position': 'Position',
	'course-fight.open': 'See course of the fight',
	'TechnicalArsenal' : 'Technical Arsenal',
	'compare-fighters.open': 'See technical arsenal of fighters',
	'course-fight.numbers-strikes': 'Numbers of strikes (Thrown Landed)',

	'compare-stat.general': 'General',
	'compare-stat.distance': 'Distance',
	'compare-stat.clinch': 'Clinch',
	'compare-stat.ground': 'Ground',
	'compare-stat.attack': 'Attack',
	'compare-stat.defence': 'Defence',
	'compare-stat.punches-stat': 'Strikes Statistics (per Round)',

	'compare-stat.punches-stat.critical': 'Critical',
	'compare-stat.punches-stat.landed': 'Landed',
	'compare-stat.punches-stat.total': 'Total',
	'compare-stat.punches-stat.punches-efficiency': 'Strikes Efficiency',
	'compare-stat.types-punches': 'Percentage of Types of Strikes',

	'compare-stat.punches-stat.successful': 'Successful',
	'compare-stat.punches-stat.attempts': 'Attempts',

	'compare-stat.punches-stat.punch': 'Punch',
	'compare-stat.punches-stat.elbow': 'Elbow',
	'compare-stat.punches-stat.kick': 'Kick',
	'compare-stat.punches-stat.knee': 'Knee',

	'compare-stat.punches-zones': 'Strikes Zones',
	'compare-stat.punches-zones.head': 'Head',
	'compare-stat.punches-zones.body': 'Body',
	'compare-stat.punches-zones.legs': 'Legs',

	'compare-stat.ground-stat' : 'Average Number of Attempts (per Round)',
	'compare-stat.ground-stat.takedowns' : 'Takedowns',
	'compare-stat.ground-stat.transitions' : 'Transitions',

	'compare-stat.zone.submission-at': 'Number of submission attempts (per 15 min)',
	'compare-stat.zone.chokehold': 'Сhokehold',
	'compare-stat.zone.left-arm': 'Left Arm',
	'compare-stat.zone.right-arm': 'Right Arm',
	'compare-stat.zone.right-leg': 'Right Leg',
	'compare-stat.zone.left-leg': 'Left Leg',

	'compare-stat.efficiency': 'Efficiency, %',
	'compare-stat.defence.avg-by-zone': 'Average number of taken strikes by zones',
	'compare-stat.defence.eff-submission': 'The effectiveness of protection submission (per 15 min)',
	'compare-stat.defence.tt': 'Defense Efficiency (per 15 min)',

	'compare-stat.avg.distance': 'Average percentage (time) of staying in a position Distance',
	'compare-stat.avg.clinch': 'Average percentage (time) of staying in the Clinch position',
	'compare-stat.avg.parter': 'Average percentage (time) of stay in the position of Partere',

	'battle-list.appbar.tooltip': 'Play MMA Picks in the next event and win coins, get Rank and PTS',

	'fantasy-list.past': 'Past',
	'fantasy-list.upcoming': 'Upcoming',
	'fantasy-list.live': 'Live',
	'fantasy-list.make-picks': 'MakePicks',
	'fantasy-list.live.text': 'Events appear from the start and are here until the end of the last match of the event',
	'fantasy-list.past.text': 'See your MMA Picks results and statistics of past fights',
	'fantasy-list.make-picks.text': 'Most often, we open events for the game the day before the event, when the full card is known. Turn on the notification so as not to miss the game.',
	'fantasy-list.upcoming.text': 'We are already working on updating the calendar of future events',


	'battle-item.who-win': 'Who will win?',
	'battle-item.pick-made': 'Pick is made',

	'reward.bonus-points': 'Bonus Coins',

	'reward.tab-to-skip': 'Tab to skip',
	'reward.your-got': 'You got',
	'reward.tab-to-continue': 'Tab to continue',
	'reward.get-a-reward': 'Get a reward',
	'reward.alredy-got': 'This reward was already claimed',
	'reward.not-yet': 'This reward will be available in',
	'reward.no-sub': 'Get Stats Fight+ to unlock premium rewards',
	'reward.lost': 'This reward has burned, try to get it next week',

	'battle-list.event-reward': 'Get a set of cards for this event',
	'battle.list.event-reward-msg': 'This reward will be available in ',
	'battle-list.fighter-not-open': 'You will be able to pick a fighter when the event moves to the "Make Picks" section, now you can see pre statistics of the fighters',
	'battle-list.fighter-past': 'Yo can\'t to picks a fighter in the past event, now you can see Live Stats of the fight.',


	'user-card-page.additional-point-msg': 'This card will allow you to multiply your winnings in Fantasy MMA when picking an Additional advantage. This type of card burns out after use',
	'user-card-page.win-point-msg': 'This card will allow you to multiply your winnings in Fantasy MMA when picking an Winning Method. This type of card burns out after use',
	'user-card-page.bonus-point-msg': 'This card will allow you to multiply your winnings in Fantasy MMA when picking an Bonus coins. This type of card burns out after use.',
	'user-card-page.promotion-msg': 'This card will allow you to multiply your winnings in Fantasy MMA at the last stage of confirming your pick. Cards of this type burn out after use.',
	'user-card-page.country-msg': 'This card will allow you to multiply your winnings in Fantasy MMA at the last stage of confirming your pick. Cards of this type burn out after use.',

	'athlete-rating-page.msg': 'This card will allow you to automatically multiply your winnings in Fantasy MMA when choosing the fighter himself. Cards of this type do not burn out after use.',
	'coins_bank': 'Coins bank',
	'guaranteed_rewards': 'Guaranteed rewards',
	'possible_rewards': 'Possible rewards',
	'possible_rewards.sub-title': 'Iterms shown are for illustration only, none of these iterms are quaranteed.',
	'fighter_card': "FIGHTER'S CARD",

	'onboarding_1.title': 'Play our unique MMA Picks format',
	'onboarding_1.desc': 'Predict not only the winner, the winning method, but also the statistical advantage of the fighter',

	'onboarding_2.title': 'Win virtual currency - coins',
	'onboarding_2.desc': 'For each successful prediction of the fight, you get a virtual currency - coins',

	'onboarding_3.title': 'Buy cards of fighters, indicators, etc.',
	'onboarding_3.desc': 'With the help of coins, you can buy cards of fighters, indicators, promotions and countries to increase your winnings next time',

	'onboarding_4.title': 'Games opens the day before the event',
	'onboarding_4.desc': 'The game opens the day before the event, when the final card of the upcoming event is known',

	'onboarding_5.title': 'Sign up and start playing',
	'onboarding_5.desc': 'Don\'t miss out on this chance to take your fantasy MMA experience to the next level with Stats Fight',



	'other_card': 'Other Card',
	'login_every_day': 'Login every day to win these rewards',
	'Rewards': 'Rewards',
	'daily_login': 'Daily Login',
	'ends_in': 'Ends in {0}d {1}h',
	'ends_in_hour': 'Ends in {1}h',

	'in': '{0}d {1}h',
	'in_hour': 'in {1}h',

	'Feed': 'Feed',

	'Course.Takedown': 'Takedown',
	'Course.Att-Takedown' : 'Att. Takedown',
	'Course.Transition' : 'Transition',
	'Course.Att-Transition': 'Att. Transition',
	'Course.Submission' : 'Submission',
	'Course.Att-Submission' : 'Att. Submission',
	'Odds': 'Odds',
	'fighter-card': 'Fighter\'s \n card',

	'Sign up now': 'Sign up now',
	'subdetails.new-look' :'New look at martial arts',
	'subdetails.additional-features': 'Additional features and perks only for our Stats Fight + subscribers',
	'sub.feature.1': 'Average percentage (time) of staying in a position',
	'sub.feature.2': 'Percentage of types of punches',
	'sub.feature.3': 'Average number of punches of zones',
	'sub.feature.4': 'Number of takedown and submission attempts',
	'sub.feature.5': 'and much much more',
	'sub.feature.6': 'Flow of strikes',
	'sub.feature.7': 'Timings of each technique',
	'sub.feature.8': 'Time zones of positions',
	'sub.feature.9': 'Get more coins',
	'sub.feature.10': 'Get better advantage and bonus cards',
	'sub.feature.11': 'Get better fighter cards',

	'sub-info.1': 'The subscription price is for one month',
	'sub-info.2': 'The subscription will automatically renew each month',
	'sub-info.3': 'You can cancel your subscription at any time on the Profile - Setting - Subscription screen by switching the button to inactive mode and confirming the cancellation.',
	'sub-info.4': 'For more information about the subscription, please follow the link - Terms and Conditions Apply Subscriptions',


	'Achievements': 'Achievements',
	'Achievements.Coins': 'Coins',
	'Achievements.Play': 'Play',
	'Achievements.Rank': 'Rank',
	'Achievements.Gym': 'Gym',
	'Achievements.Event': 'Event',

	'Achievements.Play.top-10': 'Top 10',
	'Achievements.Play.top-5': 'Top 5',
	'Achievements.Play.top-1': 'Top 1',
	'Achievements.Play.10-top-5': '10x top 5',
	'Achievements.Play.10-top-3': '10x top 3',
	'Achievements.Play.10-top-1': '10x top 1',

	'Achievements.Next': 'Your next goal',
	'Achievements.Next.coins': 'Earn more coins',
	'Achievements.Next.play_fantasy': 'Play more',
	'Achievements.Next.general_raiting_gym': 'Assemble a Gym with a Total Stats Fight Rating',
	'Achievements.Next.rank': 'Win and get a new rating',

	'battles.rating': 'Stats Fight Performance is our performance indicator of an MMA fighter, showing the effectiveness of using the entire complex of the technical arsenal of a mixed martial arts fighter in a fight aimed at achieving victory.'
}

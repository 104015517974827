export const RU = {
	FantasyCost: 'Фэнтази цена',
	FeatureEvents: 'Мероприятия',
	YourFantasyBalance: 'Ваш Фэнтази баланс: ',
	Balance: 'баланс',
	ChooseWinMethod: 'Выбери метод победы',
	PreStats: 'Сравнение бойцов',
	Cancel: 'Отмена',
	Next: 'Далее',
	Back: 'Назад',
	Point1Desc: 'Эти монеты будут начислены, если метод побед совпадет с результатом боя',
	MakePicks: 'Играть',
	Point2Title: 'Выберите дополнительные преимущества бойца (вы можете выбрать только два преимущества)',
	Point2Desc: 'За выбранные показатели игрок может получить монеты с коэффициентом 1,5. Эти монеты будут начислены, если преимущества и показатели соответствуют статистике боя',
	Ready: 'Готово',
	'fantasy.will.balance': 'На момент открытия мероприятия будет начислен фэнтази баланс, чтобы вы могли выбирать бойцов',
	'ClaimRewards': 'Получи награды',

	Yes: 'Да',
	No: 'Нет',

	CheckEmailCode: 'Проверьте вашу электронную почту на наличие кода для сброса пароля',
	NewPassword: 'Новый пароль',
	ConfirmNewPassword: 'Подтвердите новый пароль',
	PasswordsDontMatch: 'Пароли не совпадают',

	'Win.main': 'Победа вне зависимости от метода победы',
	EarlyVictoryPainfulHold: 'Досрочная победа сабмишеном (удушающий)',
	EarlyVictoryChokeHold: 'Досрочная победа сабмишеном (болевой)',
	SuccessfulThrowPassFeet: 'Преимущество в тейкдаунов (проходов в ноги)',
	TotalLandedWhoMore: 'Преимущество в нанесенных ударов',
	ProtectionAgainstThrowingPassLegs: 'Преимущество в защите от тейкдаунов',
	TotalHitsWhoMore: 'Преимущество в выброшенных ударах',
	EarlyVictoryKnockoutTKO: 'Досрочная победа нокаутом (TKO/КО)',
	WinByPointsAditional: 'Победа решением',
	EarlyVictoryAditional: 'Досрочная быстрая победа с 1 по 59 секунду',
	OverallEffectivenessStrikes: 'Преимущество в эффективности ударов',
	PositionControlClinchWhoMore: 'Преимущество контроля в Клинче',
	PositionControlParterreWhoMore: 'Преимущество контроля в Партере',
	BlockSubmission: 'Преимущество в защите от сабмишенов',
	FirstHitPair: 'Преимущество в первом ударе',
	MostSuccessfulTakedown: 'Больше всего успешных тейкдаунов на мероприятии',
	ForbiddenHit: 'Запрещенный удар',
	EarlyDefeat: 'Досрочное поражение с 1 по 59 секунду',
	BestImpactIntensity: 'Самая высокая интенсивность ударов на мероприятии',
	MostLanded: 'Больше всех нанесённых ударов на мероприятии',
	MostThrownHits: 'Больше всех выброшенных ударов на мероприятии',
	LastHitPair: 'Последний удар пары',
	UnsuccessfulDefenseAgainstThrowingPassLegs: 'Боец пропустил бросок (проход в ноги) соперника',
	UnsuccessfulThrowPassFeet: 'Боец совершил неудачную попытку броска (проход в ноги)',
	FastestVictory: 'Самая быстрая победа на мероприятии',
	FailSubmission: 'Боец совершил неудачную попытку сабмишена',
	SelectCards: 'Примени карточку, чтобы преумножить монеты',

	'Win.desc': 'Монеты начисляются при условии победы вне зависимости от метода победы',
	'EarlyVictoryPainfulHold.desc': 'Монеты начисляются при условии досрочной победы сабмишеном (болевой)',
	'EarlyVictoryChokeHold.desc': 'Монеты начисляются при условии досрочной победы сабмишеном (удушающий)',
	'SuccessfulThrowPassFeet.desc': 'Монеты начисляются за каждый удачный бросок',
	'TotalLandedWhoMore.desc': 'Монеты начисляются при условии большего количества landed ударов у бойца по сравнению с соперником',
	'ProtectionAgainstThrowingPassLegs.desc': 'Монеты начисляются за каждую неудачную попытку броска (прохода в ноги) соперника',
	'TotalHitsWhoMore.desc': 'Монеты начисляются при условии большего количества выброшенных ударов у бойца по сравнению с соперником',
	'EarlyVictoryKnockoutTKO.desc': 'Монеты начисляются при условии досрочной победы нокаутов (TKO/КО)',
	'WinByPointsAditional.desc': 'Монеты начисляются при условии победы решением судей',
	'EarlyVictoryAditional.desc': 'Монеты начисляются при условии быстрой досрочной победы (с 1 по 59 секунды)',
	'OverallEffectivenessStrikes.desc': 'Монеты начисляются при условии большего процента эффективности удара у бойца по сравнению с соперником',
	'PositionControlClinchWhoMore.desc': 'Монеты начисляются при условии большего значения времени (процента) контроля в Клинче',
	'PositionControlParterreWhoMore.desc': 'Монеты начисляются при условии большего значения времени (процента) контроля в Партере',
	'BlockSubmission.desc': 'Монеты начисляются за каждую неудачную попытку сабмишенов соперника',
	'FirstHitPair.desc': 'Монеты начисляются при условии, что боец совершил первым удар в раунде',
	'MostSuccessfulTakedown.desc': 'Монеты начисляются при условии досрочной победы сабмишеном (болевой)',
	'ForbiddenHit.desc': '',
	'EarlyDefeat.desc': 'Монеты списываются при условии быстрого досрочного поражения (с 1 по 59 секунды)',
	'BestImpactIntensity.desc': 'Монеты начисляются при условии быстрой досрочной победы (с 1 по 59 секунды)',
	'MostLanded.desc': 'Монеты начисляются при условии досрочной победы сабмишеном (удушающий)',
	'MostThrownHits.desc': 'Монеты начисляются при условии досрочной победы нокаутов (TKO/КО)',
	'LastHitPair.desc': '',
	'UnsuccessfulDefenseAgainstThrowingPassLegs.desc': 'Монеты списываются за каждый удачный бросок соперника',
	'UnsuccessfulThrowPassFeet.desc': 'Монеты списываются за каждую неудачную попытку броска',
	'FastestVictory.desc': 'Монеты начисляются при условии саммой быстрой победы',
	'FailSubmission.desc': 'Монеты списываются за каждую неудачную попытку сабмишена',


	'AthleteRowResult.numberofcoins': 'Число монет за каждый показатель - {0}',
	'Numbers': 'Количество',
	'Coefficient': 'Коэффициент',
	'Base' : 'Подитог',

	Pick: 'Выбрать',
	Picked: 'Выбрано',
	Step3Title1: 'Вы также сможете получить бонусные монеты по итогам мероприятия',
	Step3Title2: 'Вы также можете получить штрафные монеты по итогам мероприятия',
	Step3Desc1: 'Эти монеты будут начислены, если бонусные показатели соответствуют статистике события',
	Step3Desc2: 'Эти штрафные монеты будут начислены, если бонусные показатели соответствуют статистике боя',

	Player: 'Игрок',
	PTS: 'PTS',
	Coins: 'Монет',
	Play: 'Играть',
	Rank: 'Ранг',
	Coins2: 'Монеты',

	Result: 'Результат',
	AdditionalAdv: 'Доп перевес бойца',
	BonusPoints: 'Бонусные монеты по итогам события',
	PenaltyCoins: 'Штрафные монеты',
	AcceptedCards: 'Использованные карточки',
	TotalPoints: 'Всего монет',

	Step4Title1: 'Метод победы',
	Step4Title2: 'Дополнительные преимущества бойца',
	Step4Title3: 'Бонусные монеты по итогам мероприятия',

	Change: 'Изменить',
	Remove: 'Удалить',
	Event: 'Турнир',

	NotEnoughCoins: 'Недостаточно монет',

	CoinsEarned: 'Заработанные монеты',

	Step0: "Давай начнём",
	Step1: 'Следующий шаг',
	Step2: 'Ещё один шаг',
	Step3: 'Последний шаг',
	Step4: 'Готово',

	coins: 'монет',
	bronzeCard: 'Бронзовая карточка',
	silverCard: 'Серебряная карточка',
	goldCard: 'Золотая карточка',

	bronzePack: 'Бронзовый \n пакет',
	silverPack: 'Серебряный \n пакет',
	goldPack: 'Золотой \n пакет',
	cards4: '4 карточки',
	Pack4Desc: 'в каждом пакете',

	PackageOf: 'Пакет из 4 карточек {status}',
	BuyEarn: 'Заработайте или купите больше монет',
	Prelims: 'Prelims',
	Maincard: 'Main Card',
	EarlyPrelims: 'Early Prelims',

	LiveFeed: 'Live Feed',
	LiveStats: 'Онлайн Статистика поединка',
	CourseFight: 'Динамика поединка',
	Live: 'Live',

	Canceled: 'Отменён',
	Finished: 'Завершён',
	InProgress: 'Live',
	'Not started': 'Не начат',
	Additional2: 'Статистика',
	Additional1: 'Метод победы',

	Extra: 'Бонусные монеты',

	General: 'Общее',
	Record: 'Рекорд',
	Age: 'Возраст',
	Country: 'Страна',
	Weight: 'Вес',
	Height: 'Рост',
	Reach: 'Размах рук',

	WinBy: 'Метод победы',

	Stats: 'Статистика',
	'ThrownStrikesPerMin': 'Выброшенные удары в минуту',
	'LandedStrikesPerMin': 'Нанесенные удары в минуту',
	'StrikesEfficiency': '% попадания',
	'TakedownPer15': 'Тейкдауны за раунд',
	'AttemptsSubmissionPer15': 'Попыток сабмишенов за 15 минут',
	'SuccessSubmissionPer15': 'Успешных сабмишенов на 15 минут',

	Win: 'Победа',
	Draw: 'Ничья',
	Loss: 'Поражение',
	NoContest: 'Не состоявшийся',

	BuyNewCards: 'Купить новые карточки',
	Shop: 'Магазин',
	Close: 'Закрыть',
	NewCards: 'Новые карточки',
	Control: 'Контроль',
	ClinchControl: 'Контроль в клинче',
	GroundControl: 'Контроль в партере',
	Strikes: 'Удары',
	LandedThrown: 'Попадание/Выброшенные',
	Total: 'Всего',
	byPosition: 'по Позициям',
	Distance: 'Стойка',
	Clinch: 'Клинч',
	Ground: 'Партер',

	byType: 'по Типу',
	byTarget: 'по Зонам',
	Punches: 'Руки',
	Elbows: 'Локти',
	Kick: 'Ноги',
	Knees: 'Колени',
	Head: 'Голова',
	Body: 'Корпус',
	Leg: 'Ноги',
	Takedowns: 'Тейкдауны',
	SuccessAttempts: 'Успешные / Попытки',
	Submissions: 'Сабмишены',
	EfStrikes: '% попадания',
	StrikesPerMin: 'Ударов в минуту',
	Position: 'Позиция',
	Level1: 'Новичок 1',
	Level2: 'Новичок 2',
	Level3: 'Новичок 3',
	Level4: 'Мастер 1',
	Level5: 'Мастер 2',
	Level6: 'Мастер 3',
	Level7: 'Избранный 1',
	Level8: 'Избранный 2',
	Level9: 'Избранный 3',
	Level10: 'Чемпион 1',
	Level11: 'Чемпион 2',
	Level12: 'Чемпион',

	Code: 'Код',
	ForgotPassword: 'Забыли пароль?',
	SignUp: 'Зарегистрироваться',
	HAccount: 'Нет аккаунта?',
	HAccount2: 'Уже есть аккаунт?',
	SignIn: 'Войти',
	CreateAccount: 'СОЗДАТЬ АККАУНТ',
	LoginIn: 'ВОЙТИ',
	SignUpText1: 'Нажимая «Создать аккаунт», я соглашаюсь с ',
	And: ' и ',
	TermsAndCond: 'Условия использования',
	PrivacyPolicy: 'Политика конфиденциальности',
	BronzeCards: 'Бронзовые карточки',
	SilverCards: 'Серебряные карточки',
	GoldCards: 'Золотые карточки',

	MyData: 'Данные',
	Settings: 'Настройка',
	Rules: 'Правила',

	'NumberEvents': 'Количество мероприятий',
	'NumberCoins': 'Количество заработанных монет',
	'HighestPlace': 'Самое высокое место',
	'AveragePlace': 'Среднее место за игру',
	'AverageNumberCoins': 'Среднее количество монет за игру',

	Profile: 'Профиль',
	Feedback: 'Обратная связь',
	Language: 'Язык',
	TermService: 'Условия использования',
	DeleteAccount: 'Удалить аккаунт',
	Subscription: 'Подписка',
	MoreDetails: 'Подробнее',
	StatsFightPlus: 'Stats Fight +',
	CancelSubscription: 'Отменить подписку',
	'CancelSubscription.Title1': 'Вы уверены, что хотите отменить подписку?',
	'CancelSubscription.Title2': 'Вы потеряете доступ к функциям "Технический арсенал бойцов" и "Динамика поединка"',

	Title: 'Заголовок',
	Details: 'Детали',
	Submit: 'Отправить',
	FeedbackResult: 'Ваш отзыв успешно отправлен',
	ThankYou: 'Спасибо',

	MyProfile: 'Мой профиль',
	Save: 'Сохранить',
	Username: 'Имя пользователя',
	EmailAddress: 'Адрес электронной почты',
	Saved: 'Сохранено',
	RatingStatus: 'Рейтинг',

	Date: 'Дата',
	EventName: 'Турнир',
	Place: 'Место',
	YourPTS: 'Ваши PTS',
	YourCoins: 'Ваши монеты',
	'coin.buyCardPackage4': 'Купленные карточки',
	'coin.buy': 'Купленные монеты',
	'coin.buyCard': 'Купленные карты',
	'coin.daily-reward': 'Забрать награды',
	Load7Days: 'Показать еще 1 неделю',


	'Continue': 'Продолжить',

	'BattleList_OnBoard1': '1. На одно событие вам всегда предоставляется 100 фэнтази единиц, что позволяет выбрать нескольких бойцов',
	'BattleList_OnBoard2': '2. Фэнтази цена - это стоимость единицы конкретного бойца. Общая Фэнтази стоимость всех выбранных бойцов не должна превышать 100',
	'BattleList_OnBoard3': '3. Перед выбором вы можете посмотреть предварительную статистику бойцов',
	'BattleList_OnBoard4': '4. Выбери бойца, нажав на него',
	'BattleList_OnBoard5': '5. Если на поле бойца есть оранжевая метка, это означает, что у вас есть карточка этого бойца.',

	'GotIt': 'Понятно',
	'Skip': 'Пропустить',

	'FantasyEventList_OnBoard1': '1. На этом экране вы можете увидеть список мероприятий',
	'FantasyEventList_OnBoard2': '2. На прошлых мероприятиях вы можете посммотреть результаты поединков и их статистику',
	'FantasyEventList_OnBoard3': '3. Если вы видите надпись Live, значит, событие уже в разгаре, зайдите быстрее, смотрите статистику боя в реальном времени и ваш промежуточный результат игры.',
	'FantasyEventList_OnBoard4': '4. Если вы видите надпись Play, то быстрее выбирайте бойцов и соревнуйтесь с другими поклонниками ММА',

	'SelectFighter1_OnBoard1': '1. Вам нужно выбрать метод предполагаемой победы',
	'SelectFighter1_OnBoard2': '2. Монеты за победу присуждаются по умолчанию',
	'SelectFighter1_OnBoard3': '3. Количество монет, которые можно получить',
	'SelectFighter1_OnBoard4': '4. Если на поле методы победы есть оранжевая метка, это означает, что у вас есть карточка этого метода победы.',
	'SelectFighter1_OnBoard5': '5. Подсказки, которые объясняют принципы расчета',
	'SelectFighter1_OnBoard6': '6. Обязательно проверьте карточки методов победы. Если вы хотите применить ее, то нужно нажать на нее и сделать активной.',

	'SelectFighter2_OnBoard1': '1. Вам нужно выбрать два статистических показателя бойца, которые, по вашему мнению, он продемонстрирует в поединке',
	'SelectFighter2_OnBoard2': '2. За выбранные показатели вы сможете получить соответствующие монеты, умноженные на 1,5.',
	'SelectFighter2_OnBoard3': '3. Независимо от ваших выборов, вы также сможете получить монеты за остальные показатели, если показатели боя совпадут со статистикой поединка',
	'SelectFighter2_OnBoard4': '4. Если рядом с показателем есть оранжевая метка, это означает, что у вас есть карточка этого показателя.',
	'SelectFighter2_OnBoard5': '5. Убедитесь, что у вас есть карточки для показателей. Если хотите их применить, нажмите на них, чтобы активировать.',

	'SelectFighter3_OnBoard1': '1. Также важно учитывать возможность получения бонусных монет и то, что бонус не может быть выбран',
	'SelectFighter3_OnBoard2': '2. Если рядом с бонусом есть оранжевая метка, это означает, что у вас есть карточка этого бонуса.',
	'SelectFighter3_OnBoard3': '3. И не забудьте проверить наличие бонусных карточек',

	'SelectFighter4_OnBoard1': '1. Проверь свой выбор',
	'SelectFighter4_OnBoard2':  '2. Здесь отображаются и применяются карточки бойцов, промоушенов и стран. Обязательно проверь!',

	'BattleList2_OnBoard1': '1. Кликни, чтобы узнать, на каком месте находитесь среди других игроков, сколько монет и PTS вы заработали',
	'BattleList2_OnBoard2': '2. Количество монет, которые вы собрали за определённого бойца. Нажмите на бойца, чтобы увидеть расчёт',

	'FantasyRateRowView_OnBoard1': '1. Вы видите только те показатели, которые соответствуют вашему прогнозу, учитывая дополнительный выбор двух статистических показателей (1,5), бонусные и штрафные монеты. Открыв показатель, вы можете посмотреть расчёт',

	'LiveStatTable_OnBoard1': '1. G-Fight – это наш уникальный индикатор на основе искусственного интеллекта, который в простой визуализации (50%/50%) показывает преимущество одного бойца над другим в каждом раунде',
	'LiveStatTable_OnBoard2': '2. Время позиций показывает время и процент общего времени, в течение которого происходил бой в определённой позиции (стойка, клинч, партер)',
	'LiveStatTable_OnBoard3': '3. Контроль позиции - это время и процент общего времени позиции (клинч или партер), в течение которого один боец был в преимущественном положении перед другим',
	'LiveStatTable_OnBoard4': '4. Выброшенные удары. Это все выброшенные удары, которые были совершены усилиями одного бойца и реакцией соперника на это',
	'LiveStatTable_OnBoard5': '5. Нанесённые удары - это точные удары, которые прошли оборону оппонента или удары, которые были нанесены по "рамочному блоку".',
	'LiveStatTable_OnBoard6': '6. Тейкдауны. Успешным считается тейкдаун, после которого боец сохранил контроль. Если контроль не сохранен или позиция изменилась сама по себе, тейкдаун не засчитывается.',
	'LiveStatTable_OnBoard7': '7. Сабмишен. Мы фиксируем попытку сабмишена в тот момент, когда боец фиксирует "замок" приема',

	'G-Fight': 'G-Fight показывает вероятность победы в раунде во время поединка, на основе статистики поединка и международных правил ММА (ACB и CS стандарт)',

	'AskNot': 'Разрешите нам отправлять уведомления, и вы не упустите возможность принять участие в увлекательных фэнтази MMA и посмотреть уникальный Live Stats',
	'Notifications': 'Уведомления',
	'OK': 'OK',

	'UserAlreadyExistsException': 'Этот адрес электронной почты уже используется',


	'Are your sure': 'Вы уверены?',
	'DeleteMeConfirm': 'Вы действительно хотите удалить свой аккаунт?',

	'YourCollection': 'Ваша коллекция',
	'cards': 'карточки',
	'Cards': 'Карточки',
	'Sorting': 'Порядок',
	'Rating': 'Рейтинг',
	'Group': 'Группа',
	'Athlete': 'Боец',
	'Promotion': 'Промоушен',
	'Other': 'Другие',
	'StatsFightRating': 'Stats Fight Rating',
	'GeneralInformation': 'Общая информация',
	'GeneralRating': 'Общий рейтинг',
	'Force': 'Сила',
	'Speed': 'Скорость',
	'Takedown': 'Тейкдаун',
	'Defense': 'Защита',
	'Efficiency': 'Эффективность',
	'TV/Stream': 'ТВ/Стрим',
	'LastFights': 'Рекорд',
	'StatsFighterRatingToolTip': 'Stats Fight Rating - это продукт компьютерного алгоритма, основанного на более чем 100 статистических данных бойцов',
	'UserCardToolTip1': 'Карточки можно купить только за монеты на экране Магазин. Монеты также можно получить, играя в фэнтази MMA',
	'UserCardToolTip2': 'играй в фэнтази MMA',
	'UserCardToolTip3': 'смотря рекламу',
	'UserCardToolTip4': 'покупая за реальные деньги на экране Магазин',
	'NoAds': 'Реклама сейчас недоступна, попробуйте позже',
	'NoMoreAdsToday': 'Просмотр рекламы будет доступен завтра',

	'YourGyms': 'Ваши залы',
	'gym': 'зал',
	'rating': 'рейтинг',
	'GymPageTitle': 'Stats Fight Rating вашего общего зала',
	'Add': 'Добавить',
	'AddCardToGym': 'Выберите карточку',
	'NoWeightCards': 'У вас нет карточки для этой весовой категории',
	'GeneralGym': 'Общий зал',
	'GymOnBoard': '1. Нажмите на эту кнопку, чтобы собрать зал из ваших карточек бойцов',
	'GymOnBoard1': '2. Добавьте или измените свою карточку, нажав на это поле',
	'GymOnBoard2': '3. Затем вы увидите карточку конкретной весовой категории',
	'GymOnBoard3': '4. Собрав свой зал, вы получите рейтинг своего зала. Собирайте больше карточек, добавляйте их в зал, чтобы увеличить рейтинг',

	'course-fight.strikes': 'Удары',
	'course-fight.technique': 'Техника',
	'course-fight.position': 'Позиция',
	'course-fight.open': 'Посмотреть динамику поединка',
	'TechnicalArsenal': 'Технический арсенал',
	'compare-fighters.open': 'Посмотреть техн. арсенал бойцов',
	'course-fight.numbers-strikes': 'Количество ударов (попадание/Выброшенные)',

	'compare-stat.general': 'Общее',
	'compare-stat.distance': 'Дистанция',
	'compare-stat.clinch': 'Клинч',
	'compare-stat.ground': 'Партер',
	'compare-stat.attack': 'Атака',
	'compare-stat.defence': 'Защита',
	'compare-stat.punches-stat': 'Статистика ударов (за раунд)',

	'compare-stat.punches-stat.critical': 'Критические',
	'compare-stat.punches-stat.landed': 'Попадания',
	'compare-stat.punches-stat.total': 'Всего',
	'compare-stat.punches-stat.punches-efficiency': 'Эффективность ударов',
	'compare-stat.types-punches': 'Процент типов ударов',

	'compare-stat.punches-stat.successful': 'Успешно',
	'compare-stat.punches-stat.attempts': 'Попытки',

	'compare-stat.punches-stat.punch': 'Руки',
	'compare-stat.punches-stat.elbow': 'Локоть',
	'compare-stat.punches-stat.kick': 'Ноги',
	'compare-stat.punches-stat.knee': 'Коленом',

	'compare-stat.punches-zones': 'Зоны ударов',
	'compare-stat.punches-zones.head': 'Голова',
	'compare-stat.punches-zones.body': 'Корпус',
	'compare-stat.punches-zones.legs': 'Ноги',

	'compare-stat.ground-stat': 'Среднее количество попыток (за раунд)',
	'compare-stat.ground-stat.takedowns': 'Тейкдауны',
	'compare-stat.ground-stat.transitions': 'Проходы в ноги',

	'compare-stat.zone.submission-at': 'Количество попыток сабмишенов (за 15 минут)',
	'compare-stat.zone.chokehold': 'Удушающий',
	'compare-stat.zone.left-arm': 'Левая рука',
	'compare-stat.zone.right-arm': 'Правая рука',
	'compare-stat.zone.right-leg': 'Правая нога',
	'compare-stat.zone.left-leg': 'Левая нога',

	'compare-stat.efficiency': 'Эффективность, %',
	'compare-stat.defence.avg-by-zone': 'Среднее количество принимаемых ударов по зонам',
	'compare-stat.defence.eff-submission': 'Эффективность защиты от сабмишенов (за 15 минут)',
	'compare-stat.defence.tt': 'Эффективность обороны (за 15 минут)',

	'compare-stat.avg.distance': 'Средний процент (время) нахождения в позиции Стойка',
	'compare-stat.avg.clinch': 'Средний процент (время) нахождения в позиции Клинч',
	'compare-stat.avg.parter': 'Средний процент (время) нахождения в позиции Партер',

	'battle-list.appbar.tooltip': 'Сыграй в фэнтези MMA на следующем мероприятии и выиграй монеты, получи ранг и PTS',

	'fantasy-list.past': 'Итоги',
	'fantasy-list.upcoming': 'Скоро',
	'fantasy-list.live': 'Live',
	'fantasy-list.make-picks': 'Играть',
	'fantasy-list.live.text': 'Мероприятие переходит в закладку Live от начала первого и до окончания последнего поединка.',
	'fantasy-list.past.text': 'Смотрите результаты и статистику прошлых боев',
	'fantasy-list.make-picks.text': 'Чаще всего, игры открываются за день до события. Включите уведомления, чтобы не пропустить.',
	'fantasy-list.upcoming.text': 'Мы уже работаем над обновлением календаря будущих событий',

	'battle-item.who-win': 'Кто победит?',
	'battle-item.pick-made': 'Выбор сделан',

	'reward.bonus-points': 'Бонусные монеты',

	'reward.tab-to-skip': 'Нажмите Tab, чтобы пропустить',
	'reward.your-got': 'Вы получили',
	'reward.tab-to-continue': 'Нажмите Tab, чтобы продолжить',
	'reward.get-a-reward': 'Получить награду',
	'reward.alredy-got': 'Эта награда уже получена',
	'reward.not-yet': 'Эта награда будет доступна через',
	'reward.no-sub': 'Получите Stats Fight+, чтобы разблокировать премиальные награды',
	'reward.lost': 'Эта награда упущена, попробуйте получить ее на следующей неделе',

	'battle-list.event-reward': 'Забрать набор карточек этого мероприятия',
	'battle.list.event-reward-msg': 'Эта награда будет доступна через ',
	'battle-list.fighter-not-open': 'Вы сможете выбрать бойца, когда мероприятие перейдет в раздел "Играть"; сейчас вы можете увидеть предварительную статистику бойцов',
	'battle-list.fighter-past': 'Нельзя выбирать бойца в прошедшем мероприятии, сейчас вы можете посмотреть Live Stats боя',

	'user-card-page.additional-point-msg': 'Эта карточка позволит вам преумножить свои выигрыши в Фэнтези MMA, выбирая Дополнительное преимущество. Этот тип карточки исчезает после использования',
	'user-card-page.win-point-msg': 'Эта карточка позволит вам преумножить свои выигрыши в Фэнтези MMA, выбирая Метод победы. Этот тип карточки исчезает после использования',
	'user-card-page.bonus-point-msg': 'Эта карточка позволит вам преумножить свои выигрыши в Фэнтези MMA, выбирая Бонусные монеты. Этот тип карточки исчезает после использования',
	'user-card-page.promotion-msg':'Эта карточка позволит вам преумножить свои выигрыши в Фэнтези MMA на последнем этапе подтверждения вашего прогноза. Карточки этого типа исчезают после использования',
	'user-card-page.country-msg': 'Эта карточка позволит вам преумножить свои выигрыши в Фэнтези MMA на последнем этапе подтверждения вашего прогноза. Карты этого типа исчезают после использования.',

	'athlete-rating-page.msg': 'Эта карточка позволит автоматически увеличить ваши выигрыши в Фэнтези MMA, выбирая самого бойца. Карты этого типа не исчезают после использования',
	'coins_bank': 'Баланс монет',
	'guaranteed_rewards': 'Гарантированные награды',
	'possible_rewards': 'Возможные награды',
	'one_possible_rewards': 'Возможные награды',
	'possible_rewards.sub-title': 'Представленные карточки показаны только для примера, их получение не гарантируется',
	'fighter_card': "КАРТОЧКА БОЙЦА",

	"onboarding_1.title": "Играй в наш уникальный  Фэнтази ММА",
	"onboarding_1.desc": "Предсказывай не только победителя, метод победы, но и статистическое преимущество бойца",

	"onboarding_2.title": "Выигрывай виртуальную валюту — монеты",
	"onboarding_2.desc": "За каждый удачный прогноз на бой вы получаете виртуальную валюту — монеты",

	"onboarding_3.title": "Покупай карточки бойцов, показателей и др. ",
	"onboarding_3.desc": "С помощью монет вы можете покупать карточки бойцов, показатели,промоушенов и стран, чтобы увеличить свой выигрыш в следующий раз",

	"onboarding_4.title": "Игры открываются за день до мероприятия",
	"onboarding_4.desc": "Игра начинается за день до события, когда становится известна финальный кард предстоящего события",

	"onboarding_5.title": "Зарегистрируйся и начни играть",
	"onboarding_5.desc": "Не упустите шанс подняться на новый уровень просмотра ММА с помощью Stats Fight",

	'other_card': 'Другая карточка',

	'login_every_day': 'Заходите каждый день, чтобы выиграть награды',
	'Rewards': 'Награды',
	'daily_login': 'Ежедневный вход',
	'ends_in': 'Истекает: {0}дн {1}ч',
	'ends_in_hour': 'Истекает: {1}ч',

	'in': 'через {0}д {1}ч',
	'in_hour': 'через {1}ч',

	'Feed': 'Турнир',


	'Course.Takedown': 'Успешный текдаун',
	'Course.Att-Takedown' : 'Попытка текдауна',
	'Course.Transition' : 'Успешный проход в ноги',
	'Course.Att-Transition': 'Попытка прохода в ноги',
	'Course.Submission' : 'Успешный сабмишен',
	'Course.Att-Submission' : 'Попытка сабмишена',
	'Odds': 'Коэффициенты ставок',
	'fighter-card': 'Карточка бойца',

	'Sign up now': 'Зарегистрироваться',
	'subdetails.new-look' :'Новый взгляд на боевые искусства',
	'subdetails.additional-features': 'Дополнительные функции и преимущества только для наших подписчиков Stats Fight +',
	'sub.feature.1': 'Средний процент (время) пребывания в позициях',
	'sub.feature.2': 'Процентное соотношение типов ударов',
	'sub.feature.3': 'Среднее количество ударов по зонам',
	'sub.feature.4': 'Количество попыток тейкдауна и сабмишенов',
	'sub.feature.5': 'и многое другое',
	'sub.feature.6': 'Динамика ударов',
	'sub.feature.7': 'Временные отметки техники',
	'sub.feature.8': 'Временные зоны позиций',
	'sub.feature.9': 'Получи больше монет',
	'sub.feature.10': 'Получи больше преимуществ и бонусных карточек',
	'sub.feature.11': 'Получи лучшие карточки бойцов',

	'sub-info.1': 'Стоимость подписки указана за один месяц',
	'sub-info.2': 'Подписка будет автоматически продлеваться каждый месяц',
	'sub-info.3': 'Вы можете отменить свою подписку в любое время на экране Настройки профиля - Подписки, переключив кнопку в неактивный режим и подтвердив отмену.',
	'sub-info.4': 'Для получения дополнительной информации о подписке, пожалуйста, перейдите по ссылке - Правила и условия подписки',


	'Achievements': 'Достижения',
	'Achievements.Coins': 'Монеты',
	'Achievements.Play': 'Игры',
	'Achievements.Rank': 'Ранк',
	'Achievements.Gym': 'Залы',
	'Achievements.Event': 'События',

	'Achievements.Play.top-10': 'Топ 10',
	'Achievements.Play.top-5': 'Топ 5',
	'Achievements.Play.top-1': 'Топ 1',
	'Achievements.Play.10-top-5': '10x Топ 5',
	'Achievements.Play.10-top-3': '10x Топ 3',
	'Achievements.Play.10-top-1': '10x Топ 1',

	'Achievements.Next': 'Следующая цель',
	'Achievements.Next.coins': 'Заработывайте больше монет',
	'Achievements.Next.play_fantasy': 'Играйте в фэнтези',
	'Achievements.Next.general_raiting_gym': 'Получите общий рейтинг зала',
	'Achievements.Next.rank': 'Побеждайте и получайте новый рейтинг',

	'battles.rating': 'Stats Fight Performance - это показатель производительности бойца ММА, показывающий эффективность использования всего комплекса технического арсенала бойца смешенных единоборств в поединке, направленного на достижение победы.'
}

import React from 'react';
import {Image, Platform, Pressable, StyleSheet, Text, View} from 'react-native';
import {MAIN_BAR_COLOR, ORANGE_COLOR} from '../colors';
import {getWidthExpression} from '../func';
import {OnBoardingStyles} from "../Onboarding";
import ToolTipButton from "../controls/ToolTipButton";


interface IProps {
	onPress?: Function,
	mainText: string,
	points: string | number,
	cardCoefText?: string | number
	selected?: boolean,
	enabled?: boolean,
	tooltip?: string,
	onBordVis?: boolean,
	onBordVisHint?: boolean,
	onBoardTextVis?: boolean
}

interface IState {
	modalVisible: boolean,
	y: number,
	x: number
}


export default class CheckBox extends React.Component<IProps, IState> {

	constructor(props: any)
	{
		super(props);
		this.state = {
			modalVisible: false,
			x: 0,
			y: 0
		};
	}


	getNameWidth()
	{
		if (this.props.tooltip)
			return getWidthExpression(Platform.OS == 'web' ? 48 + 8 + 48 : 48 + 48 + 32 + 18)

		return getWidthExpression(Platform.OS == 'web' ? 48 + 8  : 48  + 32 + 18)
	}

	render()
	{
		let selected = this.props.selected;
		return <Pressable style={[styles.root]}
								onPress={() => this.props.enabled !== false && this.props.onPress && this.props.onPress()}>
			<View
				 style={[styles.name, selected && styles.selectedName,
					 this.props.enabled === false && styles.disabledRect,
					 {width: this.getNameWidth()},
					  this.props.onBoardTextVis && OnBoardingStyles.highlight
				 ]}>
				<Text style={[styles.nameText, selected && styles.selectedNameText]}>{this.props.mainText}</Text>
			</View>
			<View
				 style={[styles.point,
					 selected && styles.selectedPoint,
					 this.props.enabled === false && styles.disabledRect,
					 this.props.onBordVis && OnBoardingStyles.highlight
				 ]}>
				<Text style={[styles.pointText, selected && styles.selectedPointText]}>{this.props.points}</Text>
				{this.props.cardCoefText && <Text style={[styles.coefText]}>{this.props.cardCoefText}x</Text>}
			</View>

			{this.props.tooltip &&
				<View style={[{height: 48, width: 48, alignItems: 'center', justifyContent: 'center'}, this.props.onBordVisHint && OnBoardingStyles.highlight]}>
					<ToolTipButton text={this.props.tooltip}>
						<Image style={styles.infoImage} source={require('../icons/info.png')}/>
					</ToolTipButton>
				</View>
			}

		</Pressable>
	}
}


const styles = StyleSheet.create({
	infoImage: {
		width: 20,
		height: 20
	},
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexDirection: 'row',
		marginBottom: 8,
		marginLeft: 4,
		overflow: 'visible',
	},
	name: {
		height: 48,
		backgroundColor: '#FFFFFF',
		elevation: 3,
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
		borderRadius: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		borderWidth: 2,
		borderColor: 'transparent'
	}  as any,
	disabledRect: {
		backgroundColor: '#EFF1F7',
	},
	selectedName: {
		backgroundColor: MAIN_BAR_COLOR,
	},
	nameText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 12,
		marginLeft: 16,

	},
	selectedNameText: {
		color: '#FFFFFF',
	},
	point: {
		height: 48,
		width: 48,
		marginLeft: 8,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#FFFFFF',
		elevation: 3,
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
		borderRadius: 10,
	}  as any,
	selectedPoint: {
		backgroundColor: MAIN_BAR_COLOR,
	},
	pointText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 14,
	},
	selectedPointText: {
		color: '#FFFFFF',
	},
	coefText: {
		position: 'absolute',
		top: -3,
		right: -7,
		fontSize: 10,
		borderRadius: 20,
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: ORANGE_COLOR,
		color: '#FFFFFF',
		fontWeight: '700',
	},
});

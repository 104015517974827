import {SafeAreaView, Text, StyleSheet, View, ScrollView} from 'react-native';

import React from 'react';
import {CardType, DistributionPoint, FantasyInfo, FantasyRateRow, Fight, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';
import CheckBox from './CheckBox';
import {MAIN_BAR_COLOR} from '../colors';
import FighterAppBar from './FighterAppBar';
import {getData, getHeightExpression, saveData} from '../func';
import StepProgress from '../controls/StepProgress';
import {ServerAPI} from "../ServerAPI";
import BoardStep from "../controls/BoardStep";
import {CardList} from "../shop/CardShop";


interface IProps {
    athlete: Fighter;
    fantasy: FantasyInfo;
    fantasyRateRow: FantasyRateRow;
    onBackClick: Function
    onNextStepClick: Function
    fight: Fight
    userCards: UserCard[]
}

interface IState {
    athlete: Fighter,
    fight: Fight,
    items: DistributionPoint[];
    fantasyRateRow: FantasyRateRow,
    fantasy: FantasyInfo,
    userCards: UserCard[],
    onboardStep: number | null
}

const ONBoardSteps = [
    'SelectFighter4_OnBoard1',
    'SelectFighter4_OnBoard2',
]


export default class SelectFighter4 extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            onboardStep: null,
            items: [],
            userCards: props.userCards,
            fantasyRateRow: props.fantasyRateRow,
            athlete: props.athlete,
            fantasy: props.fantasy,
            fight: props.fight
        };
    }

    onBoardNextClick() {
        if (this.state.onboardStep!! + 1 == ONBoardSteps.length)
            this.doneOnboarding()
        else
            this.setState({onboardStep: this.state.onboardStep!! + 1})
    }

    onBoardSkipClick() {
        this.doneOnboarding()
    }

    doneOnboarding() {
        this.setState({onboardStep: null})

        saveData('OnBoardingDone_SelectFighter4', 'done')
    }


    componentDidMount() {
        this.refresh();
        this.updateOnBoarding()
    }

    async updateOnBoarding() {
        let res = await getData('OnBoardingDone_SelectFighter4');

        if (!res)
            this.setState({
                onboardStep: 0
            })
    }

    async refresh() {

        let items = await ServerAPI.getDistributionPoints();

        this.setState({
            items: items
        });
    }


    onCardSelected(userCard: UserCard) {
        let isSelected = !!this.state.fantasyRateRow.SelectedUserCards?.find(it => it == userCard.id);
        if (!isSelected) {
            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    SelectedUserCards: [...this.state.fantasyRateRow.SelectedUserCards, userCard.id],
                },
            });
        } else {
            this.setState({
                fantasyRateRow: {
                    ...this.state.fantasyRateRow,
                    SelectedUserCards: this.state.fantasyRateRow.SelectedUserCards.filter(it => it != userCard.id),
                },
            });
        }
    }

    isCardSelected(userCard: UserCard) {
        return !!this.state.fantasyRateRow.SelectedUserCards.find(it => it == userCard.id)
    }

    isPointSelected(item: DistributionPoint) {
        return !!this.state.fantasyRateRow.AdPoints?.find(it => it.Id == item.Id);
    }

    onReadyClick() {
        this.props.onNextStepClick(this.state.fantasyRateRow);
    }

    onBackClick() {
        this.props.onBackClick();
    }

    render() {
        let points = this.state.items;

        let ad1List = points.filter(it => it.Type == 'Additional1' && this.isPointSelected(it));
        let ad2List = points.filter(it => it.Type == 'Additional2' && it.Points > 0);

        let winningItem = points.find(it => it.Id == 'Win');

        let selectedCards = this.state.fantasyRateRow?.SelectedUserCards;

        let extraList = selectedCards ? points.filter(p => p.Type == 'Extra' && selectedCards.find(it => it == p.Key)) : [];

        let nonPointsCards = this.state.userCards.filter(it => it.name == CardType.Country && it.exCardData == this.state.athlete.countryId
            || it.name == CardType.Promotion && it.exCardData == this.state.fight?.promotionId
        );

        return (
            <SafeAreaView style={styles.root}>


                {this.state.onboardStep != null && <View style={{
                    width: '100%',
                    height: '100%',
                    padding: 16,
                    position: 'absolute',
                    zIndex: 2
                }}>
                    <BoardStep onNextClick={() => this.onBoardNextClick()}
                               currentStep={this.state.onboardStep}
                               position={'bottom'}
                               stepCount={ONBoardSteps.length}
                               text={(TRANSLATES as any)[ONBoardSteps[this.state.onboardStep]]}
                               onSkipClick={() => this.onBoardSkipClick()}/>

                </View>}
				 

                <ScrollView style={styles.container}>

                    <Text style={styles.chooseText}>
                        {TRANSLATES['Step4Title1']}
                    </Text>

                    {winningItem &&
                        <CheckBox
                            key={winningItem.Id}
                            selected={true}
                            mainText={winningItem.Name}
                            points={winningItem.Points}
                        />
                    }



                    {ad1List.map(p => <CheckBox
                        key={p.Id}
                        cardCoefText={this.state.userCards.find(it => this.isCardSelected(it) && it.fantasyPointId == p.Key)?.statusCoef}
                        selected={this.isPointSelected(p)}
                        enabled={this.isPointSelected(p)}
                        mainText={TRANSLATES[p.Id] || p.Name}
                        points={p.Points}
                    />)}

                    <Text style={styles.chooseText}>
                        {TRANSLATES['Step4Title2']}
                    </Text>

                    {ad2List.map(p => <CheckBox
                        key={p.Id}
                        cardCoefText={this.state.userCards.find(it => this.isCardSelected(it) && it.fantasyPointId == p.Key)?.statusCoef}
                        selected={this.isPointSelected(p)}
                        enabled={this.isPointSelected(p)}
                        mainText={TRANSLATES[p.Id] || p.Name}
                        points={p.Points}
                    />)}

                    {extraList?.length > 0 && <Text style={styles.chooseText}>
                        {TRANSLATES['Step4Title3']}
                    </Text>
                    }

                    {extraList.map(ep =>
                        <CheckBox
                            key={ep.Id}
                            selected={true}
                            mainText={TRANSLATES[ep.Id] || ep.Name}
                            points={ep.Points}
                        />)
                    }

                    <CardList
                        showText={nonPointsCards.length > 0}
                        selectedList={nonPointsCards.filter(it => this.state.fantasyRateRow.SelectedUserCards.find(id => it.id == id))}
                        onCardSelected={(uc: UserCard) => this.onCardSelected(uc)}
                        list={nonPointsCards}/>


                </ScrollView>

                <View style={styles.buttons}>

                    <FButton text={TRANSLATES['Back']} width={160} onClick={() => this.onBackClick()}/>
                    <FButton text={TRANSLATES['Ready']} width={160} onClick={() => this.onReadyClick()}
                             backgroundColor={MAIN_BAR_COLOR}
                             color="#FFFFFF"/>
                </View>


            </SafeAreaView>)
    }
}


const styles = StyleSheet.create({
    chooseText: {
        fontWeight: '700',
        fontSize: 15,
        marginTop: 16,
        marginBottom: 16,
        color: '#2E2D2D',
    },
    container: {
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16
    },
    root: {
        height: '100%'
    },
    buttons: {
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16
    },
});

import React, {useEffect, useState} from 'react';
import {Dimensions, GestureResponderEvent, ScrollView, StyleSheet, View} from 'react-native';
import {Svg, Path, G, Line, Rect, Text, Circle} from 'react-native-svg';
import * as d3 from 'd3';
import {ServerAPI} from "../ServerAPI";
import GFightBattle from "./GFightBattle";
import {CourseFightData, CourseFighterRoundData, MMABattleResponse, PositionTime} from "./types";
import {BattleStatus, Fight} from "../types";
import TRANSLATES from "../translates/translates";
import TPressable from "../controls/TPressable";
import {styles} from "../profile/Tabs";
import {Text as ReactText} from 'react-native';
import {getStepInfo, hexToRgbA, secondsToTime2, timeToSec} from "../func";
import {COLORS} from "../colors";
import MessageToolTip from "../controls/MessageToolTip";

type DataPoint = {
    name: string;
    value: number;
};

type StepInfo = {
    step: number,
    max: number
}

const getOpacityPosition = (isGround: boolean) => {
    return isGround ? 0.5 : 0.2
}

const padding = 16

let timeId: any = null

export default function CourseFight(props: {
    height: number,
    battleId: string,
    fight: Fight
}) {

    const [stat, setStat] = useState<MMABattleResponse | null>(null)

    const [layers, setLayers] = useState<{
        [key: string]: boolean
    }>({
        strikes: true,
        technique: false,
        position: false
    })

    const loadData = async () => {
        let res = await ServerAPI.get<MMABattleResponse>(`/liveStat/${props.battleId}?online=true&round=-1`);
        setStat(res)

        if (res.battle.Status != BattleStatus.Finished)
            timeId = setTimeout(() => loadData(), 1500)
    }

    useEffect(() => {
        if (props.battleId)
            loadData()

        return () => clearTimeout(timeId)
    }, [props.battleId])


    let legendHeight = 24 + 12
    let headerHeight = 136
    let w = Dimensions.get('window').width
    let h = props.height - 16 - 24 - 32 - headerHeight - 20 - 16 - 16 - 40 - legendHeight   //тут все отступы и размеры верхних панелей

    const tabSelected = (name: string) => {
        setLayers({
            ...layers,
            [name]: !layers[name]
        })
    }

    const stepInfo = roundToMax(stat?.courseFightData)


    return (<View style={courseStyles.container}>

        <View style={{width: '100%', paddingLeft: padding, paddingRight: padding}}>
            <GFightBattle fight={props.fight}
                          advantage={stat?.advantage}
                          onboardStep={null}

                          red={props.fight.red}
                          blue={props.fight.blue}/>
        </View>

        <View style={courseStyles.tabs}>
            <TPressable
                name="strikes"
                onPress={() => tabSelected('strikes')}
                style={[styles.tab, layers.strikes ? styles.selectedTab : null]}>
                <ReactText style={styles.textTab}>
                    {TRANSLATES['course-fight.strikes']}
                </ReactText>
            </TPressable>
            <TPressable
                name="technique"
                onPress={() => tabSelected('technique')}
                style={[styles.tab, layers.technique ? styles.selectedTab : null]}>
                <ReactText style={styles.textTab}>
                    {TRANSLATES['course-fight.technique']}
                </ReactText>
            </TPressable>
            <TPressable
                name="strikes"
                onPress={() => tabSelected('position')}
                style={[styles.tab, layers.position ? styles.selectedTab : null]}>
                <ReactText style={styles.textTab}>
                    {TRANSLATES['course-fight.position']}
                </ReactText>
            </TPressable>
        </View>

        <ScrollView style={[courseStyles.scrollView, {height: h}]}>
            {stat?.courseFightData?.redByRoundData.map((red, idx) =>
                <RoundChart h={h} w={w} red={red}
                            key={idx}
                            isLive={stat?.battle.Status != BattleStatus.Finished}
                            endRoundTimeSeconds={stat?.battle.RoundLength * 60}
                            currentRoundTime={stat.roundTotalTime / 1000}
                            stepInfo={stepInfo}
                            positionTime={stat?.courseFightData.positionByRound[idx]}
                            layers={layers}
                            round={idx + 1}
                            blue={stat?.courseFightData.blueByRoundData[idx]}/>
            )}
        </ScrollView>
        <ReactText style={courseStyles.nameAxisText}>
            {TRANSLATES['course-fight.numbers-strikes']}
        </ReactText>
        <View style={courseStyles.legend}>
            <View style={[courseStyles.legendItem, {backgroundColor: hexToRgbA(COLORS.RED, getOpacityPosition(true))}]}>
                <ReactText
                    style={[courseStyles.legendItemText, {color: COLORS.RED}]}>{TRANSLATES['GroundControl']}</ReactText>
            </View>
            <View
                style={[courseStyles.legendItem, {backgroundColor: hexToRgbA(COLORS.RED, getOpacityPosition(false))}]}>
                <ReactText
                    style={[courseStyles.legendItemText, {color: COLORS.RED}]}>{TRANSLATES['ClinchControl']}</ReactText>
            </View>
            <View style={[courseStyles.legendItem, {backgroundColor: hexToRgbA(COLORS.GRAY, 0.2)}]}>
                <ReactText
                    style={[courseStyles.legendItemText, {color: COLORS.GRAY}]}>{TRANSLATES['Distance']}</ReactText>
            </View>
            <View
                style={[courseStyles.legendItem, {backgroundColor: hexToRgbA(COLORS.BLUE, getOpacityPosition(false))}]}>
                <ReactText
                    style={[courseStyles.legendItemText, {color: COLORS.BLUE}]}>{TRANSLATES['ClinchControl']}</ReactText>
            </View>
            <View
                style={[courseStyles.legendItem, {backgroundColor: hexToRgbA(COLORS.BLUE, getOpacityPosition(true))}]}>
                <ReactText
                    style={[courseStyles.legendItemText, {color: COLORS.BLUE}]}>{TRANSLATES['GroundControl']}</ReactText>
            </View>
        </View>
    </View>)
}


const courseStyles = StyleSheet.create({
    legend: {
        marginTop: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: 20,
        paddingRight: 26
    },
    legendItem: {
        width: 64,
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4
    },
    legendItemText: {
        textAlign: 'center',
        fontSize: 10,
        fontWeight: '700'
    },
    nameAxisText: {
        color: COLORS.GRAY,
        fontSize: 10,
        marginTop: 8,
        fontWeight: '500'
    },
    tabs: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: padding,
        paddingRight: padding,
        marginTop: 32
    },
    container: {
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    scrollView: {
        marginTop: 16
    },
    selectedTab: {
        backgroundColor: COLORS.MAIN_BAR_COLOR,
    },
    textTab: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 12
    },
    tab: {
        backgroundColor: COLORS.GRAY,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 20,
        width: 104,
        alignItems: 'center',
        justifyContent: "center"
    },
});


function roundToMax(data: CourseFightData | undefined): StepInfo | null {
    if (!data)
        return null

    let arr: number[] = []

    for (let i = 0; i < data.blueByRoundData.length; i++) {
        let red = data.redByRoundData[i];
        if (red)
            for (let key in red.seconds30Data)
                arr.push(red.seconds30Data[key].thrown)

        let blue = data.blueByRoundData[i];
        if (blue)
            for (let key in blue.seconds30Data)
                arr.push(blue.seconds30Data[key].thrown)
    }

    let max = Math.max(...arr)

    return getStepInfo(max)
}

const EMPTY_ROUND_DATA = {
    seconds30Data: {}
} as CourseFighterRoundData

const XAxisHeight = 20

const RoundChart = ({
                        red,
                        blue,
                        h,
                        w,
                        round,
                        layers,
                        positionTime,
                        stepInfo,
                        currentRoundTime,
                        endRoundTimeSeconds,
                        isLive
                    }: {
    w: number,
    h: number,
    isLive: boolean,
    red: CourseFighterRoundData,
    blue: CourseFighterRoundData,
    positionTime: PositionTime[]
    round: number,
    endRoundTimeSeconds: number,        //300 sec
    currentRoundTime: number,
    stepInfo: StepInfo | null
    layers: {
        [key: string]: boolean
    }
}) => {

    const [toolTipText, setToolTipText] = useState<string>('')

    if (round == 1)
        h -= XAxisHeight

    if (!stepInfo)
        stepInfo = {max: 10, step: 2}

    if (!red)
        red = EMPTY_ROUND_DATA

    if (!blue)
        blue = EMPTY_ROUND_DATA


    let xAxisLabels: number[] = []

    for (let i = -stepInfo.max; i <= stepInfo.max; i += stepInfo.step) {
        xAxisLabels.push(i)
    }

    let times: string[] = []

    for (let sec30 = 0; sec30 <= endRoundTimeSeconds; sec30 += 30)
        times.push(secondsToTime2(sec30))

    const axisFontSize = 10
    const startX = padding
    const endX = w - 2 * padding;


    const getX = d3.scaleLinear()
        .domain([xAxisLabels[0], xAxisLabels[xAxisLabels.length - 1]])
        .range([startX, endX]);

    const getY = d3.scalePoint()
        .domain(times.map(it => it))
        .range([0, h]);

    const getYTimePoint = d3.scaleLinear()
        .domain([0, endRoundTimeSeconds])
        .range([0, h]);

    const dataMaker = (isLeft: boolean, prop: string): DataPoint[] => {
        let data = isLeft ? red : blue
        return times.map(t => {
            let d = (data.seconds30Data['00:0' + t] as any)
            return {
                name: t,
                value: d ? d[prop] : 0
            } as DataPoint
        })
    }


    const getXWithOffset = (value: number) => getX(value) + axisFontSize / 2


    const renderXAxis = () => {
        return <G>
            {xAxisLabels.map((item, index) => {
                return (<G key={index}>
                        <Line
                            x1={axisFontSize / 2 + getX(item)}
                            y1={axisFontSize + 6}
                            x2={axisFontSize / 2 + getX(item)}
                            y2={axisFontSize + 10}
                            stroke={COLORS.GRAY}
                            strokeWidth={1}
                        />
                        <Text x={item == 0 ? getX(item) + 2.5 : getX(item)}
                              fill={COLORS.GRAY}
                              fontSize={axisFontSize}
                              fontWeight={700}
                              key={index}
                              y={axisFontSize}>
                            {Math.abs(item)}
                        </Text>
                    </G>
                );
            })}
        </G>
    }

    const renderYAxis = () => {
        return <G>
            {times.map((item, index) => {
                let y = getY(item)!!;
                return (index > 0 &&
                    <Text x={w / 2}
                          fill={COLORS.GRAY}
                          fontWeight={700}
                          fontSize={axisFontSize}
                          key={index}
                          y={y - 5}>{item}</Text>
                );
            })}
        </G>
    }

    const renderGrid = () => {
        let horY2 = getY(times[times.length - 1])!!;
        return <G>
            {xAxisLabels.map((num, index) => {
                let x = getXWithOffset(num)
                return <Line
                    x1={x}
                    y1={0}
                    x2={x}
                    y2={horY2}
                    stroke={COLORS.GRAY}
                    strokeWidth={1}
                    key={index}
                />
            })}

            {times.map((item, index) => {
                let y = getY(item);

                return (
                    <Line
                        x1={startX + axisFontSize / 2}
                        y1={y}
                        x2={endX + axisFontSize / 2}
                        y2={y}
                        stroke={COLORS.GRAY}
                        strokeWidth={1}
                        key={index}
                    />
                );
            })}
        </G>
    }

    const renderStikesLayer = () => {

        let rightThrownData = dataMaker(false, 'thrown')
        let rightLandedData = dataMaker(false, 'landed')
        let leftThrownData = dataMaker(true, 'thrown')
        let leftLandedData = dataMaker(true, 'landed')

        const strokePathRight = d3
            .line<DataPoint>()
            .x(d => getXWithOffset(d.value))
            .y(d => getY(d.name)!!)
            .curve(d3.curveMonotoneY)(rightLandedData);

        const areaPathRight = d3
            .area<DataPoint>()
            .x(d => getXWithOffset(d.value))
            .y0(d => getY(d.name)!!)
            .y1(getY(times[times.length - 1])!!)
            .curve(d3.curveMonotoneY)(rightLandedData);

        const areaPathLeft = d3
            .area<DataPoint>()
            .x(d => getXWithOffset(-d.value))
            .y0(d => getY(d.name)!!)
            .y1(getY(times[times.length - 1])!!)
            .curve(d3.curveMonotoneY)(leftLandedData);

        const strokeAreaPathLeft = d3
            .line<DataPoint>()
            .x(d => getXWithOffset(-d.value))
            .y(d => getY(d.name)!!)
            .curve(d3.curveMonotoneY)(leftLandedData);

        const linePathLeft = d3
            .line<DataPoint>()
            .x(d => getXWithOffset(-d.value))
            .y(d => getY(d.name)!!)
            .curve(d3.curveMonotoneY)(leftThrownData);

        const linePathRight = d3
            .line<DataPoint>()
            .x(d => getXWithOffset(d.value))
            .y(d => getY(d.name)!!)
            .curve(d3.curveMonotoneY)(rightThrownData);

        return <G>
            <Path
                fill={COLORS.BLUE}
                fillOpacity={0.4}
                d={areaPathRight + ''}
            />
            <Path
                stroke={COLORS.DARK_BLUE}
                strokeWidth={2}
                strokeDasharray="5,5"
                fill={'none'}
                d={linePathRight + ''}
            />
            <Path
                stroke={COLORS.DARK_BLUE}
                strokeWidth={2}
                fill={'none'}
                d={strokePathRight + ''}
            />


            <Path
                fill={COLORS.RED}
                fillOpacity={0.4}
                d={areaPathLeft + ''}
            />
            <Path
                stroke={COLORS.RED}
                strokeWidth={2}
                strokeDasharray="5,5"
                fill={'none'}
                d={linePathLeft + ''}
            />
            <Path
                stroke={COLORS.RED}
                strokeWidth={2}
                fill={'none'}
                d={strokeAreaPathLeft + ''}
            />
        </G>
    }

    const onShowToolTipClick = (text: string, e: GestureResponderEvent) => {
        setToolTipText(text)
    }

    const renderTSLayer = () => {
        return <G>
            {positionTime.filter(it => it.action == 'Takedown' || it.action == 'Submission' || it.action == 'Transition')
                .map((item, index) => {
                    let r = 3.5
                    let cardWidth = 87
                    let cardHeight = 29
                    let seconds = timeToSec(item.roundTime)
                    let y = getYTimePoint(seconds);
                    let color = item.isRed ? COLORS.DARK_RED : COLORS.BLUE
                    let rectX = (item.isRed ? w / 4 : w * 3 / 4) - cardWidth / 2;
                    let key = item.answer == 'Hit' ? item.action : 'Att-' + item.action
                    let text = TRANSLATES[`Course.${key}`]


                    let fugure = item.action == 'Submission' ? <Rect
                            x={item.isRed ? rectX + cardWidth - 10 : rectX}
                            y={y - cardHeight / 2 - 7}
                            width={14}
                            // @ts-ignore
                            onClick={(e) => onShowToolTipClick(text, e)}
                            onPress={(e) => onShowToolTipClick(text, e)}
                            height={14}
                            fill={color}/> :
                        <Circle
                            onPress={(e) => onShowToolTipClick(text, e)}
                            // @ts-ignore
                            onClick={(e) => onShowToolTipClick(text, e)}
                            cx={item.isRed ? rectX + cardWidth : rectX}
                            r={7}
                            fill={color}
                            cy={y - cardHeight / 2}
                        />


                    return (<G key={index}>
                            {fugure}
                            {/*Connection line*/}
                            {item.isRed ?
                                <Line
                                    x1={rectX + cardWidth}
                                    y1={y - cardHeight / 2}
                                    x2={rectX + cardWidth + cardWidth / 3}
                                    y2={y - cardHeight / 2}
                                    stroke={color}
                                    strokeWidth={2}
                                />
                                : <Line
                                    x1={rectX}
                                    y1={y - cardHeight / 2}
                                    x2={rectX - cardWidth / 3}
                                    y2={y - cardHeight / 2}
                                    stroke={color}
                                    strokeWidth={2}
                                />
                            }
                            <Line
                                x2={w / 2 - r}
                                y2={y - r}
                                x1={item.isRed ? rectX + cardWidth + cardWidth / 3 : rectX - cardWidth / 3}
                                y1={y - cardHeight / 2}
                                stroke={color}
                                strokeWidth={2}
                            />
                            <Circle
                                cx={w / 2 - r}
                                r={r}
                                fill={color}
                                cy={y - r}
                                key={index}
                            />
                        </G>
                    );
                })}
        </G>
    }


    const renderDistanceRect = (y: number, height: number) => {
        return <G key={y + '-' + round}>
            <Rect
                x={startX + axisFontSize / 2}
                y={y}
                width={endX - startX}
                height={height}
                opacity={0.2}
                fill={COLORS.GRAY}/>
        </G>
    }


    const renderPositionLayer = () => {

        let addedIntervals = new Set<string>();    //key is start_end
        let res = <G>
            {positionTime
                .filter(it => it.action != 'Submission')
                .map((it, idx) => {
                    let gStart = it.groundStart || it.grappleStart
                    let gEnd = it.groundEnd || it.grappleEnd
                    let key = `${gStart}-${gEnd || currentRoundTime}`;

                    if (addedIntervals.has(key))
                        return;

                    let y = getYTimePoint(timeToSec(it.roundTime))
                    let endGTime = it.grappleEnd || it.groundEnd
                    let endTimeSec = endGTime ? timeToSec(endGTime) : currentRoundTime;
                    let duration = endTimeSec - timeToSec(it.roundTime)


                    let height = getYTimePoint(timeToSec(it.roundTime) + duration) - y
                    if (height <= 0)
                        return

                    addedIntervals.add(key)
                    let opacity = getOpacityPosition(!!it.groundStart)


                    if (it.isRed) {
                        return <G key={idx}>
                            <Rect
                                x={startX + axisFontSize / 2}
                                y={y}
                                width={getX(0) - padding}
                                height={height}
                                opacity={opacity}
                                fill={COLORS.RED}
                            />
                        </G>
                    } else {
                        return <G key={idx}>
                            <Rect
                                x={getX(0) + axisFontSize / 2}
                                y={y}
                                width={getX(0) - padding}
                                height={height}
                                opacity={opacity}
                                fill={COLORS.BLUE}/>
                        </G>
                    }
                })}
        </G>


        let currentDistanceTime = 0

        let distanceViews = Array.from(addedIntervals).map(it => {
            let arr = it.split('-')
            let startGG = timeToSec(arr[0])
            let endGG = timeToSec(arr[1])

            let startDistanceTime = currentDistanceTime;
            let endDistanceTime = startGG;


            currentDistanceTime = endGG

            if (endDistanceTime - startDistanceTime > 1) {
                let y = getYTimePoint(startDistanceTime)
                let height = getYTimePoint(endDistanceTime) - y
                if (height > 0)
                    return renderDistanceRect(y, height)
            }
        }).filter(it => !!it)


        if (distanceViews.length == 0) {
            distanceViews = [
                renderDistanceRect(getYTimePoint(0), isLive ? currentRoundTime : getYTimePoint(endRoundTimeSeconds))
            ]
        } else if (!isLive) {
            distanceViews.push(renderDistanceRect(getYTimePoint(currentDistanceTime),
                getYTimePoint(endRoundTimeSeconds)))
        }


        return <>
            {distanceViews}
            {res}
        </>
    }


    const getYNumber = (time: string) => getY(time) as number

    let gridCellHeight = getYNumber(times[times.length - 1]) - getYNumber(times[times.length - 2]);

    return <>
        {toolTipText ? <MessageToolTip message={toolTipText} onClose={() => setToolTipText('')}/> : <></>}

        {round == 1 && <Svg width={w} height={XAxisHeight}>
            {renderXAxis()}
        </Svg>
        }
        <Svg width={w} height={h}>

            <G>
                {renderGrid()}

                {layers.position && renderPositionLayer()}

                {layers.strikes && renderStikesLayer()}

                {layers.technique && renderTSLayer()}

                {renderYAxis()}

            </G>
            <G x={8 + padding} translateY={getYNumber(times[times.length - 1]) - gridCellHeight + 4}>
                <Rect
                    x="0"
                    y="0"
                    width="46"
                    height="24"
                    fill={COLORS.GRAY}
                    rx="10px"  // border radius
                />
                <Text
                    fill="white"
                    fontSize="12"
                    fontWeight='700'
                    x="12"
                    y="15"
                >
                    RD{round}
                </Text>
            </G>

        </Svg>
    </>
}

import {Platform} from 'react-native';
import DeviceInfo from "react-native-device-info";
import {getData, saveData, uuidv4} from "./func";
import {trackToCloud} from "./ads";
import {DistributionPoint, Tournament} from "./types";


export interface IErrorResponse {
	clazz?: string;
	message: string;
	status?: number;
	detail?: string;
	safeHTML?: string;
	IsUserException?: boolean;
	skipShow?: boolean;
}

let ServerURL = 'https://game-backend.bd-sport.com';
//
// if (Platform.OS == 'web')
// {
// 	if (location.href.indexOf('localhost') > -1)
// 		ServerURL = 'http://localhost';
// }

// else
// {
// 	ServerURL = 'http://10.0.2.2';
// }

export function getDeviceInfo()
{
	if (Platform.OS == 'web')
		return navigator.userAgent;

	return DeviceInfo.getBrand() + " " + DeviceInfo.getSystemVersion()
}

const APP_VERSION = '0.3';

let DEVICE_TOKEN = ''

let _countActiveRequests = 0;
let _countActiveRequestsNW = 0;
let idTimeStatus: any = null;

let AuthToken = '';

export const ServerAPI = {

	initDeviceToken: async function()
	{
		console.log('initDeviceToken')
		let value = await getData('DEVICE_TOKEN');
		if (value)
			DEVICE_TOKEN = value;
		else
		{
			DEVICE_TOKEN = uuidv4();
			saveData('DEVICE_TOKEN', DEVICE_TOKEN)
		}
	},

	hasAuth: function ()
	{
		return !!AuthToken;
	},


	setToken: function (value: string)
	{
		AuthToken = value;
	},

	getCountAR: function ()
	{
		return _countActiveRequests;
	},

	hasActiveRequest: function ()
	{
		return _countActiveRequests > 0;
	},

	_incActive: function (showWaiter: boolean)
	{
		if (showWaiter !== false)
		{
			_countActiveRequests++;
		}
		else
		{
			_countActiveRequestsNW++;
		}
	},

	_decActive: function (showWaiter: boolean)
	{
		if (showWaiter !== false)
		{
			_countActiveRequests--;
		}
		else
		{
			_countActiveRequestsNW--;
		}
	},

	sleep: function (timeout: number)
	{
		return new Promise((resolve) =>
		{
			setTimeout(() =>
			{
				resolve(null);
			}, timeout);
		});
	},

	track: function (eventName: string, eventData: string = "")
	{
		try
		{

			this.post("/sfight", {
				eventName: eventName,
				eventData: eventData,
				deviceInfo: getDeviceInfo(),
				userToken: AuthToken,
				tz: new Date().getTimezoneOffset() / 60,
				appVersion: APP_VERSION,
				deviceToken: DEVICE_TOKEN
			})

			trackToCloud(eventName, eventData)
		}
		catch (e)
		{
			console.log(e)
		}
	},
	retry: function (url: string, body: any = null, method: string = 'GET')
	{
		//API_ERROR.connectionLostCallback();
		return new Promise(async (resolve, reject) =>
		{
			for (let i = 0; i < 666; i++)
			{
				await this.sleep(5000);
				try
				{
					let response = await this.callAPI(url, body, method, false);
					//API_ERROR.connectionRestoreCallback();
					resolve(response);
					return;
				} catch (e)
				{
					//всё ещё недоступно
				}
			}
			reject();  //сдаёмся
		});
	},

	async logout()
	{
		await this.post('/fantasy/logout');
		this.clearToken();
	},

	clearToken()
	{
		AuthToken = '';
	},

	post: function <T>(url: string, body: any = null, showWaiter = true): Promise<T>
	{
		return this.callAPI<T>(url, body, 'POST', false, showWaiter);
	},

	get: function <T>(url: string, showWaiter = true): Promise<T>
	{
		return this.callAPI<T>(url, null, 'GET', true, showWaiter);
	},

	put: function <T>(url: string, body: any = null): Promise<T>
	{
		return this.callAPI<T>(url, body, 'PUT');
	},

	delet: function <T>(url: string, body: any = null): Promise<T>
	{
		return this.callAPI<T>(url, body, 'DELETE');
	},

	callAPI: function <T>(url: string,
								 body: any = null,
								 method: string = 'GET',
								 doRetry = true,
								 showWaiter = true): Promise<T>
	{
		this._incActive(showWaiter);
		// ServerAPI.EvStartRequest.fire(this, {
		// 	Show: showWaiter
		// });

		//console.log(`${method} ${ServerURL + url} ${JSON.stringify((body != null ? JSON.stringify(body) : '{}'))}`)

		return new Promise((resolve, reject) =>
		{
			let headers = {
				'Content-Type': 'application/json;charset=utf-8',
				'Accept': 'application/json',
				'Authorization': AuthToken,
			} as any;

			if (Platform.OS != 'web')
				headers['User-Agent'] = Platform.OS;

			fetch(ServerURL + '/mapi/v1' + url, {
				method: method,
				credentials: 'include',
				headers: headers,
				body: method == 'GET' ? null : (body != null ? JSON.stringify(body) : '{}'),
			}).then((response) =>
			{
				this._decActive(showWaiter);

				//console.log(`${url}, response.status=${response.status}`)

				if (response.status == 404 || response.status == 502)		//возможно приложение деплоится ещё или лежит
				{
					if (doRetry)
					{
						this.retry(url, body, method).then((r: any) => resolve(r));
					}
					else
					{
						reject(response);
					}
					return;
				}

				if (response.status >= 300)
				{
					response.text().then((errorResponse: string) =>
					{
						reject();
						alert(errorResponse);
						// ServerAPI.EvError.fire(this, {
						// 	message: errorResponse,
						// 	Status: response.status || 0
						// });
					});

					return Promise.reject(new Error(response.statusText));
				}
				return Promise.resolve(response);
			}).then((response: any) => response.headers.get('Content-Length') == '0' ? response.text() : response.json())
				 .then((response: any) =>
				 {
					 //console.log(`${method} ${url} ${JSON.stringify(response)}`)
					 return resolve(response);
				 })
				 .catch((response) =>
				 {
					 if (response.message.indexOf('Unexpected end of JSON input') > -1)
					 {
						 resolve(null as any);
					 }
					 else if (response.message.toLowerCase().indexOf('failed to fetch') > -1)
					 {
						 if (doRetry)
						 {
							 this.retry(url, body, method).then((r: any) => resolve(r))
						 }
						 else
						 {
							 reject(response)
						 }
					 }
					 else
					 {
						 reject(response.message);
					 }
				 });
		});
	},



	getDistributionPoints: async function ()
	{
		if (cachePoints.length == 0)
			cachePoints = await ServerAPI.get<DistributionPoint[]>('/fantasy/getDistributionPoints');
		return cachePoints;
	},

	getTournaments(): Promise<Tournament[]>
	{
		return new Promise((resolve) =>
		{
			if (!tournaments)
				ServerAPI.get<Tournament[]>(`/tournaments`).then((res: Tournament[]) =>
				{
					tournaments = res;
					resolve(res);
				});
			else
				resolve(tournaments);
		});
	}

}


let cachePoints: DistributionPoint[] = [];
let tournaments: Tournament[];

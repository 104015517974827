import React from 'react';
import {StyleSheet, TextInput, StyleProp, View, Text} from 'react-native';


interface IProps {
	label: string,
	value: string,
	onChangeText?: Function,
	style?: StyleProp<any>
}


export default class InputWithLabel extends React.Component<IProps, any> {

	constructor(props: IProps)
	{
		super(props);
	}


	render()
	{
		return <View style={[styles.root, this.props.style]}>
			<Text style={styles.text}>{this.props.label}</Text>
			<TextInput style={styles.input}
						  value={this.props.value}
						  onChangeText={(e) => this.props.onChangeText && this.props.onChangeText(e)}/>
			<View style={styles.divider}/>
		</View>
	}
}


const styles = StyleSheet.create({
	root: {
		height: 51
	},
	input: {
		marginTop: 8,
		color: '#000000',
		fontSize: 12,
		fontWeight: '500',
		width: '100%',
		outline: 'none',
		padding: 0,
		height: 16
	} as any,
	text: {
		fontSize: 12,
		fontWeight: '500',
		color: '#797B87'
	},
	divider: {
		marginTop: 8,
		height: 1,
		width: '100%',
		backgroundColor: '#CACDDE'
	}
});

import {Dimensions, RefreshControl, ScrollView, StyleSheet, Text, View} from 'react-native';
import React, {useContext, useEffect, useState} from 'react';
import {ServerAPI} from '../ServerAPI';
import {getHeightExpression, UserContext} from '../func';
import TRANSLATES from '../translates/translates';
import CoinPackage from './CoinPackage';
import BottomModal from "../controls/BottomModal";
import SignForm from "../auth/SignForm";

import {CardShop} from "./CardShop";
import {showReclama, hideBanner, purchase} from "../ads";
import {CoinPrice, EMPTY_EMAIL} from "../types";
import MessageToolTip from "../controls/MessageToolTip";
import UserAppBarOnShopPage from "./UserAppBarOnShopPage";
import {TopUserPanelHeight} from "../UserAppBar";


interface IProps {
    navigation?: any;
}


export default function ShopPage(props: IProps) {

    const {user, setUser} = useContext(UserContext);

    const [coinPrices, setCoinPrices] = useState<CoinPrice[]>([])
    const [refreshing, setRefreshing] = useState(false)
    const [loginVisible, setLoginVisible] = useState(false)
    const [signText, setSignText] = useState('')
    const [toolTipMsg, setToolTipMsg] = useState('')

    async function refresh() {
        setRefreshing(true)
        let coinPrices = await ServerAPI.get<CoinPrice[]>('/coin-prices');
        setCoinPrices(coinPrices)
        setRefreshing(false)
    }

    useEffect(() => {
        refresh();

        ServerAPI.track('Shop')

        props.navigation.addListener('focus', () => {
            ServerAPI.track('Shop')
            hideBanner();
        });
    }, [])

    async function onCoinPackageClicked(it: CoinPrice) {

        if (!user?.Email || user.Email?.startsWith(EMPTY_EMAIL)) {
            setLoginVisible(true)
            return;
        }

        if (!it.price) {
            if (refreshing)
                return;

            setRefreshing(true)

            let isVideoCoinsAvailable = await ServerAPI.post<boolean>('/fantasy/isVideoCoinsAvailable');

            if (isVideoCoinsAvailable) {

                showReclama().then(async (res: boolean) => {
                    if (res) {
                        ServerAPI.track('Shop_watchads')
                        let balance = await ServerAPI.post<number>('/fantasy/addCoinsAfterReclama');

                        user.Coins = balance

                        setUser(user);
                    } else {
                        ServerAPI.track('Shop_watchads_no_ads')

                        setToolTipMsg(TRANSLATES['NoAds']);
                    }

                    setRefreshing(false)
                })
            } else {
                ServerAPI.track('Shop_watchads_no_more_ads_today')
                setRefreshing(false)
                setToolTipMsg(TRANSLATES['NoMoreAdsToday']);
            }

        } else {
            purchase(it.productId)
        }
    }


    let coinList1 = coinPrices.filter((it, idx) => idx < 3);
    let coinList2 = coinPrices.filter((it, idx) => idx >= 3);


    return <View style={styles.root}>


        <UserAppBarOnShopPage navigation={props.navigation}/>

        <BottomModal visible={loginVisible}
                     title={signText || TRANSLATES['SignIn']}
                     height={Dimensions.get('window').height - 60}
                     onCloseClick={() => setLoginVisible(false)}

        >
            <SignForm
                isSignInVis={true}
                isSignIn={false}
                onTitleChanged={(text: string) => {
                setSignText(text)
            }} doneCallback={() => {
                setLoginVisible(false)
            }}/>
        </BottomModal>

        {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                      onClose={() => setToolTipMsg('')}/> : <></>}

        <ScrollView style={styles.content} refreshControl={<RefreshControl
            refreshing={refreshing}
            onRefresh={() => refresh()}
        />}>

            <CardShop onBuyClickWithoutAuth={() => setLoginVisible(true)}/>

            <Text style={styles.buyEarnText}>{TRANSLATES['BuyEarn']}</Text>
            <View style={styles.coinList}>
                {coinList1.map(it => <CoinPackage key={it.price} coins={it.coins} price={it.price}
                                                  onClick={() => onCoinPackageClicked(it)}/>)}
            </View>

            <View style={[styles.coinList, {marginTop: 16}]}>
                {coinList2.map(it => <CoinPackage key={it.price} coins={it.coins} price={it.price}
                                                  onClick={() => onCoinPackageClicked(it)}/>)}
            </View>

            <View style={{height: 8}}/>
        </ScrollView>
    </View>
}

const styles = StyleSheet.create({
    buyEarnText: {
        marginTop: 24,
        marginBottom: 16,
        fontSize: 15,
        fontWeight: '700',
        color: '#2E2D2D',
    },
    coinList: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },


    root: {height: '100%'},
    content: {
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16,
        height: getHeightExpression(TopUserPanelHeight),
    },
    packageItem: {
        marginTop: 12,
        backgroundColor: '#f9c2ff',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 50,
    },
    textStyle: {
        fontSize: 16,
        marginLeft: 16,
    },
});

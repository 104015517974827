import React, {useEffect, useState} from 'react';
import {Dimensions, Image, PixelRatio, StyleSheet, Text, View} from 'react-native';
import TPressable from "./TPressable";

import {useBottomTabBarHeight} from "@react-navigation/bottom-tabs";

// @ts-ignore
import {showBanner, hideBanner} from "../ads";
import CModal from "./CModal";

interface IProps {
    visible: boolean,
    children: any,
    title?: string,
    onCloseClick: Function,
    height?: number,
    titleVisible?: boolean
    padding?: number,
    backgroundColor?: string
    overlayHeight?: number,
    bannerStoreId?: string,
    showBanner?: boolean
}

/**
 * Этот диалог используется для показа с банерами
 * Он использует другой react-native-modal, в котором можно убрать cover
 */
export default function BottomModalAds(props: IProps) {

    const tabBarHeight = useBottomTabBarHeight()
    const [bannerVis, setBannerVis] = useState(false)

    //console.log(tabBarHeight)

    useEffect(() => {
        // if (props.showBanner)
        //     showBanner('Live-Stats', -PixelRatio.get() * tabBarHeight);
        // else
        setBannerVis(false)

        return () => {
            if (props.showBanner)
                hideBanner()
        }
    }, [])


    return <CModal visible={props.visible}>
        <TPressable name={"closeModalBack"} style={{
            height: Dimensions.get('window').height - (props.height || 550),
            width: '100%',
        }} onPress={() => props.onCloseClick()}/>
        <View style={{
            ...styles.content,
            backgroundColor: props.backgroundColor || '#fff',
            height: props.height || 550
        }}>
            {props.titleVisible !== false &&
                <View style={[styles.title, props.padding === 0 && styles.titlePadding]}>
                    <Text style={styles.titleText}>{props.title}</Text>
                    <TPressable name={"closeModal"} onPress={() => props.onCloseClick()}
                                style={styles.closeBtn}>
                        <Image style={{width: 10, height: 10}} source={require('../icons/close.png')}/>
                    </TPressable>
                </View>
            }
            {props.children}
            {bannerVis && <View style={{height: 40}}></View>}
        </View>
    </CModal>
}


const styles = StyleSheet.create({
    titlePadding: {
        paddingRight: 16,
        marginTop: 16,
        paddingLeft: 16
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeBtn: {
        zIndex: 1,
        right: 0,
        position: 'absolute',
        top: -16,
        alignItems: 'center',
        justifyContent: 'center',
        width: 45,
        height: 45,
        //backgroundColor: 'red'
    },
    titleText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    content: {
        paddingTop: 16,
        borderTopRightRadius: 15,
        borderTopLeftRadius: 15,
        marginTop: 'auto'
    }
});

import {MMAAdvantage, MMABattleResponse} from "./types";
import {View, Text, Dimensions} from "react-native";
import React, {useEffect, useState} from "react";
import {ServerAPI} from "../ServerAPI";
import {MAIN_BAR_COLOR, RED} from "../colors";
import ToolTipButton from "../controls/ToolTipButton";
import Svg from "react-native-svg";
import TRANSLATES from "../translates/translates";


export default function GFight(props: {
    fightId: string
    currentRound: number | null
    totalRoundCount: number
    maxDataRoundCount: number
}) {

    const [dataByRound, setDataByRound] = useState<{[key: number] : MMAAdvantage}>({})


    const loadRoundData = async (round: number) => {

        let data = await ServerAPI.get<MMABattleResponse>(`/liveStat/${props.fightId}?online=true&round=${round}`)
        setDataByRound(prevState => ({
            ...prevState,
            [round]: data.advantage
        }))
    }


    useEffect(() => {
        setDataByRound({})

        for (let r = 1; r <= props.maxDataRoundCount; r++) {
            if (r == props.currentRound)
                break
            loadRoundData(r)
        }

    }, [props.fightId]);

    const renderEqual = () => {
        return <View style={{flexDirection: 'row'}}>
            <Svg width="7" height="14" viewBox="0 0 7 14">
                <path
                    d="M7 0C5.14348 -2.21387e-08 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 1.62302e-07 5.14348 0 7C-1.62302e-07 8.85651 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14L7 7V0Z"
                    fill={RED}/>
            </Svg>
            <Svg width="7" height="14" viewBox="0 0 7 14" style={{left: -1}}>
                <path
                    d="M-4.76837e-07 14C1.85651 14 3.63699 13.2625 4.94975 11.9497C6.2625 10.637 7 8.85652 7 7C7 5.14349 6.2625 3.36301 4.94975 2.05025C3.63699 0.737499 1.85652 3.61477e-07 1.43051e-06 0L0 7L-4.76837e-07 14Z"
                    fill={MAIN_BAR_COLOR}/>
            </Svg>
        </View>
    }

    const renderCircle = (rnd: number, advantage: MMAAdvantage | undefined) => {

        if (!advantage) {
            return <View key={rnd}>
                <View key={rnd} style={{
                    width: 14,
                    height: 14,
                    borderRadius: 10,
                    backgroundColor: '#eee'
                }}>
                </View>
                <Text style={{fontSize: 12, color: '#797B87'}}>R{rnd}</Text>
            </View>
        }

        let red = Math.round(advantage.red || 0);
        let blue = Math.round(advantage.blue || 0);
        return <View key={rnd}>
            {red == blue ? renderEqual() :
                <View  style={{
                    width: 14,
                    height: 14,
                    borderRadius: 10,
                    backgroundColor: red > blue ? RED : MAIN_BAR_COLOR,
                }}>
                </View>
            }
            <Text style={{fontSize: 12, color: '#797B87'}}>R{rnd}</Text>
        </View>
    }

    let redSum = 0;
    let blueSum = 0
    Object.keys(dataByRound).forEach((key) => {
        let value = dataByRound[parseInt(key)]
        if (value.red == value.blue) {
            redSum += 0.5
            blueSum += 0.5
        } else if (value.red > value.blue) {
            redSum += 1
        } else if (value.red < value.blue) {
            blueSum += 1
        }
    })

    if (props.maxDataRoundCount == 0) {
        //console.log('empty', props.maxDataRoundCount, dataByRound.size)
        return <></>
    }

    const getWidth = () => {
        return Dimensions.get('window').width - 32 - 124 * 2
    }

    let cnt = props.totalRoundCount

    if (props.maxDataRoundCount == 4 && cnt == 5)
        cnt = 4

    if (cnt <= 2)
        cnt = 3




    return <View style={{width: getWidth(), height: 83, marginTop: 13}}>

        <View style={{alignItems: 'center', width: '100%', flexDirection: 'row', justifyContent: 'center'}}>
            <Text style={{color: '#2E2D2D', fontWeight: 'bold', fontSize: 15}}>G-Fight</Text>
            <ToolTipButton text={TRANSLATES['G-Fight']} style={{marginLeft: 8}}>
            </ToolTipButton>
        </View>
        <View style={{
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 12
        }}>
            <Text style={{color: '#2E2D2D', fontWeight: 'bold', fontSize: 12}}>{redSum}</Text>
            <Text style={{color: '#2E2D2D', fontWeight: 'bold', fontSize: 12}}>{blueSum}</Text>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 6}}>
            {[...new Array(cnt)].map((it, rnd) => renderCircle(rnd + 1, dataByRound[rnd + 1]))}
        </View>
    </View>
}
import {Pressable, StyleProp, StyleSheet, ViewStyle} from "react-native";
import React from "react";
import HGradientBox from "./HGradientBox";
import GradientBox from "./GradientBox";
import RadialGradientBox from "./RadialGradientBox";
import {COLORS} from "../colors";


export function GradientButton(props: {
    onClick?: () => void,
    height: number,
    width: number,
    style?: StyleProp<ViewStyle>,
    children?: React.ReactNode,
    stop1?: string,
    stop2?: string,
    type: 'vert' | 'hor' | 'radial'

}) {

    let gr = null;
    switch (props.type)
    {
        case 'vert':
            gr = <GradientBox width={props.width}
                              stop1={props.stop1 || "#FFBC60"}
                              stop2={props.stop2 || COLORS.RED}
                              height={props.height}
                              style={{position: 'absolute', left: 0, top: 0}}
            />
            break;
        case 'hor':
            gr =  <HGradientBox width={props.width}
                                stop1={props.stop1 || "#FFBC60"}
                                stop2={props.stop2 || COLORS.RED}
                                height={props.height}
                                style={{position: 'absolute', left: 0, top: 0}}
            />
            break
        default:
            gr =  <RadialGradientBox width={props.width}
                                stop1={props.stop1 || "#FFBC60"}
                                stop2={props.stop2 || COLORS.RED}
                                height={props.height}
                                style={{position: 'absolute', left: 0, top: 0}}
            />
            break
    }

    return <Pressable onPress={props.onClick}
                      style={[styles.root, props.style, {height: props.height, width: props.width}]}>
        {gr}
        {props.children}
    </Pressable>
}

export const styles = StyleSheet.create({
    root: {
        borderRadius: 30,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        gap: 8,
        paddingBottom: 10,
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        overflow: "hidden",
        elevation: 2,
        boxShadow: '0px 2px 8px 0px #5D76D180'
    }  as any
})

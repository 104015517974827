import React from 'react';
import {Dimensions, Platform, StyleSheet, View, Text} from 'react-native';
import {MAIN_BAR_COLOR} from '../colors';
import TRANSLATES from '../translates/translates';
import {OnBoardingStyles} from "../Onboarding";


interface IProps {
	count: number,
	current: number,
	onBoardVis?: boolean
}


export default class StepProgress extends React.Component<IProps, any> {

	constructor(props: any)
	{
		super(props);
	}

	getWidth(): number
	{
		if (Platform.OS === 'web')
		{
			return `calc(${100 / this.props.count}% - 10px)` as any;
		}

		return Dimensions.get('window').width / this.props.count - 18;
	}

	render()
	{
		let arr = Array.from(Array(this.props.count).keys());
		return <View style={styles.root}>
			<Text style={[styles.title, this.props.onBoardVis && OnBoardingStyles.highlight]}>{(TRANSLATES as any)['Step' + this.props.current]}</Text>
			<View style={styles.items}>
				{arr.map(idx =>
					 <React.Fragment key={idx}>
						 <View style={[styles.line, {width: this.getWidth()}, idx <= this.props.current ? styles.current : {}]}/>
						 <View style={[styles.point, idx <= this.props.current ? styles.current : {}]}/>
					 </React.Fragment>
				)}
			</View>
		</View>
	}
}


const styles = StyleSheet.create({
	root: {
		marginLeft: 16,
		marginRight: 16,
		marginBottom: 24,
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column'
	},
	title: {
		fontWeight: '700',
		fontSize: 15,
		color: '#2E2D2D',
		borderWidth: 2,
		borderColor: 'transparent'
	},
	items: {
		marginTop: 23,
		display: 'flex',
		flexDirection: 'row',
		width: '100%'
	},
	current: {
		backgroundColor: MAIN_BAR_COLOR,
	},
	line: {
		height: 3,
		backgroundColor: '#CACDDE',
		borderRadius: 3,
		marginTop: 4,
	},
	point: {
		zIndex: 1,
		width: 10,
		height: 10,
		backgroundColor: '#CACDDE',
		borderRadius: 5,
	},
});

import {
    Dimensions,
    Image,
    ImageBackground,
    ImageSourcePropType,
    Modal,
    Pressable,
    ScrollView, StyleProp,
    StyleSheet,
    Text,
    View, ViewStyle
} from "react-native";
import React, {useContext, useEffect, useState} from "react";
import {COLORS, MAIN_BAR_COLOR} from "../colors";
import FButton from "../controls/FButton";
import {CardStatus, DailyReward, EMPTY_EMAIL, UserCard} from "../types";
import TRANSLATES from "../translates/translates";
import {
    DAY,
    formatWithDecimals,
    getBaseColor,
    getDayOfWeek1_7,
    getDiffStr,
    getRatingInterval,
    UserContext
} from "../func";
import Card, {styles as athlete_card_styles} from '../UserCardPage/Card'
import {ServerAPI} from "../ServerAPI";
import GradientBox from "../controls/GradientBox";
import GiveRewardScreen from "../team/GiveRewardScreen";
import MessageToolTip from "../controls/MessageToolTip";
import BottomModal from "../controls/BottomModal";
import SignForm from "../auth/SignForm";


interface IProps {
    onClose: () => void,
    navigation: any
}

enum DayCardType {
    additinal = 'additinal',
    coins = 'coins',
    bonus = 'bonus',
    athlete = 'athlete'
}

interface Reward {
    cardType: DayCardType,
    coins?: number | null,
    status: CardStatus,
}

enum RewardStatus {
    get = 'get',
    getted = 'getted',
    todayGetted = 'todayGetted',
    lost = 'lost',
    notYet = 'notYet',
    notSub = 'notSub'
}

type CardStatusCoefInfo = { [key: string]: number }

function getCoinImage(coins: number) {

    if (coins == 500) {
        return <Image style={{width: 22, height: 39}} source={require('../icons/500.png')}/>;
    }

    if (coins == 2000) {
        return <Image style={{width: 43, height: 60}} source={require('../icons/2000.png')}/>;
    }

    if (coins == 1000) {
        return <Image style={{width: 43, height: 39}} source={require('../icons/1000.png')}/>;
    }

    if (coins == 5000) {
        return <Image style={{width: 46, height: 51}} source={require('../shop/images/bag1.png')}/>;
    }

    if (coins == 10000) {
        return <Image style={{width: 70, height: 51}} source={require('../shop/images/bag2.png')}/>;
    }
}

export function DailyCard(props: {
    sourceIcon?: ImageSourcePropType,
    reward: Reward,
    onClick?: () => void,
    cardCoef: CardStatusCoefInfo,
    statusImage?: React.ReactNode,
    style?: StyleProp<ViewStyle>
}) {

    let content = null

    if (props.reward.cardType == DayCardType.additinal || props.reward.cardType == DayCardType.bonus) {
        let text = props.reward.cardType == DayCardType.bonus ? 'reward.bonus-points' : 'Additional2';

        content = <>
            <Image source={require('../assets/black_gradient.png')}
                   style={[cardStyles.img, {backgroundColor: getBaseColor(props.reward.status)}]}/>
            <Image source={require('../assets/card_background.png')} style={cardStyles.img}/>
            <Text style={cardStyles.text}>{TRANSLATES[text]}</Text>
        </>
    } else if (props.reward.cardType == DayCardType.coins) {
        return <Pressable style={[cardStyles.coinCard]}
                          onPress={() => props.onClick && props.onClick()}>
            {props.statusImage}
            {getCoinImage(props.reward.coins as number)}
            <Text
                style={cardStyles.coinText}>{formatWithDecimals(props.reward.coins as number)} {TRANSLATES['coins']}</Text>
            {props.sourceIcon && <Image source={props.sourceIcon} style={cardStyles.icon}/>}

        </Pressable>
    } else {
        let ratingText = getRatingInterval(props.reward.status)

        content = <>
            <Image source={require('../assets/black_gradient.png')}
                   style={[cardStyles.img, {backgroundColor: getBaseColor(props.reward.status)}]}/>
            <Image source={require('../assets/athlete_shadow.png')} style={cardStyles.img}/>
            <Image source={require('../assets/card_background.png')} style={cardStyles.img}/>
            <Text style={cardStyles.text}>
                {TRANSLATES['fighter-card']}
            </Text>
            {props.sourceIcon && <Image source={props.sourceIcon} style={cardStyles.icon}/>}
            <View style={[athlete_card_styles.ratingView, {width: 'auto', paddingLeft: 4, paddingRight: 4}]}>
                <Image source={require('../assets/rating.png')} style={athlete_card_styles.ratingImg}/>
                <Text style={athlete_card_styles.ratingText}>{ratingText}</Text>
            </View>
        </>
    }

    return <Pressable style={[cardStyles.root, props.style]}
                      onPress={() => props.onClick && props.onClick()}>
        <View style={cardStyles.content}>
            {content}
        </View>
        {props.sourceIcon && <Image source={props.sourceIcon} style={cardStyles.icon}/>}

        <Text style={athlete_card_styles.coefText}>
            {props.cardCoef[props.reward.status] || ''}x
        </Text>
        {props.statusImage}
    </Pressable>
}

const cardStyles = StyleSheet.create({
    icon: {
        width: 32, height: 32, position: 'absolute', left: -12, top: -12
    },
    coinCard: {
        backgroundColor: '#fff',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: 16,
        gap: 8,
        width: 100,
        height: 140,
    },
    coinText: {
        fontWeight: '700',
        marginBottom: 8,
        fontSize: 12,
        color: '#2E2D2D',
    },
    text: {
        letterSpacing: -1,
        fontFamily: 'DIN Pro',
        color: '#fff',
        textAlign: "center",
        position: 'absolute',
        top: 40,
        fontSize: 14,
        fontWeight: '700',
        textTransform: 'uppercase'
    },
    img: {
        position: 'absolute',
        width: '100%',
        height: '105%',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    root: {
        width: 100,
        height: 140,
    },
    content: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        display: 'flex',
        width: 100,
        height: 140,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#F6D191',
        borderStyle: 'solid',
    }
});


interface RewardInfo {
    sub: DailyReward,
    free: DailyReward
}

export default function DailyRewardModal(props: IProps) {

    const {user, setUser} = useContext(UserContext);

    const [rewards, setRewards] = useState<Reward[][]>([])
    const [givedRewards, setGivedRewards] = useState<RewardInfo[]>([])
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    const [viewCard, setViewCard] = useState<UserCard | null | Reward>(null)
    const [cardCoef, setCardCoef] = useState<CardStatusCoefInfo>({})
    const [toolTipMsg, setToolTipMsg] = useState('')
    const [signText, setSignText] = useState<string>('');

    let days = 7 - getDayOfWeek1_7()
    let hours = 24 - new Date().getHours()
    let timeToEndOfWeek =  TRANSLATES[days == 0 ? 'ends_in_hour' : 'ends_in']
        .replace('{0}', days + '')
        .replace('{1}', hours+ '')

    const refresh = async () => {
        let res = await ServerAPI.get<CardStatusCoefInfo>(`/card-coef`)
        setCardCoef(res)

        let rew = await ServerAPI.get<Reward[][]>(`/fantasy/week-rewards`)
        setRewards(rew)

        let res2 = await ServerAPI.get<RewardInfo[]>(`/fantasy/get-daily-cards-current-week`)
        setGivedRewards(res2)
    }

    useEffect(() => {
        refresh()
    }, []);

    let todayGetRewardsIndex = givedRewards.findIndex(it => it.sub?.isToday || it.free?.isToday)
    let currentIndexDay = todayGetRewardsIndex == -1 ? givedRewards.length + 1 : todayGetRewardsIndex + 1

    const getDayImage = (day: number) => {

        let source;

        if (day == currentIndexDay) {
            source = require('../icons/current-day.png')
        } else if (day > currentIndexDay)
            source = require('../icons/future-day.png')
        else
            source = require('../icons/past-day.png')
        return <View>
            <Image source={source} style={{width: 24, height: 24}}/>
            <Text style={{
                position: 'absolute', color: '#fff', fontSize: 14,
                left: 7.5,
                top: 1,
                fontWeight: '700'
            }}>{day}</Text>
        </View>
    }

    const getStatusReward = (day: number, isSub: boolean): RewardStatus => {


        let alredyHasTodayCard = givedRewards.find(it =>
            isSub ? it.sub?.givenDay == day : it.free?.givenDay == day
        ) != null

        if (day == currentIndexDay) {
            if (alredyHasTodayCard)
                return RewardStatus.todayGetted
            else {
                if (isSub) {
                    if (user?.HasSubscription)
                        return RewardStatus.get
                    else
                        return RewardStatus.notSub
                } else
                    return RewardStatus.get
            }

        } else if (day < currentIndexDay) {

            if (alredyHasTodayCard)
                return RewardStatus.getted
            else {
                if (isSub && user?.HasSubscription)
                    return RewardStatus.lost
                else
                    return RewardStatus.notYet
            }
        } else {
            let dayOfWeek = getDayOfWeek1_7()

            let givedCardsPrevDays = givedRewards
                .map(it => isSub ? it.sub : it.free)
                .filter(it => it && it.givenDay != currentIndexDay)

            //сколько должен был получить карточек
            let shouldGetCardsCount = dayOfWeek - 1;
            //сколько упущенных карточек
            let lostCardsCount = shouldGetCardsCount - givedCardsPrevDays.length
            if (lostCardsCount > 7 - day)
                return RewardStatus.lost
            else
                return RewardStatus.notYet
        }

        return RewardStatus.notYet
    }

    const getStatusImage = (day: number, isSub: boolean) => {
        let source = null;

        let status = getStatusReward(day, isSub)

        switch (status) {
            case RewardStatus.todayGetted:
                source = require('../icons/reward/today-getted-reward.png')
                break;
            case RewardStatus.getted:
                source = require('../icons/reward/past-get-reward.png')
                break;
            case RewardStatus.get:
                source = require('../icons/reward/get-reward.png')
                break;
            case RewardStatus.lost:
                source = require('../icons/reward/lost-reward.png')
                break;
            case RewardStatus.notYet:
                source = require('../icons/reward/lock-reward.png')
                break;
            case RewardStatus.notSub:
                source = require('../icons/reward/lock-reward.png')
                break;
        }

        return <View style={{position: 'absolute', left: -8, top: -8}}>
            <Image source={source} style={{width: 24, height: 24}}/>
        </View>
    }

    const closeReward = () => {
        setViewCard(null)
        refresh()
    }

    const renderLogin = () => {
        return loginVisible && <BottomModal visible={true}
                         title={signText || TRANSLATES['SignUp']}
                         height={Dimensions.get('window').height - 60}
                         onCloseClick={() => {
                             setLoginVisible(false)
                             setSignText('')
                         }}

            >
                <SignForm isSignIn={false}
                          currentEmail={user.Email}
                          onTitleChanged={(text: string) => setSignText(text)}
                          doneCallback={() => {
                              refresh();
                              setLoginVisible(false)
                          }}/>
            </BottomModal>

    }

    const onRewardClick = async (clickedDay: number, isSub: boolean) => {

        if (isSub)
            ServerAPI.track(clickedDay + `day_sub_click`)
        else
            ServerAPI.track(clickedDay + `day_click`)

        if (!user?.Email || user.Email?.startsWith(EMPTY_EMAIL)) {
            setLoginVisible(true)
            return;
        }

        if (isSub && !user.HasSubscription) {
            props.onClose()
            props.navigation.navigate('Profile', {
                subscription: new Date().getTime(),
                goBack: true
            })
            return;
        }

        let givedcard = givedRewards.find(it =>
            isSub ? it.sub?.givenDay == clickedDay : it.free?.givenDay == clickedDay
        )

        if (givedcard) {
            setToolTipMsg(TRANSLATES['reward.alredy-got'])
            return
        }

        if (currentIndexDay != clickedDay) {

            if (getStatusReward(clickedDay, isSub) == RewardStatus.lost) {
                setToolTipMsg(TRANSLATES['reward.lost'])
                return
            }

            if (currentIndexDay < clickedDay) {
                let now = new Date()
                setToolTipMsg(TRANSLATES['reward.not-yet'] +
                    getDiffStr((clickedDay - currentIndexDay) * DAY - now.getHours() * 60 * 60 * 1000))
            }
            return
        }

        let cw = await ServerAPI.post<UserCard | Reward>(`/fantasy/give-daily-cards?sub=${isSub}`)

        if (!cw)
            return

        setViewCard(cw)
        if (isSub)
            ServerAPI.track(clickedDay + `day_sub_get`)
        else
            ServerAPI.track(clickedDay + `day_get`)

        let c = (cw as Reward).coins
        if (c) {
            if (user.Coins)
                user.Coins += c;
            else
                user.Coins = c;
            setUser(user)
        }
    }


    return (
        <Modal
            animationType={"slide"}
            transparent={false}
            visible={true}
            style={{backgroundColor: MAIN_BAR_COLOR, position: 'relative'}}
        >

            {renderLogin()}

            {viewCard ? <GiveRewardScreen closeReward={closeReward}>
                    {(viewCard as any).cardType == DayCardType.coins ?
                        <DailyCard sourceIcon={require(`../icons/status-reward.png`)}
                                   cardCoef={cardCoef}
                                   reward={viewCard as Reward} onClick={closeReward}/>
                        :
                        <Card card={viewCard as UserCard} onSelected={closeReward}/>
                    }
                </GiveRewardScreen>
                :
                <>

                    {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                                   top={100}
                                                   onClose={() => setToolTipMsg('')}/> : <></>}

                    <ScrollView style={styles.root}>

                        <GradientBox width={Dimensions.get('window').width} height={300}
                                     style={{position: 'absolute', left: 0, top: 0}}
                                     stop1={"#7593FF"}
                                     stop2={"#FFBC60"}
                        />

                        <View style={styles.header1}>
                            <Text style={styles.mainTextStyle}>
                                {TRANSLATES['daily_login']}
                            </Text>
                        </View>

                        <View style={{
                            alignItems: 'center',
                            position: 'relative',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <Image source={require(`../assets/golden_coin_stacking.png`)}
                                   resizeMethod={'resize'}
                                   style={styles.image_coins}/>

                            {!user?.HasSubscription &&
                                <FButton text={"Buy stats fight +"}
                                         backgroundColor={COLORS.MAIN_BAR_COLOR}
                                         color={'#fff'}
                                         textStyle={{
                                             fontSize: 12
                                         }}
                                         style={{
                                             position: 'absolute',
                                             height: 35, width: 152,
                                             right: 16, top: 20,
                                             zIndex: 1
                                         }}
                                         onClick={() => {
                                             ServerAPI.track('buy_stats_fight_daily_login')
                                             props.onClose()
                                             props.navigation.navigate('Profile', {
                                                 subscription: new Date().getTime(),
                                                 goBack: true
                                             })
                                         }}/>
                            }


                        </View>

                        <View style={{padding: 16, flexDirection: 'row'}}>
                            <Text style={[styles.mainTextStyle, {width: 189}]}>
                                {TRANSLATES['login_every_day']}
                            </Text>

                            <FButton width={94} margin={0}
                                     style={{marginLeft: 'auto', width: 131}}
                                     upperCase={false}
                                     text={timeToEndOfWeek}
                                     backgroundColor={COLORS.ORANGE_COLOR}
                                     color={'#fff'}/>

                        </View>

                        <View style={{marginTop: 12}}>
                            <View style={styles.roundItem}/>
                            <View style={styles.header2}>
                                <Text style={styles.mainTextStyle}>
                                    {TRANSLATES['Rewards']}
                                </Text>
                            </View>

                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                paddingLeft: 16,
                                paddingRight: 16,
                                flexDirection: 'row',
                                marginTop: 20
                            }}>
                                <FButton width={94} margin={0}
                                         style={styles.button}
                                         text={'Free'}
                                         backgroundColor={"#5D76D1"}
                                         color={'#fff'}/>


                                <FButton width={94} margin={0}
                                         style={styles.button}
                                         text={"Stats fight+"}
                                         backgroundColor={COLORS.YELLOW}
                                         color={'#fff'}/>
                            </View>

                            <View style={{padding: 16, alignItems: 'center'}}>
                                {rewards.map((arr, dayWeek) =>
                                    <View key={dayWeek} style={[{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: 24,
                                        height: 202,
                                        width: 342,
                                        position: 'relative'
                                    }, dayWeek + 1 == currentIndexDay ? styles.currentDay : null]}>
                                        {dayWeek + 1 == currentIndexDay && <View style={styles.dayBack}>
                                            <ImageBackground
                                                source={require(`../icons/current-day-back.png`)}
                                                resizeMode="cover"
                                                style={styles.currentDayCenterBack}/>
                                        </View>
                                        }
                                        <DailyCard cardCoef={cardCoef}
                                                   statusImage={getStatusImage(dayWeek + 1, false)}
                                                   reward={arr[0]}
                                                   onClick={() => onRewardClick(dayWeek + 1, false)}/>
                                        {getDayImage(dayWeek + 1)}

                                        <DailyCard cardCoef={cardCoef} reward={arr[1]}
                                                   statusImage={getStatusImage(dayWeek + 1, true)}
                                                   onClick={() => onRewardClick(dayWeek + 1, true)}/>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View style={{height: 56}}></View>
                    </ScrollView>

                    <Pressable style={styles.footer} onPress={() => props.onClose()}>
                        <Image source={require('../icons/arrow-left-2.png')} style={{width: 24, height: 24}}/>
                        <Text style={styles.backText}>{TRANSLATES['Back']}</Text>
                    </Pressable>

                </>}

        </Modal>)
}

const styles = StyleSheet.create({
    button: {
        width: 148
    },
    root: {
        position: 'relative',
        backgroundColor: MAIN_BAR_COLOR,
        height: Dimensions.get('window').height
    },
    image_coins: {
        height: 167,
        width: 253
    },
    header1: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 44
    },
    header2: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    ellipse: {
        position: "absolute",
        width: '100%',
        height: 300,
        top: 0
    },
    mainTextStyle: {
        fontSize: 15,
        color: '#fff',
        fontWeight: '700'
    },
    roundItem: {
        left: 0,
        width: '100%',
        backgroundColor: MAIN_BAR_COLOR,
        borderRadius: 60,
        top: -20,
        position: 'absolute',
        height: 60
    },
    footer: {
        height: 56,
        width: '100%',
        backgroundColor: '#131C3A',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    backText: {
        color: '#fff',
        fontSize: 15,
        fontWeight: '700'
    },
    currentDay: {},
    dayBack: {
        left: 0,
        top: 0,
        position: 'absolute'
    },
    currentDayCenterBack: {
        position: "relative",
        height: 202,
        width: 342
    }
})

import {Pressable, StyleProp, Text, ViewStyle} from "react-native";
import React from "react";
import {styles} from "./OrangeButtonWithPlusIcon";


export function OrangeButton(props: {
    onClick?: () => void,
    text: string,
    children?: React.ReactNode,
    style?: StyleProp<ViewStyle>
}) {
    return <Pressable onPress={props.onClick} style={[styles.titleRow, {elevation: 2}, props.style]}>
        <Text style={styles.text}>{props.text}</Text>
        {props.children}
    </Pressable>
}

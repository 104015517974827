import {CoinPrice, ValidatePurchaseAndBuy} from "./types";

export async function updateIAP(coinPrices: CoinPrice[], validatePurchaseAndBuy: ValidatePurchaseAndBuy) {


}

export async function purchase(sku: string) {

}


export async function initAds() {

}

let isBannerLoaded = false;

export function loadBanner(placementName: string, vertOffset: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
        isBannerLoaded = true
        resolve(null)
        return;

    });
}

export async function showReclama(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        resolve(true)
    });
}


export async function updateFCMToken(setUser: Function) {

}

export function initFirebase(openFantasy: (fantasyIf: number) => void) {

}


export function updateAppsFlyer() {

}


export function hideBanner() {

}


export async function showBanner(placementName: string, offset: number) {

}

export function trackToCloud(eventName: string, eventData: string = "") {

}


export async function subscribe(sku: string) {

}

export async function cancelSubscription(sku: string) {

}

export async function getSubById(sku: string): Promise<{
    price: string,
    currency: string;
    localizedPrice: string
} | null> {
    return null
}
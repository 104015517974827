import React from 'react';

import FantasyEventList from './FantasyEventList';
import {createStackNavigator} from '@react-navigation/stack';
import BattleList from '../team/BattleList';
import {MAIN_BAR_COLOR} from '../colors';
import TRANSLATES from '../translates/translates';

const Stack = createStackNavigator();


export default function FantasyNavigation(props: {navigation: any})
{

	//https://reactnavigation.org/docs/web-support/
	return <Stack.Navigator screenOptions={{headerShown: false}}>

		<Stack.Screen name={'fantasyList'} component={FantasyEventList}/>



		<Stack.Screen name={'battleList'} component={BattleList}
						  options={{
							  headerTitleStyle: {
								  color: 'white',
							  },
							  headerTitleAlign: 'center',
							  headerStyle: {
								  backgroundColor: MAIN_BAR_COLOR,
							  },
							  headerTintColor: 'white',
							  headerShadowVisible: false,
							  headerShown: true,
							  headerTitle: TRANSLATES['FeatureEvents'],
						  }}/>

	</Stack.Navigator>;
}

import {Text, View} from "react-native";
import {COLORS} from "../../colors";
import {secondsToTime} from "../../func";
import React from "react";


const renderLinePercent = (controlPercent: number | undefined, color1: string, color2: string) => {
    return <View
        style={{
            height: 4,
            width: '100%',
            flexDirection: 'row',
            borderRadius: 10,
            overflow: 'hidden',
            marginTop: 4
        }}>
        <View style={{height: 4, width: (controlPercent || 0) + '%', backgroundColor: color1}  as any}/>
        <View style={{
            height: 4,
            width: (100 - (controlPercent || 0)) + '%',
            backgroundColor: color2,
            opacity: 0.5
        }  as any}/>
    </View>
}

export interface IProps
{
    color1: string,
    color2: string,
    perc: number,
    sec: number,
    headerText: string,
    headerStyle: any
    style: any
}
export default function TimeAndPercChart(props: IProps)
{
    return <View style={props.style}>
        <Text style={props.headerStyle}>{props.headerText}</Text>

        {renderLinePercent(props.perc, props.color1, props.color2)}
        <View style={{justifyContent: 'space-between', flexDirection: 'row', width: '100%', marginTop: 8}}>
            <Text style={{color: COLORS.LIGHT_BLACK}}>
                {secondsToTime(props.sec)}
            </Text>
            <Text style={{color: COLORS.LIGHT_BLACK}}>
                {props.perc}%
            </Text>
        </View>

    </View>
}

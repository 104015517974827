import {Image, ImageBackground, Pressable, StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {CardStatus, CardType, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import {getCountryFlagUrl} from "../func";
import {COLORS, MAIN_BAR_COLOR, ORANGE_COLOR} from "../colors";


interface IProps {
	card: UserCard,
	style?: any,
	onSelected?: Function,
	selected?: boolean,
	count?: number
}

interface IState {

}

export default class Card extends React.Component<IProps, IState> {


	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}


	getBaseColor()
	{
		if (this.props.selected)
			return MAIN_BAR_COLOR

		if (this.props.card.status == CardStatus.gold)
		{
			return '#F1CB8F';
		}

		if (this.props.card.status == CardStatus.silver)
		{
			return '#CACDDE';
		}

		return '#B97344'; 		//bronze
	}

	getAthleteImage()
	{
		return <Image source={this.props.card.athletePhotoUrl ? {uri: this.props.card.athletePhotoUrl} :
			 require('../assets/athlete_shadow.png')} style={styles.athleteImg}/>
	}

	renderAthleteContent()
	{
		let card = this.props.card;
		let nameArr = card.exDescData.split(' ');
		let flagUrl = card.countryISO && getCountryFlagUrl(card.countryISO)
		let promoUrl = card.promotionUrl;
		return <>
			{this.getAthleteImage()}
			{card.athleteRating > 0 && <View style={styles.ratingView}>
				<Image source={require('../assets/rating.png')} style={styles.ratingImg}/>
				<Text style={styles.ratingText}>{card.athleteRating}</Text>
			</View>
			}
			{promoUrl && <ImageBackground source={{uri: promoUrl}} resizeMode="contain" style={styles.athletePromoUrl}/>}
			<View style={{position: 'absolute', bottom: 26, left: 0, alignItems: 'center', width: '100%'}}>
				<Text style={styles.athleteCardFirstName} numberOfLines={1}>{nameArr[0] || ''}</Text>
				<Text style={styles.athleteCardLastName} numberOfLines={1}>{nameArr[1] || ''}</Text>
				<View style={styles.smallCountry}>
					{flagUrl && <Image source={{uri: flagUrl}} style={styles.flagImg}/>}
				</View>
			</View>
		</>
	}

	renderSimpleCard()
	{
		let card = this.props.card;
		return <>
			<Text style={{
				...styles.mainName,
				marginTop: 40
			}}>{(TRANSLATES as any)[card.exDescData] || card.exDescData}</Text>
			<Text style={styles.cardDesc}>{(TRANSLATES as any)[card.name] || card.name}</Text>
		</>
	}

	renderCountryContent()
	{
		let card = this.props.card;

		let flagUrl = card.countryISO && getCountryFlagUrl(card.countryISO)

		return <View style={{alignItems: 'center', height: '100%'}}>
			{flagUrl && <Image source={{uri: flagUrl}} style={{width: 36, height: 36, marginTop: 40}}/>}
			<Text style={{...styles.mainName, marginTop: 'auto', marginBottom: 26}}>{card.countryName}</Text>
		</View>
	}

	renderPromotionContent()
	{
		let card = this.props.card;
		return <View style={{alignItems: 'center', height: '100%'}}>
			<Text style={{...styles.mainName, marginTop: 40}}>{card.exDescData}</Text>
		</View>
	}

	renderCardContent()
	{
		if (this.props.card.name == CardType.Athlete)
			return this.renderAthleteContent()


		if (this.props.card.name == CardType.Country)
			return this.renderCountryContent()

		if (this.props.card.name == CardType.Promotion)
			return this.renderPromotionContent()

		return this.renderSimpleCard();
	}

	renderCount()
	{
		return <View style={styles.count}><Text style={{
			fontWeight: '700',
			color: '#FFFFFF',
			fontSize: 10
		}}>{this.props.count}</Text></View>
	}

	render()
	{
		let card = this.props.card;

		return (
			 <View style={[{position: 'relative'}, this.props.style]}>

				 <Pressable style={styles.content}
								onPress={() => this.props.onSelected && this.props.onSelected()}>
					 <Image source={require('../assets/black_gradient.png')}
							  style={[styles.img, {backgroundColor: this.getBaseColor()}]}/>


					 {this.renderCardContent()}

					 {!this.props.selected && <Image source={require('../assets/card_background.png')} style={styles.img}/>}

					 {this.props.selected &&
					<View style={styles.checkBox}>
						<Image style={{width: 14, height: 9}} source={require('../assets/galka.png')}/>
					</View>
					 }
					 <Text style={styles.coefText}>
						 {card.statusCoef}x
					 </Text>


				 </Pressable>
				 {this.props.count && this.props.count > 0 && this.renderCount()}
			 </View>
		);
	}
}


export const styles = StyleSheet.create({
	ratingView: {
		position: 'absolute',
		left: 4,
		top: 4,
		borderRadius: 20,
		width: 36,
		height: 14,
		backgroundColor: 'rgba(64, 92, 191, 1)',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexDirection: 'row'
	},
	ratingImg: {
		width: 12,
		height: 12,
	},
	ratingText: {
		color: '#fff',
		fontWeight: '700',
		fontSize: 10
	},
	count: {
		//zIndex: 4,
		width: 16,
		height: 16,
		borderRadius: 12,
		backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
		justifyContent: "center",
		alignItems: 'center',
		position: "absolute",
		left: -3,
		top: -3,
		elevation: 3
	},
	checkBox: {
		width: 24,
		height: 24,
		position: 'absolute',
		backgroundColor: '#ffffff',
		left: 8,
		top: 8,
		borderRadius: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 3
	},
	mainName: {
		letterSpacing: -1,
		color: '#FFFFFF',
		fontWeight: '900',
		fontSize: 14,
		zIndex: 1,
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	cardDesc: {
		marginTop: 'auto',
		marginBottom: 23,
		letterSpacing: -0.5,
		fontSize: 10,
		fontWeight: '500',
		color: 'white',
		textAlign: 'center',
		zIndex: 1,
		textTransform: 'uppercase',
		fontFamily: 'DIN Pro',
	},
	athleteCardFirstName: {
		color: 'white',
		textTransform: 'uppercase',
		zIndex: 1,
		fontSize: 10,
		fontWeight: '500'
	},
	athleteCardLastName: {
		textTransform: 'uppercase',
		fontSize: 14,
		fontWeight: '900',
		zIndex: 1,
		color: 'white'
	},
	img: {
		position: 'absolute',
		width: 100,
		height: 140,
		zIndex: -1
	},
	athletePromoUrl: {
		position: 'absolute',
		right: 7,
		top: 6,
		width: 26,
		height: 10,
	},
	athleteImg: {
		top: 0,
		position: 'absolute',
		width: 100,
		height: 140,
	},
	background: {
		position: 'absolute',
		width: 100,
		height: 140,
	},
	content: {
		position: 'relative',
		alignItems: 'center',
		overflow: 'hidden',
		display: 'flex',
		width: 100,
		height: 140,
		borderRadius: 10,
		borderWidth: 2,
		borderColor: '#F6D191',
		borderStyle: 'solid',
	},
	coefText: {
		position: 'absolute',
		bottom: 7,
		left: 11,
		fontSize: 10,
		borderRadius: 20,
		paddingLeft: 4,
		paddingRight: 4,
		backgroundColor: ORANGE_COLOR,
		color: '#FFFFFF',
		fontWeight: '700',
	},
	flagImg: {
		width: 16,
		height: 12,
		bottom: 3
	},
	smallCountry: {
		position: 'absolute',
		top: 40,
		right: 10,
		display: 'flex',
		flexDirection: 'row'
	}
});

import {Image, StyleSheet, Text, View} from "react-native";
import React from "react";

export default function RatingBox(props: { text: string, style?: any }) {
    return <View style={[styles.ratingView, props.style]}>
        <Image source={require('../assets/rating.png')} style={styles.ratingImg}/>
        <Text style={styles.ratingText}>{props.text}</Text>
    </View>
}


export const styles = StyleSheet.create({
    ratingView: {
        paddingLeft: 4,
        paddingRight: 6,
        borderRadius: 20,
        height: 14,
        backgroundColor: 'rgba(64, 92, 191, 1)',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row'
    },
    ratingImg: {
        width: 12,
        height: 12,
    },
    ratingText: {
        whiteSpace: 'nowrap',
        color: '#fff',
        fontWeight: '700',
        fontSize: 10
    } as any
})
import {Image, Pressable, StyleSheet, Text, View} from "react-native";

import React from "react";


export default function DeleteButton(props: {text: string, onClick: Function})
{
	return <Pressable style={styles.root} onPress={() => props.onClick()}>
		<Text style={styles.text}>{props.text}</Text>
		<Image style={{width: 12, height: 7}} source={require('../assets/remove.png')}/>
	</Pressable>
}

const styles = StyleSheet.create({
	root: {
		height: 24,
		flexDirection: 'row',
		borderColor: 'rgba(64, 92, 191, 1)',
		borderWidth: 1,
		borderStyle: 'solid',
		alignItems: 'center',
		justifyContent: 'space-around',
		minWidth: 77,
		borderRadius: 20,
		paddingLeft: 10,
		paddingRight: 10
	},
	text: {
		marginRight: 8,
		color: 'rgba(64, 92, 191, 1)',
		fontWeight: '700',
	}
})

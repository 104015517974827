import React, {useEffect, useState} from 'react';
import {StyleSheet, View, ScrollView, Text} from 'react-native';
import {Fight, Fighter} from "../../types";
import {getHeightExpression} from "../../func";
import GFightBattle from "./../GFightBattle";
import {ServerAPI} from "../../ServerAPI";
import {CompareStat, DefenceAthleteStat, FighterStat} from "./compare-types";
import TPressable from "../../controls/TPressable";
import TRANSLATES from "../../translates/translates";
import {styles as tabStyles} from "../../profile/Tabs";
import PunchesChart from "./PunchesChart";
import {COLORS} from "../../colors";
import TypePunchesChart from "./TypePunchesChart";
import PunchesZone from "./PunchesZone";
import AttemptsTT from "./AttemptsTT";
import SubAttemptZone from "./SubAttemptZone";
import TimeAndPercChart from "./TimeAndPercChart";

interface IProps {
    fight: Fight
}

export default function ComparePreStatTable(props: IProps) {

    const padding = 12;
    const [type, setType] = useState<'general' | 'distance' | 'clinch' | 'parter'>('general')

    const [stat, setStat] = useState<CompareStat>()
    const [generalStat, setGeneralStat] = useState<CompareStat>()


    const [selectedFighterStat, setSelectedFighterStat] = useState<FighterStat>()
    const [defenceAthleteStat, setDefenceAthleteStat] = useState<DefenceAthleteStat>()

    const [isAttack, setIsAttack] = useState(true)

    const getCurrentTabColor = () => {
        return selectedFighterStat?.id == stat?.red.id ? COLORS.RED : COLORS.DARK_BLUE
    }

    const getCurrentColor = () => {
        return selectedFighterStat?.id == stat?.red.id ? COLORS.RED : COLORS.BLUE
    }

    const getSecondCurrentColor = () => {
        return selectedFighterStat?.id == stat?.red.id ? COLORS.DARK_RED : COLORS.DARK_BLUE
    }

    const loadData = async () => {
        let red = props.fight.red;
        let blue = props.fight.blue;

        let typeParam = type == 'general' ? '' : `&type=${type}`;

        if (type != 'general' || generalStat == undefined) {
            let res = await ServerAPI.callAPI<CompareStat>(`/fantasy/mma-compare?red=${red.id}&blue=${blue.id}&lang=en${typeParam}`);
            setStat(res)
            setSelectedFighterStat(res.red)
            setDefenceAthleteStat(res.defenceStat.red)

            if (type == 'general')
                setGeneralStat(res)
        } else {
            setStat(generalStat)
            setSelectedFighterStat(generalStat.red)
            setDefenceAthleteStat(generalStat.defenceStat.red)
        }
    }

    useEffect(() => {
        loadData()
    }, [type]);

    if (!selectedFighterStat || !stat)
        return <></>

    let totalSeconds = selectedFighterStat ? selectedFighterStat?.positionInfo?.grappleSeconds +
        selectedFighterStat?.positionInfo?.stanceSeconds +
        selectedFighterStat?.positionInfo?.groundSeconds : 0

    const renderDottedDivider = () => {
        return <View style={{borderWidth: 1, height: 0, borderStyle: 'dotted', borderColor: COLORS.GRAY, marginTop: 16}}/>
    }

    const renderControlPositionCharts = () => {

        const textStyle = {
            color: COLORS.LIGHT_BLACK
        }

        return <View style={{alignItems: 'center'}}>
            <Text style={styles.headerText}>{TRANSLATES['Control']}</Text>

            <TimeAndPercChart headerText={TRANSLATES['ClinchControl']}
                              style={{width: 220, marginTop: 12, alignItems: 'center'}}
                              headerStyle={textStyle}
                              sec={selectedFighterStat?.controlPosition.grappleSeconds || 0}
                              color1={getCurrentTabColor()}
                              color2={getCurrentColor()}
                              perc={selectedFighterStat?.controlPosition.grapplePercent}/>

            <TimeAndPercChart headerText={TRANSLATES['GroundControl']}
                              style={{width: 220, marginTop: 12, alignItems: 'center'}}
                              headerStyle={textStyle}
                              sec={selectedFighterStat?.controlPosition.groundSeconds || 0}
                              color1={getCurrentTabColor()}
                              color2={getCurrentColor()}
                              perc={selectedFighterStat?.controlPosition.groundPercent}/>

        </View>
    }


    const renderAttack = () => {
        return <>
            {type == 'general' &&

                <>
                    {renderControlPositionCharts()}

                    {renderDottedDivider()}
                </>
            }

            {selectedFighterStat.punchesStat && <>
                <Text style={styles.headerText}>{TRANSLATES['compare-stat.punches-stat']}</Text>
                <PunchesChart punchesStat={selectedFighterStat!!.punchesStat}
                              landedColor={getSecondCurrentColor()}
                              totalColor={getCurrentColor()}/>
                {renderDottedDivider()}
            </>}


            <Text style={styles.headerText}>{TRANSLATES['compare-stat.types-punches']}</Text>

            <TypePunchesChart punchesStat={selectedFighterStat.punchesStat}
                              color={getCurrentTabColor()}/>

            {renderDottedDivider()}


            <Text style={styles.headerText}>{TRANSLATES['compare-stat.punches-zones']}</Text>
            <PunchesZone landedColor={getSecondCurrentColor()}
                         totalColor={getCurrentColor()}
                         zoneStat={selectedFighterStat.punchesByZoneStat}/>



            {type != 'parter' &&
                <>
                {renderDottedDivider()}


                <Text style={styles.headerText}>{TRANSLATES['compare-stat.ground-stat']}</Text>
                <AttemptsTT landedColor={getSecondCurrentColor()}
                            totalColor={getCurrentColor()}
                            groundStat={selectedFighterStat.groundStat}/>
                </>
            }

            {renderDottedDivider()}

            <Text style={styles.headerText}>{TRANSLATES['compare-stat.zone.submission-at']}</Text>
            <SubAttemptZone landedColor={getSecondCurrentColor()}
                            totalColor={getCurrentColor()}
                            zoneStat={selectedFighterStat.submissionStat}/>
        </>
    }

    const renderDefence = () => {
        if (!defenceAthleteStat)
            return <></>
        return <>
            <Text style={styles.headerText}>{TRANSLATES['compare-stat.defence.avg-by-zone']}</Text>
            <PunchesZone landedColor={getSecondCurrentColor()}
                         totalColor={getCurrentColor()}
                         zoneStat={defenceAthleteStat.punchesByZoneStat!}/>

            {renderDottedDivider()}

            {type != 'parter' &&
                <>
                    <Text style={styles.headerText}>{TRANSLATES['compare-stat.defence.tt']}</Text>
                    <AttemptsTT landedColor={getSecondCurrentColor()}
                                totalColor={getCurrentColor()}
                                groundStat={defenceAthleteStat.groundStat}/>

                    {renderDottedDivider()}
                </>
            }
            <Text style={styles.headerText}>{TRANSLATES['compare-stat.defence.eff-submission']}</Text>
            <SubAttemptZone landedColor={getSecondCurrentColor()}
                            totalColor={getCurrentColor()}
                            zoneStat={defenceAthleteStat.submissionStat}/>
        </>
    }

    const renderTimePosition = (positionType: string) => {

        if (!selectedFighterStat || !generalStat)
            return <></>

        let positionInfo = selectedFighterStat.id == props.fight.red.key
            ? generalStat.red.positionInfo : generalStat.blue.positionInfo


        let perc = 0;
        let sec = 0;

        switch (positionType) {
            case "distance":
                perc = positionInfo.avgStancePercent;
                sec = positionInfo.avgStanceSeconds
                break;
            case "clinch":
                perc = positionInfo.avgGrapplePercent;
                sec = positionInfo.avgGrappleSeconds
                break;
            case "parter":
                perc = positionInfo.avgGroundPercent;
                sec = positionInfo.avgGroundSeconds
                break;
        }

        return <TimeAndPercChart headerText={(TRANSLATES as any)[`compare-stat.avg.${positionType}`]}
                                 style={{padding: 16}}
                                  headerStyle={styles.headerText}
                                  sec={sec}
                                  color1={getCurrentTabColor()}
                                  color2={getCurrentColor()}
                                  perc={perc}/>
    }



    //let grapplePercent=Math.round(100 * selectedFighterStat.positionInfo.grappleSeconds / totalSeconds)
    //let groundPercent= Math.round(100 * selectedFighterStat.positionInfo.groundSeconds / totalSeconds)
    //let stancePercent= 100 - grapplePercent - groundPercent

    return (
        <ScrollView style={styles.root}>
            <View style={{width: '100%', paddingLeft: padding, paddingRight: padding}}>
                <GFightBattle
                    fight={props.fight}
                    selectedFighter={selectedFighterStat?.id == props.fight.red.key ? props.fight.red : props.fight.blue}
                    onSelectFighter={(fighter: Fighter) => {
                        setSelectedFighterStat(fighter.id == props.fight.red.id ? stat?.red : stat?.blue)
                        setDefenceAthleteStat(fighter.id == props.fight.red.id ? stat.defenceStat.red : stat.defenceStat.blue)
                    }}
                    onboardStep={null}
                    red={props.fight.red}
                    blue={props.fight.blue}/>
            </View>

            {renderDottedDivider()}


            <View style={styles.tabs}>
                <TPressable
                    name="general"
                    onPress={() => setType('general')}
                    style={[tabStyles.tab, type == 'general' ? {backgroundColor: getCurrentTabColor()} : null, styles.tab]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.general']}
                    </Text>
                </TPressable>
                <TPressable
                    name="distance"
                    onPress={() => setType('distance')}
                    style={[tabStyles.tab, type == 'distance' ? {backgroundColor: getCurrentTabColor()} : null, styles.tab]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.distance']}
                    </Text>
                </TPressable>
                <TPressable
                    name="clinch"
                    onPress={() => setType('clinch')}
                    style={[tabStyles.tab, type == 'clinch' ? {backgroundColor: getCurrentTabColor()} : null, styles.tab]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.clinch']}
                    </Text>
                </TPressable>
                <TPressable
                    name="parter"
                    onPress={() => setType('parter')}
                    style={[tabStyles.tab, type == 'parter' ? {backgroundColor: getCurrentTabColor()} : null, styles.tab]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.ground']}
                    </Text>
                </TPressable>
            </View>

            {type == 'general' ?
                <>
                    {renderTimePosition('distance')}
                    {renderTimePosition('clinch')}
                    {renderTimePosition('parter')}
                </>
                :
                renderTimePosition(type)
            }



            <View style={styles.tabs}>
                <TPressable
                    name="compare-stat-attack"
                    onPress={() => setIsAttack(true)}
                    style={[tabStyles.tab, styles.tab2, isAttack ? {backgroundColor: getCurrentTabColor()} : null]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.attack']}
                    </Text>
                </TPressable>
                <TPressable
                    name="compare-stat-defence"
                    onPress={() => setIsAttack(false)}
                    style={[tabStyles.tab, styles.tab2, !isAttack ? {backgroundColor: getCurrentTabColor()} : null]}>
                    <Text style={tabStyles.textTab}>
                        {TRANSLATES['compare-stat.defence']}
                    </Text>
                </TPressable>
            </View>

            {isAttack ? renderAttack() : renderDefence()}


            <View style={{height: 80}}/>

        </ScrollView>
    )


}


const styles = StyleSheet.create({
    tab: {width: 86, height: 24},
    tab2: {width: 160},
    tabs: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16
    },
    root: {
        height: getHeightExpression(200)
    },
    lastFights: {
        backgroundColor: COLORS.MAIN_BAR_COLOR,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        width: 83,
        marginTop: 12
    },
    lastFightsText: {
        fontSize: 12,
        color: '#fff',
        fontWeight: '700'
    },
    headerText: {
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 28,
        marginBottom: 12,
        color: COLORS.BLACK
    },
    item: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textItem: {
        width: 150,
        fontSize: 15,
        fontWeight: '700',
        color: '#2E2D2D'
    },

    nickname: {
        color: '#797B87',
        fontSize: 8,
        width: 100
    },
    name: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 12,
    },

    rightTextAlign: {
        textAlign: 'right',
    },

    athleteImage: {
        width: 80,
        height: 80
    },

    ratingView: {
        height: 14,
        flexDirection: 'row',
        backgroundColor: '#FFDAA5',
        borderRadius: 20,
        paddingLeft: 6,
        paddingRight: 6,
        alignItems: 'center',
        position: "absolute"
    },
    ratingImg: {
        width: 12,
        height: 12,
    },
    ratingText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 10
    },
    nick_rating: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4
    }

});

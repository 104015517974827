import {ScrollView, StyleSheet, View, Text, Dimensions} from "react-native";
import React, {useEffect, useState} from "react";
import {ServerAPI} from "../ServerAPI";
import {Achievement, AchievementUser} from "../types";
import AchievementCard from "./AchievementCard";
import TRANSLATES from "../translates/translates";
import AchievementStepper from "./AchievementStepper";
import {hideBanner} from "../ads";

export default function AchievementView(props: { height: number, navigation?: any; }) {

    const [allAchievements, setAllAchievements] = useState<Achievement[]>([])
    const [userAchievements, setUserAchievements] = useState<AchievementUser[]>([])


    const loadData = async () => {
        let data1 = await ServerAPI.get<Achievement[]>("/fantasy/achievements")
        setAllAchievements(data1)


        let data2 = await ServerAPI.get<AchievementUser[]>("/fantasy/my-achievements")
        setUserAchievements(data2)
    }

    useEffect(() => {
        loadData()

        props.navigation.addListener('focus', () => {
            loadData();
        });
    }, [])

    //need to render only 4 card in one row
    let nextAch: Achievement | null = null
    let rows4 = []
    for (let i = 0; i < allAchievements.length; i += 4) {
        rows4.push(allAchievements.slice(i, i + 4))
    }

    let nextIndex = 0
    for (let i = 0; i < allAchievements.length; i++) {
        if (!userAchievements.find(ach => ach.achievement_id == allAchievements[i].id)) {
            nextAch = allAchievements[i]
            nextIndex = i + 1
            break
        }
    }

    //найти номер ачивки в массиве ачивок если до неё есть 3 достигнутые ачивки
    let cntDone = 0
    for (let i = 0; i < allAchievements.length; i++) {
        if (userAchievements.find(ach => ach.achievement_id == allAchievements[i].id)) {
            cntDone++
        } else {
            if (cntDone == 3) {
                nextIndex = i + 1
                break
            }
        }
    }

    let index = 1;


    return (
        <ScrollView style={[styles.root, {height: props.height}]}>


            {nextAch &&
                <View style={styles.nextBlock}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <AchievementCard index={nextIndex}
                                         achievement={nextAch}
                                         disabledToolTip={true}
                                         isDone={true}/>

                        <View style={{marginLeft: 20}}>
                            <Text style={[styles.title]}>{TRANSLATES['Achievements.Next']}</Text>
                            <Text numberOfLines={4} style={{
                                color: '#797B87',
                                width: 160,
                                marginTop: 8
                            }}>{TRANSLATES[`Achievements.Next.${nextAch.type}`]}</Text>
                        </View>
                    </View>

                    <AchievementStepper
                        width={Dimensions.get('window').width - 4 * 16}
                        done={nextIndex - 1}
                        numbers={[nextIndex - 3, nextIndex - 2, nextIndex - 1, nextIndex]}/>

                </View>
            }

            <Text style={[styles.title, {marginTop: 20}]}>{TRANSLATES['Achievements']}</Text>

            <View style={styles.row}>
                <Text style={styles.achName}>{TRANSLATES['Achievements.Coins']}</Text>
                <Text style={styles.achName}>{TRANSLATES['Achievements.Play']}</Text>
                <Text style={styles.achName}>{TRANSLATES['Achievements.Rank']}</Text>
                <Text style={styles.achName}>{TRANSLATES['Achievements.Gym']}</Text>
            </View>


            <View style={styles.item}>
                {rows4.map((row) => {

                    return <View key={index + 1} style={styles.row}>

                        {row.map((ach) => {
                            const isDone = !!userAchievements.find(it => it.achievement_id == ach.id)
                            return <AchievementCard index={index++}
                                                    key={ach.id}
                                                    achievement={ach}
                                                    isDone={isDone}/>
                        })}
                    </View>
                })}
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    title: {
        color: '#2E2D2D',
        fontSize: 15,
        fontWeight: 'bold',
        marginLeft: 4,
    },
    nextBlock: {
        padding: 16,
        marginTop: 8,
        marginLeft: 4,
        marginRight: 4,
        borderRadius: 10,
        elevation: 3,
        backgroundColor: '#EFF1F6',
        boxShadow: '0px 0px 9px 0px rgba(0, 0, 0, 0.12)',
    }  as any,
    root: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 20,
        width: '100%'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 8
    },
    item: {
        flexDirection: 'column',
        display: 'flex'
    },
    achName: {
        width: 76,
        textAlign: 'center',
        color: '#797B87',
        fontSize: 12
    }
})
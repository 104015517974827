import React from 'react';
import {StyleSheet, TextInput, StyleProp} from 'react-native';


interface IProps {
	placeHolder: string,
	value: string,
	onChangeText: Function,
	style?: StyleProp<any>
	isPassword?: boolean,
	onFocus?: Function,
	autofocus?: boolean
}


export default class SInput extends React.Component<IProps, any> {

	constructor(props: IProps)
	{
		super(props);
	}


	render()
	{
		return <TextInput style={[styles.root, this.props.style]}
								value={this.props.value}
						  		autoCorrect={false}
						  		spellCheck={false}
						  		autoFocus={this.props.autofocus}
						  		onFocus={() => this.props.onFocus && this.props.onFocus(true)}
						  		onBlur={() => this.props.onFocus && this.props.onFocus(false)}
								secureTextEntry={this.props.isPassword}
								placeholderTextColor="#A6A9C0"
								placeholder={this.props.placeHolder}
								onChangeText={(e) => this.props.onChangeText(e)}/>
	}
}


const styles = StyleSheet.create({
	root: {
		color: '#000000',
		paddingLeft: 16,
		paddingTop: 9,
		paddingBottom: 9,
		paddingRight: 16,
		height: 35,
		width: 290,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: '#CACDDE',
		borderRadius: 22
	}
});

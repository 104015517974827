import React, {useContext, useEffect} from 'react';
import {StyleSheet, View, Text, ScrollView, Dimensions, Image} from 'react-native';
import {AthleteStat, Fight, Fighter, RecordData} from "../types";
import {ServerAPI} from "../ServerAPI";
import TRANSLATES from "../translates/translates";
import {parse} from "date-fns";
import {getHeightExpression, UserContext} from "../func";
import TPressable from "../controls/TPressable";
import {MAIN_BAR_COLOR} from "../colors";
import GFightBattle from "./GFightBattle";
import {useState} from 'react';
import {GradientButton} from "../controls/GradientButton";
import {CompareOneValue} from "./CompareOneValue";

interface IProps {
    fight: Fight,
    oddBlue: number | undefined
    oddRed: number | undefined
    onLastFightClick: (f: Fighter) => void
    openCompareStatClick: () => void
}

export default function PreStatTable(props: IProps) {

    const {user, fantasyAppFeatures} = useContext(UserContext);

    const [redRecord, setRedRecord] = useState<RecordData[]>([]);
    const [blueRecord, setBlueRecord] = useState<RecordData[]>([]);
    const [redStat, setRedStat] = useState<AthleteStat | null>(null);
    const [blueStat, setBlueStat] = useState<AthleteStat | null>(null);
    //const [viewHistoryFighter, setViewHistoryFighter] = useState<Fighter | null>(null);


    useEffect(() => {
        refresh();
    }, [props.fight]);


    async function refresh() {
        let red = props.fight.red;
        let blue = props.fight.blue


        ServerAPI.get<RecordData[]>(`/getRecordData/${red.id}`).then(data => setRedRecord(data))

        ServerAPI.get<RecordData[]>(`/getRecordData/${blue.id}`).then(data => setBlueRecord(data))

        ServerAPI.get<AthleteStat>(`/getStatAthlete/${blue.id}`).then(data => setBlueStat(data))

        ServerAPI.get<AthleteStat>(`/getStatAthlete/${red.id}`).then(data => setRedStat(data))
    }

    function getAgeString(f: Fighter) {
        if (!f.dateBirth)
            return '';

        let birthDate = parse(f.dateBirth, 'yyyy-MM-dd', new Date());

        let age = new Date().getFullYear() - birthDate.getFullYear();
        let m = new Date().getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && new Date().getDate() < birthDate.getDate())) {
            age--;
        }
        return age + '';
    }

    function getRecordString(data: RecordData[]) {
        if (!data)
            return ''
        let win = 0, draw = 0, loss = 0;

        data.forEach(b => {
            if (b.Result == 'LOSE')
                loss++
            else if (b.Result == 'WIN')
                win++;
            else if (b.Result == 'DRAW')
                draw++;

        })

        return `${win}-${loss}-${draw}`
    }

    function getCnt(data: RecordData[], winType: string[]) {
        if (!data)
            return 0
        return data.filter(it => it.Result == 'WIN' && winType.indexOf(it.WinType) > -1).length
    }


    function renderItem(name: string, leftValue?: string | number, rightValue?: string | number, width = 148) {
        if (!isNaN(leftValue as number))
            leftValue = Math.round(leftValue as number * 100) / 100

        if (!isNaN(rightValue as number))
            rightValue = Math.round(rightValue as number * 100) / 100

        return <View style={styles.item}>
            <Text style={[{textAlign: 'right'}, styles.textItem]}>{leftValue == undefined ? 'N/D' : leftValue}</Text>
            <Text style={{
                fontSize: 12,
                fontWeight: '500',
                color: '#797B87',
                width: width,
                textAlign: 'center'
            }}>{name}</Text>
            <Text style={[{textAlign: 'left'}, styles.textItem]}>{rightValue == undefined ? 'N/D' : rightValue}</Text>
        </View>
    }

    // function renderStatItem(name: string, leftValue?: number, rightValue?: number) {
    //     return <View style={styles.item}>
    //         <Text style={[{
    //             textAlign: 'right',
    //             color: 'rgba(46, 45, 45, 1)',
    //         }, styles.textItem]}>{leftValue == undefined ? 'N/D' : leftValue}</Text>
    //         <Text style={{
    //             fontSize: 12,
    //             fontWeight: '500',
    //             color: '#797B87',
    //             width: 300,
    //             textAlign: 'center'
    //         }}>{name}</Text>
    //         <Text style={[{
    //             textAlign: 'left',
    //             color: 'rgba(46, 45, 45, 1)',
    //         }, styles.textItem]}>{rightValue == undefined ? 'N/D' : rightValue}</Text>
    //     </View>
    // }

    function renderLastFightButton(f: Fighter) {
        return <TPressable style={styles.lastFights} onPress={() => props.onLastFightClick(f)}
                           name={"LastFightsButton"}>
            <Text style={styles.lastFightsText}>{TRANSLATES['LastFights']}</Text>
        </TPressable>
    }


    let red = props.fight.red;
    let blue = props.fight.blue;

    // const getOddString = (v: number | undefined) => {
    //     if (v == undefined)
    //         return 'N/D'
    //
    //     if (v > 0)
    //         return '+' + ' ' + v
    //
    //     return v
    // }


    return (
        <ScrollView style={styles.root}>

            <View style={{marginLeft: 24, marginRight: 24}}>
                <GFightBattle
                    fight={props.fight}
                    onboardStep={null}
                    red={props.fight.red}
                    blue={props.fight.blue}/>
            </View>
            <View style={{justifyContent: 'space-between', flexDirection: 'row', marginLeft: 24, marginRight: 24}}>
                {renderLastFightButton(props.fight.red)}
                {renderLastFightButton(props.fight.blue)}
            </View>

            {fantasyAppFeatures?.enableSubscription &&
                <View style={{padding: 16}}>
                    <GradientButton onClick={props.openCompareStatClick}
                                    height={35}
                                    type="hor"
                                    width={Dimensions.get('window').width - 32}>
                        <Text style={{
                            zIndex: 1,
                            color: '#FFFFFF',
                            fontWeight: '700',
                            fontSize: 14,
                            lineHeight: 16,
                            textTransform: 'uppercase'
                        }}>{TRANSLATES['compare-fighters.open']}
                        </Text>
                        <Image style={{
                            height: 14,
                            width: 14,
                            marginLeft: 8
                        }} source={require('../icons/plus-more.png')}/>
                    </GradientButton>
                </View>
            }

            <Text style={styles.headerText}>{TRANSLATES['General']}</Text>
            {renderItem(TRANSLATES['Record'], getRecordString(redRecord), getRecordString(blueRecord))}
            {renderItem(TRANSLATES['Age'], getAgeString(red), getAgeString(blue))}
            {renderItem(TRANSLATES['Country'], red.countryName, blue.countryName)}
            {renderItem(TRANSLATES['Weight'], red.weight, blue.weight)}
            {renderItem(TRANSLATES['Height'], red.height, blue.height)}
            {renderItem(TRANSLATES['Reach'], red.armSpan, blue.armSpan)}

            <Text style={styles.headerText}>{TRANSLATES['WinBy']}</Text>


            <View style={{
                paddingLeft: 12,
                paddingRight: 12,
                marginBottom: 50
            }}>


                <CompareOneValue
                    text={'KO/TKO'}
                    redValue={getCnt(redRecord, ['KO', 'TKO'])}
                    blueValue={getCnt(blueRecord, ['KO', 'TKO'])}
                    max={20}
                    useMax={false}
                />


                <CompareOneValue
                    text={'SUB'}
                    redValue={getCnt(redRecord, ['SUB', 'TB'])}
                    blueValue={getCnt(blueRecord, ['SUB', 'TB'])}
                    max={20}
                    useMax={false}
                />

                <CompareOneValue
                    text={'DEC'}
                    redValue={getCnt(redRecord, ['WP', 'UD', 'MD', 'SD'])}
                    blueValue={getCnt(blueRecord, ['WP', 'UD', 'MD', 'SD'])}
                    max={20}
                    useMax={false}
                />


                <Text style={styles.headerText}>{TRANSLATES['Stats']}</Text>

                <CompareOneValue
                    text={TRANSLATES['ThrownStrikesPerMin']}
                    redValue={redStat?.StrikesPerMin}
                    blueValue={blueStat?.StrikesPerMin}
                    max={20}
                    useMax={false}
                />

                <CompareOneValue
                    text={TRANSLATES['LandedStrikesPerMin']}
                    redValue={redStat?.StrikesLandedPerMin}
                    blueValue={blueStat?.StrikesLandedPerMin}
                    max={20}
                    useMax={false}
                />

                <CompareOneValue
                    text={TRANSLATES['StrikesEfficiency']}
                    redValue={redStat ? Math.round(redStat.EfStrikes) : undefined}
                    blueValue={blueStat ? Math.round(blueStat.EfStrikes) : undefined}
                    max={20}
                    useMax={false}
                />

                <CompareOneValue
                    text={TRANSLATES['TakedownPer15']}
                    redValue={redStat?.TakedownCountAvg5}
                    blueValue={blueStat?.TakedownCountAvg5}
                    max={20}
                    useMax={false}
                />


                <CompareOneValue
                    text={TRANSLATES['AttemptsSubmissionPer15']}
                    redValue={redStat?.SubmissionCountAvg5}
                    blueValue={blueStat?.SubmissionCountAvg5}
                    max={20}
                    useMax={false}
                />

                <CompareOneValue
                    text={TRANSLATES['SuccessSubmissionPer15']}
                    redValue={redStat?.SubmissionCountSuccessAvg5}
                    blueValue={blueStat?.SubmissionCountSuccessAvg5}
                    max={20}
                    useMax={false}
                />

                <Text style={styles.headerText}>{TRANSLATES['Odds']}</Text>

                <CompareOneValue
                    text={TRANSLATES['Win']}
                    redValue={props.oddRed}
                    blueValue={props.oddBlue}
                    max={20}
                    useMax={false}
                />



            </View>
        </ScrollView>
    );


}


const styles = StyleSheet.create({
    root: {
        height: getHeightExpression(200)
    },
    lastFights: {
        backgroundColor: MAIN_BAR_COLOR,
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        width: 83,
        marginTop: 12
    },
    lastFightsText: {
        fontSize: 12,
        color: '#fff',
        fontWeight: '700'
    },
    headerText: {
        height: 43,
        fontSize: 15,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 12,
        color: 'rgba(46, 45, 45, 1)'
    },
    item: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textItem: {
        width: 150,
        fontSize: 15,
        fontWeight: '700',
        color: '#2E2D2D'
    },
    rightTextAlign: {
        textAlign: 'right',
    }
});

import {Platform, SafeAreaView, StyleSheet, Text, View,} from 'react-native';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ServerAPI} from '../ServerAPI';
import {CountTabs, FantasyInfo, FantasyListFilter, FantasyStatus, User} from '../types';


import UserAppBar, {TopUserPanelHeight} from '../UserAppBar';
import {
    delay,
    getData,
    getDateStringFromUTC2LocalTime,
    getHeightExpression,
    getWidthExpression,
    isPastEvent,
    saveData,
    SaveUserFunc,
    UserContext
} from '../func';
import FButton from '../controls/FButton';
import Card from '../team/Card';
import TRANSLATES from '../translates/translates';
import {COLORS, MAIN_BAR_COLOR, ORANGE_COLOR} from '../colors';
import ScrollViewRefresh from "../controls/ScrollViewRefresh";
import TPressable from "../controls/TPressable";
import {OnBoardingStyles} from "../Onboarding";
import BoardStep from "../controls/BoardStep";
import {initFirebase} from "../ads";
import Tabs from "../profile/Tabs";


interface IProps {
    navigation?: any;
    route?: any
}

const ONBoardSteps = [
    'FantasyEventList_OnBoard1',
    'FantasyEventList_OnBoard2',
    'FantasyEventList_OnBoard3',
    'FantasyEventList_OnBoard4',
]

let updateId: any = null
let firstUpdate = true

export default function FantasyEventList(props: IProps) {

    const {user, setUser} = useContext(UserContext);
    const [onboardStep, setOnboardStep] = useState<number | null>(null)
    const [events, setEvents] = useState<FantasyInfo[]>([])

    const [refreshing, setRefreshing] = useState(false)
    const [offsetDays, setOffsetDays] = useState(7)
    const [countTabs, setCountTabs] = useState<CountTabs | null>(null)

    const [selectedTab, setSelectedTab] = useState<FantasyListFilter | null>(null)
    const stateRef = useRef<FantasyListFilter>(FantasyListFilter.Upcoming);



    useEffect(() => {
        //при первом открытии этот метод сразу вызывается

        mount();

        initFirebase((fId: number) => openFantasy(fId));

        return props.navigation.addListener(
            'focus',
            async () => {
                refresh()
            }
        );

    }, [props.navigation]);


    const mount = async () => {
        await refreshOnBording()
    }

    const doUpdate = async (isTimeout = false) =>
    {
        clearTimeout(updateId)
        updateId = null
        await updateTabs()
        if (isTimeout)
            await refresh(false)
        else
            await refresh()
        updateId = setTimeout(() => doUpdate(true), 4000)
    }


    useEffect(() => {
        doUpdate()
    }, [offsetDays, user?.Email, selectedTab]);

    const updateTabs = async () => {
        if (!props.navigation.isFocused())
            return
        let res = await ServerAPI.get<CountTabs>(`/fantasy/count-tabs`)
        if (firstUpdate)
        {
            firstUpdate = false
            if (res.Live)
            {
                setSelectedTab(FantasyListFilter.Live)
            }
            else if (res.Open)
            {
                setSelectedTab(FantasyListFilter.Open)
            }
            else if (res.Upcoming)
                setSelectedTab(FantasyListFilter.Upcoming)
            else
                setSelectedTab(FantasyListFilter.Past)
        }
        setCountTabs(res)
        await delay(10)
    }

    const refresh = async (showLoader = true) => {

        if (!props.navigation.isFocused() || refreshing || !selectedTab)
            return

        setRefreshing(showLoader)

        let res = await ServerAPI.get<FantasyInfo[]>(`/fantasy/events?offsetDays=${offsetDays}&filter=${selectedTab}`)


        if (selectedTab == FantasyListFilter.Past)
            res = res.reverse()

        setEvents(res)

        setRefreshing(false)


        let fantasyId = props.route.params?.fantasyId as number;
        if (fantasyId)
            openFantasy(fantasyId)
    }

    const openFantasy = (fantasyId: number) => {
        openEventById(fantasyId)
        props.route.params = null;		//иначе не закрывается
    }

    const refreshOnBording = async () => {
        let res = await getData('OnBoardingDone_FantasyEventList');

        if (!res)
            setOnboardStep(0)
    }

    const refreshBalance = async (setUser: SaveUserFunc) => {
        let user = await ServerAPI.get<User>('/fantasy/getProfile');
        setUser(user);
    }

    const eventClick = (item: FantasyInfo) => {
        props.navigation.navigate('battleList', {fantasyInfo: item, filter: selectedTab});
    }

    const openEventById = async (fId: number) => {
        let f = events.find(it => it.Id == fId)
        if (!f)
            f = await ServerAPI.get<FantasyInfo>(`/fantasy/${fId}`);

        if (f)
            eventClick(f)
    }


    const load7DaysClick = () => {
        setOffsetDays(offsetDays + 7)
    }

    const onBoardNextClick = () => {
        if (onboardStep!! + 1 == ONBoardSteps.length)
            doneOnboarding()
        else
            setOnboardStep(onboardStep! + 1)
    }

    const onBoardSkipClick = () => {
        doneOnboarding()
    }

    const doneOnboarding = () => {
        setOnboardStep(null)

        saveData('OnBoardingDone_FantasyEventList', 'done')
    }

    const renderEventBalance = (item: FantasyInfo) => {
        return <View style={styles.balanceItem}>
            <Card width={14} height={10}/>
            <Text style={{color: '#2E2D2D', fontWeight: '700', fontSize: 10}}>
                {TRANSLATES['Balance']} {item.UserBalance}
            </Text>
        </View>
    }


    const renderButtonEvent = (item: FantasyInfo) => {

        if (item.IsLive)
            return <View style={styles.buttons}>
                <FButton width={94} margin={0} text={TRANSLATES['Live']}
                         backgroundColor={ORANGE_COLOR}
                         color={'#FFFFFF'}
                         onClick={() => eventClick(item)}/>
            </View>

        if (item.Status == FantasyStatus.Open) {
            return <View style={styles.buttons}>
                <FButton width={94} margin={0} text={TRANSLATES['Play']}
                         style={{paddingLeft: 8, paddingRight: 8}}
                         textStyle={{fontSize: 11}}
                         onClick={() => eventClick(item)}/>
                {renderEventBalance(item)}
            </View>
        } else if (item.Status == FantasyStatus.Calculated) {

            if (item.PTS > 0)
                return <View style={styles.pts}>
                    <Text style={styles.ptsText1}>
                        {item.PTS}
                    </Text>
                    <Text style={styles.ptsText2}>
                        PTS
                    </Text>
                </View>
            else
                return <View style={styles.pts}>
                    <Text style={styles.ptsText1}>
                        {TRANSLATES['fantasy-list.past']}
                    </Text>
                </View>
        } else {
            let isPast = isPastEvent(item)
            return <View style={styles.pts}>
                <Text style={styles.ptsText1}>
                    {isPast ? TRANSLATES['fantasy-list.past'] : TRANSLATES['fantasy-list.upcoming']}
                </Text>
            </View>
        }
    }

    const getBottomText = () => {
        switch (selectedTab) {
            case FantasyListFilter.Live:
                return TRANSLATES['fantasy-list.live.text']
            case FantasyListFilter.Open:
                return TRANSLATES['fantasy-list.make-picks.text']
            case FantasyListFilter.Upcoming:
                return TRANSLATES['fantasy-list.upcoming.text']
            case FantasyListFilter.Past:
                return TRANSLATES['fantasy-list.past.text']
        }

        return '';
    }

    let onBoardList = ONBoardSteps

    return (<SafeAreaView style={styles.root}>

            <UserAppBar onEventSelected={(fId: number) => openEventById(fId)} navigation={props.navigation}/>

            {onboardStep != null && <View style={{
                width: '100%',
                height: '100%',
                padding: 16,
                position: 'absolute',
                zIndex: 2
            }}>
                <BoardStep onNextClick={() => onBoardNextClick()}
                           currentStep={onboardStep!}
                           position={'top'}
                           stepCount={onBoardList.length}
                           text={(TRANSLATES as any)[onBoardList[onboardStep!]]}
                           onSkipClick={() => onBoardSkipClick()}/>

            </View>}

            <Tabs value={selectedTab} style={{marginTop: -16}}
                  selectedTabColor={selectedTab == FantasyListFilter.Live ? ORANGE_COLOR : MAIN_BAR_COLOR}
                  items={[
                      {value: FantasyListFilter.Past, text: TRANSLATES['fantasy-list.past'], width: 'auto'},
                      {
                          value: FantasyListFilter.Live,
                          text: TRANSLATES['fantasy-list.live'],
                          width: 'auto',
                          count: countTabs?.Live
                      },
                      {
                          value: FantasyListFilter.Open,
                          text: TRANSLATES['fantasy-list.make-picks'],
                          width: 'auto',
                          count: countTabs?.Open
                      },
                      {
                          value: FantasyListFilter.Upcoming,
                          text: TRANSLATES['fantasy-list.upcoming'],
                          width: 'auto',
                          count: countTabs?.Upcoming
                      }
                  ]}
                  onTabSelected={(value: string) => {
                      setSelectedTab(value as FantasyListFilter)
                      stateRef.current = value as FantasyListFilter;
                  }}/>

            <ScrollViewRefresh style={styles.content}
                               refreshing={refreshing}
                               onRefresh={() => {
                                   updateTabs()
                                   refresh()
                                   refreshBalance(setUser)
                               }}
            >


                {events.map((item, idx) => {
                        let onBoardStyle = null;

                        switch (onboardStep) {
                            case 0:
                                if (idx == 0)
                                    onBoardStyle = OnBoardingStyles.highlight;
                                break;
                            case 1:
                                if (item.IsLive && idx < 3)		//иначе он может быть далеко внизу проскроллирован
                                    onBoardStyle = OnBoardingStyles.highlight;
                                break;
                            case 2:
                                if (item.Status == FantasyStatus.Open && idx < 3)		//иначе он может быть далеко внизу проскроллирован
                                    onBoardStyle = OnBoardingStyles.highlight;
                                break;
                        }

                        return <TPressable name="MakePicks"
                                           key={idx}
                                           style={[styles.eventItem, onBoardStyle]}
                                           onPress={() => eventClick(item)}>
                            <View style={styles.eventTexts}>
                                <Text style={styles.eventName} numberOfLines={2}>{item.EventName}</Text>
                                <Text
                                    style={styles.eventDate}>{getDateStringFromUTC2LocalTime(item.EventDate)}</Text>
                            </View>
                            {renderButtonEvent(item)}
                        </TPressable>
                    }
                )}
            </ScrollViewRefresh>

            <View style={{margin: 16}}>
                {selectedTab == FantasyListFilter.Past && <FButton margin={0}
                                                                   style={{marginBottom: 16}}
                                                                   text={TRANSLATES['Load7Days']}
                                                                   backgroundColor={MAIN_BAR_COLOR}
                                                                   color={'#FFFFFF'}
                                                                   onClick={() => load7DaysClick()}/>
                }
                <Text style={{textAlign: 'center', color: COLORS.TEXT_COLOR}}>
                    {getBottomText()}
                </Text>
            </View>
        </SafeAreaView>
    );


}

const styles = StyleSheet.create({

    pts: {
        marginLeft: 'auto',
    },
    ptsText1: {
        fontWeight: '700',
        fontSize: 15,
        color: MAIN_BAR_COLOR,
        textAlign: 'right',
    },
    ptsText2: {
        fontSize: 12,
        color: '#A6A9C0',
        textAlign: 'right',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    balanceItem: {
        width: 94,
        height: 18,
        borderRadius: 10,
        backgroundColor: '#E5E6EB',
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    content: {
        marginTop: 20,
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 30,
        height: getHeightExpression(TopUserPanelHeight),
    },
    root: {
        height: '100%',
    },
    eventItem: {
        flex: 1,
        height: 97,
        backgroundColor: '#FFFFFF',
        marginBottom: 16,
        borderRadius: 10,
        padding: 14,
        paddingBottom: 27,
        borderWidth: 2,
        borderColor: 'transparent',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    eventTexts: {
        maxWidth: getWidthExpression(Platform.OS == 'web' ? 80 : 150),
    },
    eventName: {
        flexWrap: 'wrap',
        color: '#2E2D2D',
        fontSize: 15,
        fontWeight: '700',
        maxWidth: 209
    },
    eventDate: {
        marginTop: 8,
        color: '#2E2D2D',
        fontSize: 15,
        fontWeight: '500',
    },
});

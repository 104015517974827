import {AppRegistry} from 'react-native';
import App from './App';
import {getData, SESSION_TOKEN} from "./func";
import {ServerAPI} from "./ServerAPI";


AppRegistry.registerComponent('StatsFight', () => App);

function startApp()
{
	const rootTag = document.getElementById('root');
	AppRegistry.runApplication('StatsFight', {rootTag});
}

async function start()
{
	ServerAPI.initDeviceToken();
	let su = await getData(SESSION_TOKEN);

	if (su)
	{
		try
		{
			let u = JSON.parse(su)
			ServerAPI.setToken(u.Token)

		} catch (er)
		{
			console.log(er)
		}
	}

	startApp();
}

start();






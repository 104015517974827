import React from "react";
import {ServerAPI} from "../ServerAPI";
import {CardPackage4, CardStatus, User, UserCard} from "../types";
import {
    StyleSheet,
    Text,
    View,
    Pressable,
    GestureResponderEvent,
    Dimensions,
    ScrollView,
    Image,
    Modal
} from "react-native";
import TRANSLATES from "../translates/translates";
import ShopCard from "./ShopCard";
import FButton from "../controls/FButton";
import {formatWithDecimals, getWidthExpression, UserContext} from "../func";
import {MAIN_BAR_COLOR} from "../colors";
import Card from '../UserCardPage/Card';
import MessageToolTip from "../controls/MessageToolTip";
import {OnBoardingStyles} from "../Onboarding";
import BottomModal from "../controls/BottomModal";
import GiveRewardScreen from "../team/GiveRewardScreen";
import PreviewPack from "./PreviewPack";

interface IProps {
	onChanged?: Function,
	onBuyClickWithoutAuth?: Function
}

interface IState {
	cardPrices: CardPrice[],
	cardPackages: CardPackage4[],
	alertMsg: string | null
	alertCards: UserCard[]
	stepViewCard: number | null
	previewItem: CardPrice | CardPackage4 | null
	previewText: string
}

export interface CardPrice {
	price: number,
	status: CardStatus
}

export class CardShop extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			cardPrices: [],
			alertCards: [],
			cardPackages: [],
			alertMsg: null,
			stepViewCard: null,
			previewItem: null,
			previewText: ''
		};
	}

	componentDidMount()
	{
		this.refresh();
	}

	async refresh()
	{
		let list = await ServerAPI.get<CardPackage4[]>('/card/getCardPackages');
		this.setState({
			cardPackages: list
		});

		let cardPrices = await ServerAPI.get<CardPrice[]>('/card/getBaseCardPackage');
		if (cardPrices && cardPrices.length)
		{
			this.setState({cardPrices: cardPrices});
		}
	}

	renderPackage(p: CardPackage4, user: User, setUser: Function)
	{
		return <View key={p.id} style={{display: 'flex', alignItems: 'center', width: 100}}>
			<Text style={styles.cardNameText}>{(TRANSLATES as any)[p.status + 'Pack']}</Text>
            <ShopCard status={p.status} style={{marginBottom: 8}}
					  text1={TRANSLATES['cards4']}
					  text2={TRANSLATES['Pack4Desc']}
					  //onSelected={() => this.buyCardPackageClick(p, user, setUser)}
					  onSelected={() => this.setState({previewItem: p, previewText: (TRANSLATES as any)[p.status + 'Pack']})}
                      price={formatWithDecimals(p.price) + ''}/>
		</View>
	}

	async buyCardPackageClick(item: CardPackage4, user: User, setUser: Function)
	{
		this.setState({previewItem: null})
		ServerAPI.track('Shop_4' + item.status);

		if (!ServerAPI.hasAuth() && this.props.onBuyClickWithoutAuth)
			return this.props.onBuyClickWithoutAuth()

		if (user.Coins >= item.price)
		{
			let res = await ServerAPI.post<{
				balance: number,
				newCards: UserCard[]
			}>(`/fantasy/buyCardPackage4/${item.id}`);
			user.Coins = res.balance;
			setUser(user);

			this.setState({alertCards: res.newCards, stepViewCard: 0})

			if (this.props.onChanged)
				this.props.onChanged();
		}
		else
		{
			this.setState({alertMsg: TRANSLATES['NotEnoughCoins']})
		}
	}


	async buyCard(status: CardStatus, user: User, setUser: Function)
	{
		this.setState({previewItem: null})
		ServerAPI.track('Shop_1' + status);

		if (!ServerAPI.hasAuth() && this.props.onBuyClickWithoutAuth)
			return this.props.onBuyClickWithoutAuth()

		let price = this.state.cardPrices.find(it => it.status == status)?.price;

		if (user.Coins >= price!!)
		{
			let res = await ServerAPI.post<{
				balance: number,
				newCards: UserCard[]
			}>(`/fantasy/buyRandomCard/${status}`);
			user.Coins = res.balance;
			setUser(user);

			this.setState({alertCards: res.newCards, stepViewCard: 0})

			if (this.props.onChanged)
				this.props.onChanged();
		}
		else
		{
			this.setState({alertMsg: TRANSLATES['NotEnoughCoins']})
		}
	}

	renderCardWithButton(status: CardStatus, user: User, setUser: Function)
	{
		let price = this.state.cardPrices.find(it => it.status == status)?.price || 0;

		return <View style={{display: 'flex', alignItems: 'center', width: 100}} key={status}>
			<Text style={styles.cardNameText}>{(TRANSLATES as any)[status + 'Card']}</Text>
			<ShopCard status={status} style={{marginBottom: 8}}
					  onSelected={() => {
						  this.setState({
							  previewText: (TRANSLATES as any)[status + 'Card'],
							  previewItem: {
								  price: price,
								  status: status
							  }
						  })
						  //this.buyCard(status, user, setUser)
					  }}
					  price={formatWithDecimals(price) + ''}/>
		</View>
	}

	render()
	{
		return (<UserContext.Consumer>
			{({user, setUser}) => (
				 <View>

					 {this.state.previewItem && (this.state.previewItem as CardPackage4).id == undefined && <PreviewPack
						 onBuyClick={() => {
                             this.buyCard(this.state.previewItem!.status, user, setUser)
                         }}
						 previewText={this.state.previewText}
						 cardPrice={this.state.previewItem}
						 onClose={() => this.setState({previewItem: null})}/>}

                     {(this.state.previewItem as CardPackage4)?.id != undefined && <PreviewPack
                         onBuyClick={() => {
                             this.buyCardPackageClick(this.state.previewItem as CardPackage4, user, setUser)
                         }}
                         previewText={this.state.previewText}
                         cardPackage4={this.state.previewItem as CardPackage4}
                         onClose={() => this.setState({previewItem: null})}/>}

					 <View style={styles.firstList}>
						 {this.renderCardWithButton(CardStatus.bronze, user, setUser)}
						 {this.renderCardWithButton(CardStatus.silver, user, setUser)}
						 {this.renderCardWithButton(CardStatus.gold, user, setUser)}
					 </View>

					 <View style={[styles.firstList, {marginTop: 24}]}>
					    {this.state.cardPackages.map(it => this.renderPackage(it, user, setUser))}
					 </View>

					 {this.state.alertMsg ? <MessageToolTip onClose={() => this.setState({alertMsg: null})} message={this.state.alertMsg}/> : <></>}

					 {this.state.alertCards?.length > 0 && this.renderCardsMessage()}

				 </View>
			)}
		</UserContext.Consumer>);
	}

    closeReward()
    {
        this.setState({stepViewCard: null, alertCards: []})
    }

	 nextCard(){

        if (this.state.alertCards.length == 1)
            return this.closeReward()

		if (this.state.stepViewCard != null && this.state.alertCards && this.state.stepViewCard < this.state.alertCards.length)
			this.setState({stepViewCard: this.state.stepViewCard + 1})
		else
            this.closeReward()
	}

	renderCardsMessage()
	{
		if (this.state.stepViewCard == null)
			return <></>

        let arr = this.state.alertCards

        let children = <></>

        if (this.state.stepViewCard < arr.length)
            children = <GiveRewardScreen closeReward={() => this.nextCard()} animateNum={this.state.stepViewCard}>
                <Card card={arr[this.state.stepViewCard]}
                      onSelected={() => this.nextCard()}/>
            </GiveRewardScreen>
        else
        {
            let groupedCardBy2 :UserCard[][]= []
            for (let i = 0; i < arr.length; i += 2) {
                groupedCardBy2.push([arr[i], arr[i + 1]].filter(it => it))
            }

            children = <GiveRewardScreen closeReward={() => this.closeReward()} animateNum={this.state.stepViewCard}>

                {groupedCardBy2.map((it, idx) =>
                    <View key={idx} style={{flexDirection: 'row', justifyContent: 'space-around', marginBottom: 16, gap: 10} as any}>
                        {it.map((card, idx) => <Card card={card} key={idx} onSelected={() => this.closeReward()}/>)}
                    </View>
                )}
            </GiveRewardScreen>
        }

        return  <Modal
            animationType={"slide"}
            transparent={false}
            visible={true}
            style={{backgroundColor: MAIN_BAR_COLOR, position: 'relative'}}
        >
            {children}
        </Modal>
	}
}

const styles = StyleSheet.create({
	cardNameText: {
		fontWeight: '700',
		color: '#2E2D2D',
		fontSize: 12,
		textAlign: 'center',
		marginBottom: 8,
	},
	firstList: {
		flexDirection: 'row',
		height: 192,
		justifyContent: 'space-between',
	},
	modalText: {
		textAlign: 'center',
		color: 'black'
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22,
	},
	modalView: {
		position: 'absolute',
		backgroundColor: 'white',
		borderRadius: 20,
		width: getWidthExpression(80),
		padding: 12,
		alignItems: 'flex-start',
		shadowColor: '#000',
		shadowOpacity: 0.25,
		shadowRadius: 2,
		elevation: 2
	},
	contentView: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	}
})







interface IPropsCardList {
	list: UserCard[],
	enabledList?: UserCard[],
	selectedList?: UserCard[],
	onCardSelected?: Function,
	onCardListChanged?: Function
	showText?: boolean,
	text?: string,
	showShop?: boolean,
	onBoardVis?: boolean
}

interface RInfo {
	map: Map<string, number>,
	list: UserCard[]
}

export class CardList extends React.Component<IPropsCardList, {
	centralIndex: number,
	shopVisible: boolean,
	map: Map<string, number>,
	reduceList: UserCard[]
}> {


	constructor(props: IPropsCardList)
	{
		super(props);

		let res = this.initReduceList(props.list) as any

		this.state = {
			centralIndex: 1,
			shopVisible: false,
			map: res.map,
			reduceList: res.list
		};
	}

	getCardId(it : UserCard)
	{
		return it.cardId + ' ' + it.exCardData
	}

	initReduceList(list: UserCard[]): RInfo
	{
		let map = new Map<string, number>();
		let reduceList: UserCard[] = []
		list.forEach(it =>
		{
			let id = this.getCardId(it)
			if (!map.has(id))
			{
				map.set(id, 1);
				reduceList.push(it)
			}
			else
				map.set(id, map.get(id)!! + 1);
		})

		return {map: map, list: reduceList}
	}

	componentWillReceiveProps(nextProps: IPropsCardList)
	{
		let res = this.initReduceList(nextProps.list)
		this.setState({map: res.map, reduceList: res.list});
	}

	onCardClick(card: UserCard)
	{
		if (this.props.onCardSelected)
		{
			if (this.props.enabledList)
			{
				if (this.props.enabledList.find(it => it == card))
					this.props.onCardSelected(card);
			}
			else
			{
				this.props.onCardSelected(card);
			}
		}
	}

	render()
	{
		if (!this.state.reduceList?.length)
			return <></>;


		let card1 = this.state.reduceList[this.state.centralIndex - 1] || this.state.reduceList[this.state.reduceList.length - 1];
		let card2 = this.state.reduceList[this.state.centralIndex];
		let card3 = this.state.reduceList[this.state.centralIndex + 1] || this.state.reduceList[0];

		return <View>
			{this.props.showText && <View style={[{borderWidth: 2, borderColor: 'transparent'}, this.props.onBoardVis && OnBoardingStyles.highlight]}>
			 <Text style={{
					 fontSize: 14,
					 marginTop: 24,
					 marginBottom: 16,
					 color: '#000000',
					 textAlign: 'left',
					 fontWeight: '700',
				 }}>
					 {this.props.text || TRANSLATES['SelectCards']}
			 </Text>
		 </View>
			}

			{this.state.reduceList.length >= 3 ?
				 <View style={styles2.root}
						 onStartShouldSetResponder={(e) => true}
						 onMoveShouldSetResponder={() => true}
						 onResponderRelease={(e: GestureResponderEvent) =>
						 {
							 if (e.nativeEvent.locationX > 150)
								 this.leftClick();
							 else
								 this.rightClick();
						 }}>


					 <Pressable style={{left: -15}} onPress={() => this.leftClick()}>
						 <Image source={require('../icons/left.png')} style={{width: 7, height: 12}}/>
					 </Pressable>

					 <Card card={card1}
							 count={this.state.map.get(this.getCardId(card1))}
							 style={styles2.sideCard}
							 selected={this.props.selectedList && this.props.selectedList.indexOf(card1) > -1}
							 onSelected={() => this.onCardClick(card1)}/>

					 <Card card={card2}
							 count={this.state.map.get(this.getCardId(card2))}
							 style={styles2.centralCard}
							 selected={this.props.selectedList && this.props.selectedList.indexOf(card2) > -1}
							 onSelected={() => this.onCardClick(card2)}/>

					 <Card card={card3}

							 style={{...styles2.sideCard, left: -40}}
							 selected={this.props.selectedList && this.props.selectedList.indexOf(card3) > -1}
							 onSelected={() => this.onCardClick(card3)}/>


					 <Pressable style={{left: -25}} onPress={() => this.rightClick()}>
						 <Image source={require('../icons/right.png')} style={{width: 7, height: 12}}/>
					 </Pressable>


				 </View>
				 : this.renderSimpleList()
			}

			{this.props.showShop &&
			 <View style={{marginTop: 16, marginBottom: 16}}>
				 <FButton margin={0} text={TRANSLATES['BuyNewCards']}
						  onClick={() => this.setState({shopVisible: true})}/>
			 </View>
			}

			{this.state.shopVisible &&
			 <BottomModal height={Dimensions.get('window').height - 200}
						  title={TRANSLATES['Shop']}
						  onCloseClick={() => this.setState({shopVisible: false})}
						  visible={true}>
				 <ScrollView style={{height: Dimensions.get('window').height - 220}}>
					 <CardShop onChanged={() => this.props.onCardListChanged && this.props.onCardListChanged()}/>
				 </ScrollView>
			 </BottomModal>
			}

		</View>
	}

	leftClick()
	{
		this.setState({
			centralIndex: this.state.centralIndex == 0 ? this.state.reduceList.length - 1 : this.state.centralIndex - 1
		})
	}

	rightClick()
	{
		this.setState({
			centralIndex: this.state.centralIndex == this.state.reduceList.length - 1 ? 0 : this.state.centralIndex + 1
		})
	}

	renderSimpleList()
	{
		return <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
			{this.state.reduceList.map(it =>
				 <Card card={it}
						 count={this.state.map.get(this.getCardId(it))}
						 key={it.id}
						 selected={this.props.selectedList && this.props.selectedList.indexOf(it) > -1}
						 onSelected={() => this.onCardClick(it)}/>)}
		</View>
	}
}


const styles2 = StyleSheet.create({
	root: {
		height: 140,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 20
	},
	centralCard: {
		left: -20,
		zIndex: 1
	},
	sideCard: {
		zIndex: -1,
		transform: [{scale: 0.8}]
	}
})

import {Text, StyleSheet, View, ScrollView} from 'react-native';

import React from 'react';
import {CardType, DistributionPoint, FantasyInfo, FantasyRateRow, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';

import FighterAppBar from './FighterAppBar';
import {ServerAPI} from '../ServerAPI';
import ExpandBoxPoint from './ExpandBoxPoint';
import {distinctBy, getData, getHeightExpression, saveData} from '../func';
import {MAIN_BAR_COLOR, ORANGE_COLOR} from '../colors';
import BoardStep from "../controls/BoardStep";


interface IProps {
	athleteRow: FantasyRateRow,
	fantasy: FantasyInfo,
	onCloseClick: Function
	fighter: Fighter
}

interface IState {
	appliedUserCards: UserCard[],
	onboardStep: number | null
}

const ONBoardSteps = [
	'FantasyRateRowView_OnBoard1'
]

export default class AthleteRowResult extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			appliedUserCards: [],
			onboardStep: null
		};
	}


	componentDidMount()
	{
		this.refresh();
		this.updateOnBoarding()
	}

	async updateOnBoarding()
	{
		let res = await getData('OnBoardingDone_FantasyRateRowView');

		if (!res)
			this.setState({
				onboardStep: 0
			})
	}

	doneOnboarding()
	{
		this.setState({onboardStep: null})

		saveData('OnBoardingDone_FantasyRateRowView', 'done')
	}

	async refresh()
	{
		let userCards = await ServerAPI.get<UserCard[]>(`/fantasy/getUserCards/${this.props.athleteRow.Id}`);
		this.setState({
			appliedUserCards: userCards,
		});
	}

	getTotalPoints(p: DistributionPoint)
	{
		let calcs = this.props.athleteRow.CalcLog.filter(it => it.winPointId == p.Key);

		if (!calcs.length)
		{
			return 0;
		}

		return calcs
			 .reduce((prev, current) => prev + current.startPoints + current.cardPoints, 0);
	}

	getCalcLog(p: DistributionPoint)
	{
		let calcs = this.props.athleteRow.CalcLog.filter(it => it.winPointId == p.Key);

		if (!calcs.length)
		{
			return [];
		}

		let sum = calcs
			 .reduce((prev, current) => prev + current.startPoints + current.cardPoints, 0);

		let cardText = '';
		if (calcs[0].userCardId)
		{
			let userCard = this.state.appliedUserCards.find(it => it.id == calcs[0].userCardId);
			if (userCard)
			{

				cardText = `${(TRANSLATES as any)[userCard.status + 'Card']}  ${userCard.statusCoef}x - ${Math.round(calcs[0].cardPoints * 100) / 100}`;
			}
		}

		return [
			TRANSLATES['AthleteRowResult.numberofcoins'].replace('{0}', p.Points + ''),
			`${TRANSLATES['Numbers']} - ${calcs.length}`,
			`${TRANSLATES['Coefficient']} - ${calcs[0].pointCoef}`,
			cardText,
			`${TRANSLATES['Total']} - ${Math.round(sum)}`,
		];
	}

	getCardCalcLog(userCard: UserCard)
	{
		let calcLog = this.props.athleteRow.CalcLog.find(it => it.userCardId == userCard.id);
		if (calcLog)
		{
			return [
				`${TRANSLATES['Base']} - ${calcLog.startPoints}`,
				`${(TRANSLATES as any)[userCard.status + 'Card']} - ${userCard.statusCoef}x`,
				`${TRANSLATES['Total']} - ${Math.round(calcLog.cardPoints)}`,
			];
		}
	}

	render()
	{
		let winPoints = this.props.athleteRow.WinPoints;

		let baseList = winPoints.filter(it => (it.Type == 'Base' || it.Type == 'Additional1') && it.Points > 0)
			 .sort((a, b) => b.Points - a.Points);

		let additionalList = [...new Set(winPoints.map(it => it.Key))]
			 .map(key => winPoints.find(it => it.Key == key))
			 .filter(it => it && it.Type == 'Additional2' && it.Points > 0) as DistributionPoint[];

		let bonusList = winPoints.filter(it => it.Type == 'Extra');


		let penaltyList = winPoints
			 .filter(it => it.Points < 0);


		penaltyList = distinctBy(penaltyList, it => it.Name)


		let acceptedPointsCards = this.state.appliedUserCards
			 .filter(userCard => userCard.name != CardType.Athlete && userCard.name != CardType.Promotion && userCard.name != CardType.Country);

		let startCoinsEarned = this.props.athleteRow.CalcLog
			 .filter(card => !card.userCardId || acceptedPointsCards.find(it => it.id == card.userCardId))
			 .reduce((prev, current) =>
			 {

				 let cardSum = 0;
				 if (acceptedPointsCards.find(it => it.id == current.userCardId))
				 {
					 cardSum = current.cardPoints;
				 }

				 return prev + current.startPoints + cardSum;
			 }, 0);


		let acceptedCards = this.state.appliedUserCards
			 .filter(userCard => userCard.name != CardType.Athlete || userCard.exCardData == this.props.athleteRow.AthleteId)
			 .filter(userCard => userCard.name == CardType.Athlete || userCard.name == CardType.Promotion || userCard.name == CardType.Country)
			 .sort((a, b) => a.name.localeCompare(b.name));

		let cardsCoinsEarned = this.props.athleteRow.CalcLog
			 .filter(c => acceptedCards.find(it => it.id == c.userCardId))
			 .reduce((prev, current) => prev + current.cardPoints, 0);


		return (
			 <View style={styles.root}>


				 <FighterAppBar fighter={this.props.fighter} fantasy={this.props.fantasy}
									 onBackClick={() => this.props.onCloseClick()}/>

				 {this.state.onboardStep != null && <View style={{
					 width: '100%',
					 height: '100%',
					 padding: 16,
					 position: 'absolute',
					 zIndex: 2
				 }}>
				 <BoardStep onNextClick={() => this.doneOnboarding()}
							currentStep={this.state.onboardStep}
							height={208}
							position={'bottom'}
							stepCount={1}
							text={(TRANSLATES as any)[ONBoardSteps[this.state.onboardStep]]}
							onSkipClick={() => this.doneOnboarding()}/>

			 </View>}

				 <ScrollView style={styles.content}>



					 <Text style={styles.resultText}>{TRANSLATES['Result']}</Text>

					 <View>
						 {baseList.map(p => <ExpandBoxPoint
							  key={p.Id}
							  mainText={TRANSLATES[p.Id] || p.Name}
							  points={p.Points}
						 />)}
					 </View>


					 <Text style={styles.listTitle}>{TRANSLATES['AdditionalAdv']}</Text>

					 <View>
						 {additionalList.map((p, idx) =>
						 {

							 let calcs = this.props.athleteRow.CalcLog.filter(it => it.winPointId == p.Key);
							 let cardCoefText = '';

							 if (calcs.length)
							 {
								 let c = acceptedPointsCards.find(it => it.id == calcs[0].userCardId);
								 if (c)
									 cardCoefText = c.statusCoef + ''
							 }
							 return <ExpandBoxPoint
								  key={p.Id}
								  onBordVis={idx == 0 && this.state.onboardStep == 0}
								  mainText={TRANSLATES[p.Id] || p.Name}
								  cardCoefText={cardCoefText}
								  points={Math.round(this.getTotalPoints(p))}
								  expandTextLines={this.getCalcLog(p)}
							 />;
						 })}
					 </View>


					 {bonusList.length > 0 &&
					<View>
						<Text style={styles.listTitle}>{TRANSLATES['BonusPoints']}</Text>

							  {bonusList.map(p =>
							  {
								  let calc = this.props.athleteRow.CalcLog.find(it => it.winPointId == p.Key)!!;

								  let userCard = calc == null ? null : this.state.appliedUserCards.find(it => it.id == calc.userCardId);

								  let expandLinesText: string[] = null as any;

								  if (userCard)
									  expandLinesText = [
										  `Base - ${calc.startPoints} coins`,
										  `${(TRANSLATES as any)[userCard.status + 'Card']}  ${userCard.statusCoef}x - ${Math.round(calc.cardPoints * 100) / 100}`,
										  `Total - ${Math.round(calc.startPoints + calc.cardPoints)} coins`,
									  ];

								  return <ExpandBoxPoint
										key={p.Id}
										expandTextLines={expandLinesText}
										mainText={TRANSLATES[p.Id] || p.Name}
										cardCoefText={userCard?.statusCoef}
										points={Math.round(calc ? calc.startPoints + calc.cardPoints : p.Points)}
								  />
							  })}
					</View>
					 }


					 {penaltyList.length > 0 &&
					<View>
						<Text style={styles.listTitle}>{TRANSLATES['PenaltyCoins']}</Text>
							  {penaltyList.map(p =>
							  {
								  return <ExpandBoxPoint
										key={p.Id}
										mainText={TRANSLATES[p.Id] || p.Name}
										points={Math.round(this.getTotalPoints(p))}
										expandTextLines={this.getCalcLog(p)}
								  />;
							  })}
					</View>
					 }

					 {this.renderCoinsEarned(startCoinsEarned)}

					 {acceptedCards.length > 0 && <Text style={styles.listTitle}>{TRANSLATES['AcceptedCards']}</Text>}
					 <View>
						 {
							 acceptedCards.map(userCard => <ExpandBoxPoint
								  key={userCard.id}
								  mainText={userCard.name}
								  points={Math.round(this.props.athleteRow.CalcLog.find(it => it.userCardId == userCard.id)?.cardPoints || 0)}
								  expandTextLines={this.getCardCalcLog(userCard)}
							 />)
						 }
					 </View>

					 {acceptedCards.length > 0 && this.renderCoinsEarned(cardsCoinsEarned)}

					 <View style={styles.total}>
						 <Text style={styles.totalText}>{TRANSLATES['TotalPoints']}</Text>
						 <Text
							  style={styles.totalText}>{this.renderValue(this.props.athleteRow.CalcPoints)} {TRANSLATES['Coins']}</Text>
					 </View>

					 <View style={{
						 display: 'flex',
						 justifyContent: 'flex-end',
						 flexDirection: 'row',
						 marginTop: 24,
						 height: 48
					 }}>
						 <FButton text={TRANSLATES['Event']}
									 backgroundColor={MAIN_BAR_COLOR}
									 color="#FFFFFF"
									 margin={0}
									 width={104}
									 onClick={() => this.props.onCloseClick()}/>

					 </View>

				 </ScrollView>


			 </View>);
	}

	renderValue(value: number)
	{
		return value < 0 ? value : '+' + value;
	}

	renderCoinsEarned(value: number)
	{
		return <View style={styles.coinsEarned}>
			<Text style={styles.coinsEarnedText}>{TRANSLATES['CoinsEarned']}</Text>
			<Text style={[styles.coinsEarnedText, {
				marginLeft: 'auto',
				marginRight: 16,
			}]}>{this.renderValue(Math.round(value))}</Text>
		</View>
	}
}


const styles = StyleSheet.create({
	root: {
		height: getHeightExpression(0),
	},
	content: {
		paddingLeft: 16,
		paddingRight: 16,
		backgroundColor: '#EFF1F7',
	},
	resultText: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 15,
		marginBottom: 16,
	},
	listTitle: {
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 15,
		marginTop: 24,
		marginBottom: 16,
	},
	total: {
		backgroundColor: ORANGE_COLOR,
		elevation: 3,
		height: 48,
		padding: 16,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		borderRadius: 10,
		marginLeft: 3,
		marginRight: 3,
		marginTop: 22,

	},
	totalText: {
		color: '#FFFFFF',
		fontWeight: '700',
		fontSize: 15,
	},
	coinsEarned: {
		height: 48,
		marginTop: 22,
		marginLeft: 4,
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: '#CACDDE',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: 16,
	},
	coinsEarnedText: {
		color: MAIN_BAR_COLOR,
		fontWeight: '700',
		fontSize: 15,
	},
});

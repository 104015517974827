import {StyleSheet, View, Text, Pressable, ImageBackground} from 'react-native';
import React from 'react';
import TRANSLATES from "./translates/translates";
import {MAIN_BAR_COLOR} from "./colors";
import {ServerAPI} from "./ServerAPI";
import {getHeightExpression} from "./func";


interface IProps {
	closed: Function
}

interface IState {
	step: number
}


const STEPS = [{
	title: TRANSLATES['onboarding_1.title'],
	img: 'onboard-1.png',
	desc: TRANSLATES['onboarding_1.desc']
}, {
	title: TRANSLATES['onboarding_2.title'],
	img: 'onboard-2.png',
	desc: TRANSLATES['onboarding_2.desc']
}, {
	title: TRANSLATES['onboarding_3.title'],
	img: 'onboard-3.png',
	desc: TRANSLATES['onboarding_3.desc']
}, {
	title: TRANSLATES['onboarding_4.title'],
	img: 'onboard-4.png',
	desc: TRANSLATES['onboarding_4.desc']
}, {
	title: TRANSLATES['onboarding_5.title'],
	img: 'onboard-5.png',
	desc: TRANSLATES['onboarding_5.desc']
}]

export default class Onboarding extends React.Component<IProps, IState> {


	constructor(props: IProps)
	{
		super(props);
		this.state = {
			step: 0
		};
	}

	getImage()
	{
		//в эмуляторе не хочет работать если в require передавать динамическую строку
		switch (this.state.step)
		{
			case 0:
				return <ImageBackground source={require(`./assets/onboard-1.png`)} resizeMode="contain" style={styles.image}/>
			case 1:
				return <ImageBackground source={require(`./assets/onboard-2.png`)} resizeMode="contain" style={styles.image}/>
			case 2:
				return <ImageBackground source={require(`./assets/onboard-3.png`)} resizeMode="contain" style={[styles.image, {width: 400, height: 400}]}/>
			case 3:
				return <ImageBackground source={require(`./assets/onboard-4.png`)} resizeMode="contain" style={styles.image}/>
			case 4:
				return <ImageBackground source={require(`./assets/onboard-5.png`)} resizeMode="contain" style={styles.image}/>
		}
	}

	onNextClick()
	{
		if (this.state.step == STEPS.length - 1)
		{
			ServerAPI.track('DoneOnboarding', '')
			this.props.closed('SignUp');
		}
		else
		{
			ServerAPI.track('OnboardingNextClick', this.state.step + '')
			this.setState({step: this.state.step + 1})
		}
	}

	onCloseClick()
	{
		ServerAPI.track('DoneOnboarding', '')
		this.props.closed();
	}

	render()
	{
		let step = STEPS[this.state.step];
		let eclipseImage = this.state.step == STEPS.length - 1 ? require(`./assets/ellipse2.png`) : require(`./assets/ellipse.png`)
		return <View
			 style={[styles.root, {backgroundColor: this.state.step == STEPS.length - 1 ? 'rgba(255, 188, 96, 1)' : MAIN_BAR_COLOR}]}>

			{/*{this.state.step == STEPS.length - 1 && <TPressable name={"closeModal"} onPress={() => this.onCloseClick()}*/}
			{/*												 style={[styles.closeBtn]}>*/}
			{/* <Image style={{width: 10, height: 10}} source={require('./icons/close.png')}/>*/}
			{/*</TPressable>*/}
			{/*}*/}

			<Text style={styles.title}>
				{step.title}
			</Text>
			<View style={{
				position: 'relative',
				overflow: 'visible',
				width: '120%',
				justifyContent: 'center',
				alignItems: 'center',
				height: getHeightExpression(260)
			}}>
				<ImageBackground source={eclipseImage}  style={styles.ellipse}/>
				{this.getImage()}
			</View>
			<Text style={styles.desc}>
				{step.desc}
			</Text>

			<Pressable
				 onPress={() => this.onNextClick()}
				 style={styles.button}>
				<Text style={styles.buttonText}>
					{this.state.step == STEPS.length - 1 ? TRANSLATES['SignUp'] : TRANSLATES['Continue']}
				</Text>
			</Pressable>

			<View style={styles.steps}>
				{STEPS.map((it, idx) => <View key={it.img}
														style={[styles.step, this.state.step == idx ? styles.selectedStep : null]}/>)}
			</View>
		</View>
	}
}

const styles = StyleSheet.create({
	root: {
		paddingLeft: 16,
		paddingRight: 16,
		height: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	ellipse: {
		position: "absolute",
		width: '100%',
		height: 400,
		top: 0
	},
	closeBtn: {
		position: 'absolute',
		right: 18,
		top: 18
	},
	title: {
		marginTop: 24,
		fontWeight: '700',
		fontSize: 20,
		color: '#FFFFFF',
		textAlign: 'center',
		height: 64
	},
	image: {
		width: 300,
		height: 400
	},
	desc: {
		marginTop: 'auto',
		fontWeight: '700',
		fontSize: 15,
		color: '#FFFFFF',
		textAlign: 'center',
		marginBottom: 16,
	},
	button: {
		marginBottom: 16,
		backgroundColor: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 328,
		height: 36,
		elevation: 1,
		boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.12)',
		borderRadius: 30
	}  as any,
	buttonText: {
		color: MAIN_BAR_COLOR,
		textTransform: 'uppercase',
		fontWeight: '700',
		fontSize: 12
	},
	step: {
		backgroundColor: '#FFFFFF',
		width: 15,
		height: 15,
		borderRadius: 15,
		marginLeft: 16
	},
	steps: {
		marginBottom: 16,
		width: 300,
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'row'
	},
	selectedStep: {
		backgroundColor: '#FF9400'
	}
});


export const OnBoardingStyles = StyleSheet.create({
	highlight: {
		borderColor: '#FF9400',
		borderWidth: 2,
		borderStyle: 'solid',
		borderRadius: 10,
	}
})


// export function renderBack(steps: string[], nextClick: Function, skipClick: Function)
// {
// 	return <View style={{width: '100%', height: '100%', position: 'absolute', zIndex: 2}}>
// 		<BoardStep onNextClick={nextClick}
// 					  stepsText={steps}
// 					  onSkipClick={skipClick}/>
//
// 	</View>
// }

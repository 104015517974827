import {StyleSheet, View, Text as ReactText} from "react-native";
import Svg, {Circle, Path, Text} from "react-native-svg";
import {COLORS} from "../../colors";


let height = 50;
let radius = 20

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    let start = polarToCartesian(x, y, radius, endAngle);
    let end = polarToCartesian(x, y, radius, startAngle);

    let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
}

export default function CircleChart(props: { title: string, value: number, color: string, style: any }) {
    let attackPercValue = props.value ? Math.floor(props.value) : 0,
        d = describeArc(height / 2, height / 2, radius, 0, 360 * attackPercValue / 100);

    return (
        <View style={props.style}>
            <Svg width={height} height={height}>
                <Circle cx="50%" cy="50%" r="25" fill="none" strokeWidth="4px"/>
                <Text x="50%" y="50%" fill={props.color} fontSize={12}
                      fontWeight="700" textAnchor="middle" dy=".3em">
                    {attackPercValue}
                </Text>
                {props.value === 100 ?
                    <Circle cx="50%" cy="50%" r={radius} fill="none" stroke={props.color} strokeWidth="6"></Circle>
                    :
                    d && <Path stroke={props.color} fill="none" strokeWidth="6px" d={d}/>}
            </Svg>
            <ReactText style={styles.title}>{props.title}</ReactText>
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.LIGHT_BLACK
    }
})

import React from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import ProfilePage from './profile/ProfilePage';
import {DefaultTheme, getFocusedRouteNameFromRoute, NavigationContainer} from '@react-navigation/native';
import ShopPage from './shop/ShopPage';
import FantasyNavigation from './main/FantasyNavigation';
import {Dimensions, Image, Platform, StyleSheet, Text} from 'react-native';
import { request, PERMISSIONS, RESULTS } from 'react-native-permissions';
import {CoinPrice, FantasyAppFeatures, User} from './types';
import {ServerAPI} from './ServerAPI';
import Onboarding from "./Onboarding";
import {deleteData, getData, saveData, SESSION_TOKEN, UserContext} from "./func";

import BottomModal from "./controls/BottomModal";
import TRANSLATES from "./translates/translates";
import SignForm from "./auth/SignForm";
import FButton from "./controls/FButton";


import {updateFCMToken, initAds, updateAppsFlyer, updateIAP} from "./ads";
import {MAIN_BAR_COLOR} from "./colors";
import CardNavigation from "./UserCardPage/CardNavigation";







const Tab = createBottomTabNavigator();



export const GlobalTheme = {
	...DefaultTheme,
	fontFamily: 'DIN Pro',
	colors: {
		...DefaultTheme.colors,
		primary: 'rgb(255, 45, 85)',
		background: '#EFF1F7',
	},
};

const styles = StyleSheet.create({
	tabTextStyle: {
		fontSize: 10,
		textTransform: 'uppercase',
		color: '#A6A9C0'
	},
	tabTextSelectedStyle: {
		color: 'rgba(64, 92, 191, 1)',
		fontSize: 10,
		textTransform: 'uppercase',
	},
	icon: {
		width: 25,
		height: 24
	}
});


interface IState {
	signUpVisible: boolean,
	showAskNotification: boolean,
	user: User,
	setUser: Function,
	fantasyAppFeatures: FantasyAppFeatures,
	setFantasyAppFeatures: Function,
	onboardVis: boolean
}

export default class App extends React.Component<any, IState> {

	constructor(props: any)
	{
		super(props);
		this.state = {
			fantasyAppFeatures: {} as FantasyAppFeatures,
			setFantasyAppFeatures: this.setFantasyAppFeatures,
			user: {} as User,
			setUser: this.setUser,
			onboardVis: false,
			signUpVisible: false,
			showAskNotification: false
		};
	}

	setUser = (user: User) =>
	{
		if (user && user.Token)
			ServerAPI.setToken(user.Token)

		this.setState({user});
	}

	setFantasyAppFeatures = (value: FantasyAppFeatures) => {
		this.setState({fantasyAppFeatures: value})
	}

	async showRequestPermission()
	{
		try
		{
			this.setState({showAskNotification: false})

			if (Platform.OS === 'android') {
				const result = await request(PERMISSIONS.ANDROID.POST_NOTIFICATIONS, {
					title: 'Push notifications',
					message: 'Allow us to send notifications, and you won\'t miss the opportunity to take part in an exciting our MMA Picks and watch unique Live Stats.',
					buttonNeutral: 'Ask Me Later',
					buttonNegative: 'Cancel',
					buttonPositive: 'OK',
				});
				if (result === RESULTS.GRANTED) {
					console.log('Notification permission granted');
				} else {
					console.log('Notification permission denied');
				}
			}

		} catch (err: any)
		{
			console.log(err.toString())
		}
	}



	async updateOnboarding()
	{
		let res = await getData('OnBoardingDone');

		if (!res) {

			updateFCMToken((user: User) => this.setUser(user));

			ServerAPI.track('app_first_open')
			this.setState({
				onboardVis: true
			})
		}
	}

	async onBoardingDone(navName: string)
	{

		saveData('OnBoardingDone', 'true');

		if (navName == 'SignUp')
			this.setState({onboardVis: false, signUpVisible: true})
		else
		{
			this.setState({onboardVis: false})

			if (this.state.user.FCMToken)
				this.setState({showAskNotification: true})
		}
	}


	renderRegister()
	{
		return <UserContext.Consumer>
			{({user, setUser}) => (
				 <BottomModal visible={true}
								  title={TRANSLATES['SignUp']}
							  	  height={Dimensions.get('window').height - 60}
								  onCloseClick={() => this.setState({signUpVisible: false, showAskNotification: true})}

				 >
					 <SignForm isSignIn={false}
							   	  currentEmail={user.Email}
								  doneCallback={(u: User) =>
								  {
									  this.setUser(u);
									  setUser(u)
									  this.setState({
										  signUpVisible: false,
										  showAskNotification: true
									  });
								  }}/>
				 </BottomModal>
			)}
		</UserContext.Consumer>
	}

	renderAskNotification()
	{
		return <UserContext.Consumer>
			{({user, setUser}) => (
				 <BottomModal visible={true}
								  title={TRANSLATES['Notifications']}
								  height={200}
								  onCloseClick={() => this.setState({showAskNotification: false})}

				 >

					 <Text style={{color: '#2E2D2D'}}>{TRANSLATES['AskNot']}</Text>

					 <FButton text={TRANSLATES['OK']} onClick={() =>
					 {
						 this.showRequestPermission()
					 }}/>

				 </BottomModal>
			)}
		</UserContext.Consumer>
	}



	componentDidMount()
	{
		this.updateOnboarding();

		this.loadUserFromStorage()

		updateAppsFlyer();
		initAds()

		this.updatePurchase()

		this.updateFeatures()
	}

	async updateFeatures()
	{
		let res = await ServerAPI.get<FantasyAppFeatures>('/fantasy/features');
		this.setFantasyAppFeatures(res);
	}

	async updatePurchase()
	{
		let coinPrices = await ServerAPI.get<CoinPrice[]>('/coin-prices');
		updateIAP(coinPrices, (receipt: string, productId: string, purchaseToken: string | undefined) => this.validatePurchaseAndBuy(receipt, productId, purchaseToken));
	}


	async validatePurchaseAndBuy(receipt: string, productId: string, purchaseToken: string | undefined) {

		let request = {
			productId: productId,
			receipt: receipt,
			purchaseToken: purchaseToken,
			device: Platform.OS === 'ios' ? 'ios' : 'android'
		}
		if (this.state.fantasyAppFeatures?.subscriptionId == productId)
		{
			await ServerAPI.post<number>('/fantasy/buySubscription', request);
			this.state.user.HasSubscription = true;
		}
		else {
			let balance = await ServerAPI.post<number>('/fantasy/buyCoins', request);
			this.state.user.Coins = balance;
		}
		this.state.setUser(this.state.user)
	}

	renderText(text: string, focused: boolean)
	{
		return <Text style={focused ? styles.tabTextSelectedStyle : styles.tabTextStyle}>
			{text}
		</Text>;
	}

	errorHandler = (error: Error, stackTrace: string) =>
	{
		console.log(error)
	}

	async loadUserFromStorage()
	{
		console.log('loadUserFromStorage...')

		ServerAPI.initDeviceToken();
		let su = await getData(SESSION_TOKEN);

		if (su)
		{
			try
			{
				let u = JSON.parse(su)

				this.setUser(u)

				ServerAPI.setToken(u.Token)

				u = await ServerAPI.callAPI("/fantasy/getProfile", null, 'GET', false).catch(e =>
				{
					deleteData(SESSION_TOKEN);
					this.setUser({} as User);
					ServerAPI.setToken('')
				})
				this.setUser(u)

			} catch (er: any)
			{
				ServerAPI.track("ErrorLoaded", er.toString())
				console.log(er)
			}
		}
	}

	render()
	{
		if (this.state.onboardVis)
			return <Onboarding closed={(navName: string) => this.onBoardingDone(navName)}/>

		return (<UserContext.Provider value={{
				user: this.state.user,
				setUser: this.setUser,
				setFantasyAppFeatures: this.setFantasyAppFeatures,
				fantasyAppFeatures: this.state.fantasyAppFeatures
		}}>
				 <NavigationContainer theme={GlobalTheme}>

					 {this.state.signUpVisible && this.renderRegister()}

					 {this.state.showAskNotification && this.renderAskNotification()}

					 <Tab.Navigator screenOptions={{
						 headerTitleStyle: {
							 color: 'white',
						 },
						 headerTitleAlign: 'center',
						 headerStyle: {
							 backgroundColor: MAIN_BAR_COLOR,
						 },
						 headerTintColor: 'white',
						 headerShadowVisible: false,
						 headerShown: false,
					 }}>
						 <Tab.Screen name="Feed"
										 component={FantasyNavigation}
										 options={({route}) => ({
											 //https://medium.com/@mspviraj/hide-bottom-tab-bar-on-a-specific-screen-in-react-navigation-6-0-26d31625d339
											 tabBarLabel: ({focused, color}) => this.renderText(TRANSLATES['Feed'], focused),
											 tabBarIcon: () => <Image style={styles.icon} source={require('./icons/home.png')}/>,
											 tabBarStyle: ((route) =>
											 {
												 const routeName = getFocusedRouteNameFromRoute(route) ?? ""

												 if (routeName === 'SelectFighter1' || routeName === 'SelectFighter2')
												 {
													 return {display: "none"}
												 }
												 return
											 })(route),
										 })}
						 />
						 <Tab.Screen name="Profile" component={ProfilePage}

										 options={{
											 tabBarLabel: ({focused, color}) => this.renderText(TRANSLATES['Profile'], focused),
											 tabBarIcon: () => <Image style={styles.icon}
																			  source={require('./icons/profile.png')}/>,
										 }}
						 />
						 <Tab.Screen name="Cards" component={CardNavigation}
										 options={{
											 tabBarLabel: ({focused, color}) => this.renderText(TRANSLATES['Cards'], focused),
											 tabBarIcon: () => <Image style={styles.icon} source={require('./icons/cards.png')}/>,
										 }}
						 />
						 <Tab.Screen name="Shop" component={ShopPage}

										 options={{
											 tabBarLabel: ({focused, color}) => this.renderText(TRANSLATES['Shop'], focused),
											 tabBarIcon: () => <Image style={styles.icon} source={require('./icons/bag.png')}/>,
										 }}
						 />
					 </Tab.Navigator>
				 </NavigationContainer>
			 </UserContext.Provider>
		);
	}
}



import {BattleStatus} from "../types";
import {Text} from "react-native";
import React from "react";
import {MMABattleResponse} from "./types";


export default function FormattedRoundTime({stat}: {stat: MMABattleResponse})
{
    if (stat!!.battle.Status != BattleStatus.InProgress)
        return <></>;

    let roundTimeMillis = stat!!.roundTotalTime;
    let roundLength = stat!!.battle.RoundLength;

    let roundTimeSec = 0,
        roundTimeMin = 0;
    if (roundTimeMillis)
    {
        roundTimeSec = Math.round(roundTimeMillis / 1000);
        roundTimeMin = Math.floor(roundTimeSec / 60);
    }
    let sec = roundTimeMin ? roundTimeSec % (roundTimeMin * 60) : roundTimeSec,
        displayMin = roundTimeMin < 10 ? '0' + roundTimeMin : '' + roundTimeMin,
        displaySec = sec < 10 ? '0' + sec : '' + sec;

    if (roundTimeMin >= roundLength)
    {
        return <Text style={{textAlign: 'center', color: '#2E2D2D'}}>{(roundLength < 10 ? '0' + roundLength : '' + roundLength)}:00</Text>;
    }

    return <Text style={{textAlign: 'center', color: '#2E2D2D'}}>{displayMin}:{displaySec}</Text>
}

import {
    Dimensions,
    Image,
    StyleSheet,
    View,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {ServerAPI} from '../ServerAPI';
import {CardType, Gym, User, UserCard} from '../types';

import {TopUserPanelHeight} from '../UserAppBar';
import {
    get3SubList,
    getData,
    getHeightExpression,
    getSumRating,
    isAnonymous, saveData,
    UserContext
} from '../func';
import ScrollViewRefresh from "../controls/ScrollViewRefresh";
import Card from "./Card";
import TRANSLATES from "../translates/translates";
import UserAppBarOnCardPage from "./UserAppBarOnCardPage";
import MenuButton from "../controls/MenuButton";
import BottomModal from "../controls/BottomModal";
import FButton from "../controls/FButton";
import {MAIN_BAR_COLOR} from "../colors";
import ListCheckBox from "../controls/ListCheckBox";
import GroupCard from "./GroupCard";
import DeleteButton from "../controls/DeleteButton";
import StatsFighterRating from "./StatsFighterRating";
import SignForm from "../auth/SignForm";
import TPressable from "../controls/TPressable";
import {hideBanner} from "../ads";
import BoardStep from "../controls/BoardStep";
import MessageToolTip from "../controls/MessageToolTip";


enum SortType {
    'Date' = 'Date',
    'Rating' = 'Rating',
    'Group' = 'Group'
}

interface IProps {
    navigation?: any;
}

export default function UserCardPage(props: IProps) {

    const [list, setList] = useState<UserCard[]>([]);

    const [refreshing, setRefreshing] = useState(false);
    const [filtersVis, setFiltersVis] = useState(false);
    const [sortType, setSortType] = useState<SortType>(SortType.Date);
    const [childCardsView, setChildCardsView] = useState<UserCard[] | null>(null);
    const [childText, setChildText] = useState<string | null>(null);
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    const [athleteStatVisId, setAthleteStatVisId] = useState<number | null>(null);
    const [signText, setSignText] = useState<string>('');
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [toolTipMsg, setToolTipMsg] = useState('')

    const [gyms, setGyms] = useState<Gym[]>([]);
    const [onboardStep, setOnboardStep] = useState<number | null>(null);

    useEffect(() => {
        refresh();
        ServerAPI.track('Cards')

        props.navigation.addListener('focus', () => {
            ServerAPI.track('Cards')
            hideBanner();

            refresh();
        });

        updateOnBoarding()
    }, [])

    async function updateOnBoarding() {
        let res = await getData('OnBoardingDone_GymButton');

        if (!res)
            setOnboardStep(0)
    }

    function onBoardingDone() {
        setOnboardStep(null)
        saveData('OnBoardingDone_GymButton', 'done')
    }

    async function refresh() {

        if (ServerAPI.hasAuth()) {
            setRefreshing(true);
            let list = await ServerAPI.get<UserCard[]>('/fantasy/getUserCards');
            setList(list)
            setRefreshing(false)

            let gyms = await ServerAPI.get<Gym[]>('/fantasy/getUserGyms');
            setGyms(gyms)


        } else {
            setList([])
            setGyms([])

            let res = await getData('WasLogin');
            if (!res) {
                setIsNewUser(true)
            }
        }
    }

    function onSortChanged(item: SortType) {
        setSortType(item)
    }

    function getSortItems() {
        if (sortType == SortType.Rating) {
            let athleteCards = list.filter(it => it.name == CardType.Athlete && it.athleteRating)
            let nonAthleteCards = list.filter(it => it.name != CardType.Athlete || !it.athleteRating)

            athleteCards.sort((a, b) => b.athleteRating - a.athleteRating)

            athleteCards.push(...nonAthleteCards)

            return athleteCards
        }

        //by date
        return list;

    }

    function renderGroups() {
        if (childCardsView)
            return render3List(get3SubList(childCardsView))


        let countryCards = list.filter(it => it.name == CardType.Country)
        let athleteCards = list.filter(it => it.name == CardType.Athlete || it.name == CardType.Promotion)


        let promotionsGroups: any[] = [];

        athleteCards.forEach(it => {
            let name = ''
            if (it.name == CardType.Promotion) {
                name = it.exDescData
            } else {
                name = it.athletePromotionName || 'Other'
            }

            let group = promotionsGroups.find(f => f.name == name);

            if (!group) {
                group = {name: name, items: [], isPromotion: true}
                promotionsGroups.push(group)
            }

            group.items.push(it)
        })


        //let otherCards = state.list.filter(it => it.name != CardType.Country && it.name != CardType.Athlete && it.name != CardType.Promotion)

        let additional1Cards = list.filter(it => it.exDescData == 'Additional1')
        let additional2 = list.filter(it => it.exDescData == 'Additional2')
        let extraCards = list.filter(it => it.exDescData == 'Extra')


        let groups = [
            ...promotionsGroups,
            {name: TRANSLATES['Country'], items: countryCards},
            {name: TRANSLATES['Additional1'], items: additional1Cards},
            {name: TRANSLATES['Additional2'], items: additional2},
            {name: TRANSLATES['Extra'], items: extraCards},
        ].filter(it => !!it.items.length)


        let groups3 = get3SubList(groups)

        return <>
            {groups3.map((subList, idx) =>
                <View
                    key={idx}
                    style={{
                        flexDirection: 'row',
                        justifyContent: subList.length == 3 ? 'space-between' : 'space-evenly',
                        marginTop: 16
                    }}>
                    {subList.map((group, idx) => <GroupCard key={idx}
                                                            cards={group.items}
                                                            name={group.name}
                                                            onOpen={() => {
                                                                let child = group.items as UserCard[]

                                                                if (group.isPromotion) {
                                                                    let promoCards = child.filter(it => it.name == CardType.Promotion)

                                                                    child = [...promoCards, ...child.filter(it => it.name != CardType.Promotion)
                                                                        .sort((a, b) => {
                                                                            if (!a.athleteRating)
                                                                                return 1;

                                                                            return b.athleteRating - a.athleteRating

                                                                        })]
                                                                }

                                                                setChildCardsView(child)
                                                                setChildText(group.name)
                                                            }}
                        />
                    )}
                </View>)
            }
        </>
    }

    function render3List(cardList: UserCard[][]) {
        return cardList.map((subList, idx) =>
            <View
                key={idx}
                style={{
                    flexDirection: 'row',
                    justifyContent: subList.length == 3 ? 'space-between' : 'space-evenly',
                    marginTop: 16
                }}>
                {subList.map(card => <Card key={card.id + "b"} card={card}
                                           onSelected={() => onAthleteCardOpen(card)}/>)}
            </View>
        )
    }

    function onAthleteCardOpen(userCard: UserCard) {

        switch (userCard.name) {
            case CardType.Athlete:
                setAthleteStatVisId(userCard.exCardData)
                return;
            case CardType.Promotion:
                setToolTipMsg(TRANSLATES['user-card-page.promotion-msg']);
                return;
            case CardType.Country:
                setToolTipMsg(TRANSLATES['user-card-page.country-msg']);
                return;
        }

        switch (userCard.exDescData) {
            case "Additional1":
                setToolTipMsg(TRANSLATES['user-card-page.win-point-msg']);
                break
            case "Additional2":
                setToolTipMsg(TRANSLATES['user-card-page.additional-point-msg']);
                break
            case "Extra":
                setToolTipMsg(TRANSLATES['user-card-page.bonus-point-msg']);
                break
        }

    }

    function renderMainContent(user: User) {
        let sortedCards = getSortItems()
        let cardList = get3SubList(sortedCards);

        return <>
            {!isAnonymous(user) && <View style={{marginLeft: 16, marginBottom: 12, flexDirection: 'row'}}>
                <MenuButton text={TRANSLATES['Sorting']} onClick={() => setFiltersVis(true)}/>
                <View style={{width: 8}}></View>
                {sortType == SortType.Group &&
                    (childCardsView ?
                        <DeleteButton text={childText!!}
                                      onClick={() => {
                                          setChildText(null)
                                          setChildCardsView(null)
                                      }}/> :
                        <DeleteButton text={TRANSLATES['Group']}
                                      onClick={() => {

                                          setSortType(SortType.Date)
                                          setChildText(null)
                                          setChildCardsView(null)
                                      }}/>)
                }
            </View>
            }

            <ScrollViewRefresh style={styles.content}
                               refreshing={refreshing}
                               onRefresh={() => refresh()}>

                {isAnonymous(user) ? renderGifts() :
                    (sortType == SortType.Group ? renderGroups() : render3List(cardList))
                }


                <View style={{height: 30}}/>
            </ScrollViewRefresh>


            {filtersVis && renderSortingDlg()}

            {athleteStatVisId && renderStatsFighterRating()}
        </>
    }


    function renderGifts() {
        if (!isNewUser)
            return;

        let cards = [{id: 1}, {id: 2}, {id: 3}, {id: 4}] as UserCard[]

        return <View
            style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 16
            }}>
            <TPressable onPress={() => setLoginVisible(true)}
                        name="clickGiftCard"
                        style={{position: 'relative', minHeight: 200}}>
                <Image style={[styles.gift, {zIndex: 5, top: 0, left: -6}]} resizeMethod="resize"
                       source={require('../assets/gift3.png')}/>
                {cards.map((it, idx) =>
                    <Card key={idx}
                          style={{
                              position: 'absolute',
                              left: 0,
                              zIndex: cards.length - idx,
                              top: 10 + idx * 10
                          }} card={it}/>)
                }

            </TPressable>
        </View>
    }


    function renderStatsFighterRating() {
        return <StatsFighterRating athleteId={athleteStatVisId!!}
                                   onClose={() => setAthleteStatVisId(null)}/>
    }

    function renderSortingDlg() {
        return <BottomModal visible={true}
                            title={TRANSLATES['Sorting']}
                            height={258}
                            onCloseClick={() => setFiltersVis(false)}>

            <ListCheckBox items={[
                {text: TRANSLATES['Date'], selected: sortType == SortType.Date, id: SortType.Date},
                {text: TRANSLATES['Rating'], selected: sortType == SortType.Rating, id: SortType.Rating},
                {text: TRANSLATES['Group'], selected: sortType == SortType.Group, id: SortType.Group}
            ]} onItemSelected={(item: any) => onSortChanged(item.id)}/>


            <View style={{marginTop: 32}}>
                <FButton margin={0} text={TRANSLATES['Close']} width="100%"
                         onClick={() => setFiltersVis(false)}
                         backgroundColor={MAIN_BAR_COLOR}
                         color="#FFFFFF"/>
            </View>
        </BottomModal>
    }


    let rating = getSumRating(list, gyms.find(it => it.Type == 'general')?.Id)

    return (<UserContext.Consumer>
        {({user, setUser}) => (<View style={styles.root}>


            {onboardStep != null && <View style={{
                width: '100%',
                height: '100%',
                padding: 16,
                position: 'absolute',
                zIndex: 2
            }}>
                <BoardStep onNextClick={() => onBoardingDone()}
                           currentStep={0}
                           position={'bottom'}
                           stepCount={1}
                           text={TRANSLATES['GymOnBoard']}
                           onSkipClick={() => onBoardingDone()}/>
            </View>}

            <UserAppBarOnCardPage
                navigation={props.navigation}
                cardsRating={rating}
                onboardStep={onboardStep}
                titleText={TRANSLATES['YourCollection']}
                titleCards={list.length + " " + TRANSLATES['cards']}
                // onEventSelected={(fId: number) =>
                // {
                //  props.navigation.navigate('fantasyList', {fantasyId: fId})
                // }}
                onGymOpened={() => {
                    ServerAPI.track('GymClick')
                    if (ServerAPI.hasAuth())
                        props.navigation.navigate('userCardGymPage', {gymType: 'general'});
                    else
                        setLoginVisible(true)
                }}
            />

            {toolTipMsg ? <MessageToolTip message={toolTipMsg}
                                          onClose={() => setToolTipMsg('')}/> : <></>}
            {renderMainContent(user)}

            <BottomModal visible={loginVisible}
                         title={signText || TRANSLATES['SignIn']}
                         height={Dimensions.get('window').height - 60}
                         onCloseClick={() => setLoginVisible(false)}

            >
                <SignForm onTitleChanged={(text: string) => setSignText(text)}
                          doneCallback={() => {
                              refresh();
                              setLoginVisible(false)
                          }}/>
            </BottomModal>

        </View>)}
    </UserContext.Consumer>);


}

const styles = StyleSheet.create({
    modalContent: {
        borderTopRightRadius: 15,
        borderTopLeftRadius: 15,
        marginTop: 'auto'
    },
    closeBtn: {
        position: 'absolute',
        top: 6,
        width: 30,
        height: 30
    },
    titlePadding: {
        paddingRight: 16,
        marginTop: 16,
        paddingLeft: 16
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleText: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        flex: 1,
        justifyContent: 'center',
    },
    root: {
        height: '100%'
    },
    content: {
        backgroundColor: '#EFF1F7',
        paddingLeft: 16,
        paddingRight: 16,
        height: getHeightExpression(TopUserPanelHeight),
    },
    packageItem: {
        marginTop: 12,
        backgroundColor: '#f9c2ff',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 50,
    },
    textStyle: {
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15
    },
    gift: {
        width: 111,
        height: 140
    },
    giftShadow: {
        elevation: 3,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    } as any
});

import {SafeAreaView, Text, StyleSheet, View, ScrollView, Dimensions} from 'react-native';

import React from 'react';
import {DistributionPoint, FantasyInfo, FantasyRateRow, Fighter, UserCard} from '../types';
import TRANSLATES from '../translates/translates';
import FButton from '../controls/FButton';
import CheckBox from './CheckBox';
import {MAIN_BAR_COLOR} from '../colors';
import FighterAppBar from './FighterAppBar';
import {getData, getHeightExpression, saveData} from '../func';
import StepProgress from '../controls/StepProgress';
import {ServerAPI} from "../ServerAPI";
import BoardStep from "../controls/BoardStep";
import {CardList} from "../shop/CardShop";


interface IProps {
	athlete: Fighter;
	fantasy: FantasyInfo;
	fantasyRateRow: FantasyRateRow;
	onBackClick: Function
	onNextStepClick: Function
	userCards: UserCard[]
}

interface IState {
	fantasyRateRow: FantasyRateRow,
	allPoints: DistributionPoint[];
	athlete: Fighter,
	fantasy: FantasyInfo,
	userCards: UserCard[],
	onboardStep: number | null
}


const ONBoardSteps = [
	'SelectFighter3_OnBoard1',
	'SelectFighter3_OnBoard2',
	'SelectFighter3_OnBoard3',
]


export default class SelectFighter3 extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			onboardStep: null,
			fantasyRateRow: props.fantasyRateRow,
			athlete: props.athlete,
			fantasy: props.fantasy,
			allPoints: [],
			userCards: props.userCards,
		};
	}

	getOnBoardSteps()
	{
		return ONBoardSteps;
	}

	onBoardNextClick()
	{
		if (this.state.onboardStep!! + 1 == this.getOnBoardSteps().length)
			this.doneOnboarding()
		else
			this.setState({onboardStep: this.state.onboardStep!! + 1})
	}

	onBoardSkipClick()
	{
		this.doneOnboarding()
	}

	doneOnboarding()
	{
		this.setState({onboardStep: null})

		saveData('OnBoardingDone_SelectFighter3', 'done')
	}

	componentDidMount()
	{
		this.refresh();
		this.updateOnBoarding()
	}

	async updateOnBoarding()
	{
		let res = await getData('OnBoardingDone_SelectFighter3');

		if (!res)
			this.setState({
				onboardStep: 0
			})
	}

	async refresh()
	{
		let allPoints = await ServerAPI.getDistributionPoints();
		this.setState({
			allPoints: allPoints
		});
	}


	onCardSelected(userCard: UserCard)
	{
		let isSelected = !!this.state.fantasyRateRow.SelectedUserCards?.find(it => it == userCard.id);
		if (!isSelected)
		{
			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					SelectedUserCards: [...this.state.fantasyRateRow.SelectedUserCards, userCard.id],
				},
			});
		}
		else
		{
			this.setState({
				fantasyRateRow: {
					...this.state.fantasyRateRow,
					SelectedUserCards: this.state.fantasyRateRow.SelectedUserCards.filter(it => it != userCard.id),
				},
			});
		}
	}

	onNextClick()
	{
		this.props.onNextStepClick(this.state.fantasyRateRow);
	}

	onBackClick()
	{
		this.props.onBackClick();
	}

	renderExtraCheckBox(p: DistributionPoint)
	{
		//let card = this.state.userCards.find(it => it.fantasyPointId == p.Key)!!;
		return <CheckBox
			 key={p.Id}
			 //selected={card && !!this.state.fantasyRateRow.SelectedUserCards.find(it => it == card.id)}
			 cardCoefText={this.state.userCards.find(it => it.fantasyPointId == p.Key)?.statusCoef}
			 enabled={false}
			 points={p.Points}
			 mainText={TRANSLATES[p.Id] || p.Name}
			 tooltip={TRANSLATES[`${p.Id}.desc`] || p.Description}
		/>
	}

	render()
	{
		let extraList = this.state.allPoints.filter(it => (it.Type == 'Extra' || it.Type == 'Base') && it.Points > 0);
		let penaltyList = this.state.allPoints.filter(it => it.Points < 0);

		let userCards = this.state.userCards || [];

		let boardSteps = this.getOnBoardSteps()

		return (
			 <SafeAreaView style={styles.root}>


				 {this.state.onboardStep != null && <View style={{
					 width: '100%',
					 height: '100%',
					 padding: 16,
					 position: 'absolute',
					 zIndex: 2
				 }}>
				 <BoardStep onNextClick={() => this.onBoardNextClick()}
							currentStep={this.state.onboardStep}
							position={this.state.onboardStep > 0 && Dimensions.get('window').height < 700 ? 'top' : 'bottom'}
							stepCount={boardSteps.length}
							text={(TRANSLATES as any)[boardSteps[this.state.onboardStep]]}
							onSkipClick={() => this.onBoardSkipClick()}/>

			 </View>}


				 <ScrollView style={styles.container}>

					 <Text style={styles.chooseText}>
						 {TRANSLATES['Step3Title1']}
					 </Text>

					 {extraList.map(p => this.renderExtraCheckBox(p))}

					 <Text style={styles.subText}>
						 {TRANSLATES['Step3Desc1']}
					 </Text>

					 <Text style={styles.chooseText}>
						 {TRANSLATES['Step3Title2']}
					 </Text>

					 {penaltyList.map(p => <CheckBox
						  key={p.Id}
						  enabled={false}
						  cardCoefText={this.state.userCards.find(it => it.fantasyPointId == p.Key)?.statusCoef}
						  points={p.Points}
						  tooltip={TRANSLATES[`${p.Id}.desc`] || p.Description}
						  mainText={TRANSLATES[p.Id] || p.Name}
					 />)}

					 <Text style={styles.subText}>
						 {TRANSLATES['Step3Desc2']}
					 </Text>


					 <CardList
						  showText={true}
						  showShop={true}
						  onCardListChanged={() => this.refresh()}
						  selectedList={userCards.filter(it => this.state.fantasyRateRow.SelectedUserCards.find(id => it.id == id))}
						  onCardSelected={(uc: UserCard) => this.onCardSelected(uc)}
						  list={this.state.userCards.filter(it => extraList.find(eit => it.fantasyPointId == eit.Key))}/>


				 </ScrollView>

				 <View style={styles.buttons}>

					 <FButton text={TRANSLATES['Back']} width={160} onClick={() => this.onBackClick()}/>
					 <FButton text={TRANSLATES['Next']} width={160} onClick={() => this.onNextClick()}
								 backgroundColor={MAIN_BAR_COLOR}
								 color="#FFFFFF"/>
				 </View>

			 </SafeAreaView>);
	}
}

const styles = StyleSheet.create({
	subText: {
		marginTop: 16,
		textAlign: 'center',
		color: '#797B87',
		paddingLeft: 16,
		paddingRight: 16,
		marginBottom: 24,
	},
	chooseText: {
		fontWeight: '700',
		fontSize: 15,
		color: '#2E2D2D',
		marginBottom: 16,
	},
	container: {
		backgroundColor: '#EFF1F7',
		paddingLeft: 16,
		paddingRight: 16
	},
	root: {
		height: '100%'
	},
	buttons: {
		marginTop: 'auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 16
	},
});

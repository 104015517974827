import {SafeAreaView, Text, StyleSheet, View, FlatList, Dimensions} from 'react-native';
import React from 'react';
import {ServerAPI} from '../ServerAPI';
import TRANSLATES from '../translates/translates';
import {MAIN_BAR_COLOR} from "../colors";
import {getDateString2} from "../func";
import TPressable from "../controls/TPressable";


interface CoinTransaction {
	fantasyId: number,
	description: string,
	date: string,
	value: number,
	eventName: string
}


interface IProps {
	onEventSelected: Function
}

interface IState {
	list: CoinTransaction[]
}


export default class CoinUserHistory extends React.Component<IProps, IState> {

	nameWidth: number = 0;

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			list: []
		};
	}


	componentDidMount()
	{
		this.refresh();

	}

	async refresh()
	{
		let list = await ServerAPI.get<CoinTransaction[]>(`/fantasy/getHistoryOfUser`);
		this.setState({
			list: list
		});
	}

	renderRow(ur: CoinTransaction)
	{
		return <View style={[styles.row]}>
			<Text style={styles.dateText}>{getDateString2(ur.date)}</Text>
			<TPressable name={"openEventFromCoinUserHistory"} onPress={() => this.props.onEventSelected && this.props.onEventSelected(ur.fantasyId)}>
				{ur.description == 'fantasy-game' ?
					 <Text style={[styles.nameText, {color: MAIN_BAR_COLOR}]}>{ur.eventName}</Text> :
					 <Text style={styles.nameText}>{(TRANSLATES as any)['coin.' + ur.description]}</Text>
				}
			</TPressable>
			<Text style={[styles.placeText]}>{ur.value}</Text>
		</View>
	}

	renderHeader()
	{
		return <View style={[styles.row]}>
			<Text style={styles.dateText}>{TRANSLATES['Date']}</Text>
			<Text style={[styles.nameText, {color: '#2E2D2D'}]}>{TRANSLATES['EventName']}</Text>
			<Text style={styles.placeText}>{TRANSLATES['Coins']}</Text>
		</View>
	}


	render()
	{
		return (<SafeAreaView style={styles.root}>
			{this.renderHeader()}
			<FlatList
				 data={this.state.list}
				 style={{height: 460, width: '100%'}}
				 renderItem={({item}) => <View style={styles.item}>
					 {this.renderRow(item)}
				 </View>}
			/>
		</SafeAreaView>);
	}
}


const styles = StyleSheet.create({
	dateText: {
		fontWeight: '700',
		fontSize: 12,
		color: '#2E2D2D',
		width: 128,
	},
	nameText: {
		width: Dimensions.get('window').width - 64 - 64 - 69,
		fontWeight: '700',
		fontSize: 12,
		color: '#000'
	},
	placeText: {
		textAlign: 'center',
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 12,
		width: 64,
	},
	ptsText: {
		color: '#2E2D2D',
		fontSize: 12,
		fontWeight: '700',
		width: 64,
		textAlign: 'center',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: 48,
		width: '100%'
	},
	selectedRow: {
		backgroundColor: '#EFF1F7'
	},
	root: {
		marginLeft: 12
	},
	item: {
		marginTop: 12,
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		height: 50,
	},
});

import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {COLORS, MAIN_BAR_COLOR} from '../colors';
import TPressable from "../controls/TPressable";

export interface TabItem {
    value: any,
    text: string,
    width?: string | number,
    count?: number | undefined
}

interface IProps {
    value: any,
    onTabSelected: Function,
    items: TabItem[],
    style?: any
    selectedTabColor?: string
}


export default class Tabs extends React.Component<IProps, any> {

    constructor(props: any) {
        super(props);
    }


    render() {

        return <View style={[styles.root, this.props.style]}>
            {this.props.items.map((it, idx) =>
                <TPressable
                    name={it.value}
                    key={idx}
                    onPress={() => this.props.onTabSelected(it.value)}
                    style={[styles.tab, it.value == this.props.value ? {
                        backgroundColor: this.props.selectedTabColor || styles.selectedTab.backgroundColor,
                        width: it.width
                    } : null, {width: it.width}]}>
                    <Text style={styles.textTab}>
                        {it.text}
                    </Text>
                    {it.count != undefined && <View style={styles.count}>
                        <Text style={styles.countText}>
                            {it.count}
                        </Text>
                    </View>}
                </TPressable>)
            }
        </View>
    }
}


export const styles = StyleSheet.create({
    root: {
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    count: {
        borderRadius: 10,
        width: 16,
        height: 16,
        position: 'absolute',
        top: -4,
        right: -4,
        backgroundColor: COLORS.SECONDARY_ACCESS_COLOR,
        alignItems: 'center',
        justifyContent: 'center'
    },
    countText: {
        color: '#fff',
        fontSize: 10,
        fontWeight: '700'
    },
    tab: {
        position: 'relative',
        backgroundColor: '#CACDDE',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 20,
        width: 104,
        alignItems: 'center',
        justifyContent: "center"
    },
    selectedTab: {
        backgroundColor: MAIN_BAR_COLOR,
    },
    textTab: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 12
    }
});

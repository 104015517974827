import {Text, View, StyleSheet, Pressable} from 'react-native';
import React from 'react';
import {ServerAPI, getDeviceInfo} from '../ServerAPI';

import {RegisteringUser, User} from '../types';
import SInput from "../controls/SInput";
import FButton from "../controls/FButton";
import {MAIN_BAR_COLOR} from "../colors";
import TRANSLATES, {getCurrentLang} from "../translates/translates";
import {saveData, SESSION_TOKEN, UserContext} from "../func";
import TPressable from "../controls/TPressable";
import CircularProgress from "../controls/CircularProgress";


interface IProps {
	doneCallback: Function;
	onTitleChanged?: Function,
	isSignIn?: boolean,
	currentEmail?: string,
	isSignInVis?: boolean,
	currentEmptyEmail?: string
	onFocus?: Function,
	onTypeChanged?: Function
}

interface IState {
	name: string,
	user: string,
	pass: string,
	errorText: string,
	isSignIn: boolean
	currentEmail: string | undefined,
	updatePassVisible: boolean,
	restoreVisible: boolean,
	codeRestoreVisible: boolean,
	restoreEmail: string,
	restoreCode: string,
	loading: boolean,
	newPass: string,
	confirmNewPass: string
}

export default class SignForm extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			name: '',
			user: '',
			pass: '',
			errorText: '',
			currentEmail: props.currentEmail,
			isSignIn: props.isSignIn === undefined ? true : props.isSignIn,
			restoreVisible: false,
			codeRestoreVisible: false,
			updatePassVisible: false,
			restoreEmail: '',
			restoreCode: '',
			loading: false,
			newPass: '',
			confirmNewPass: ''
		};
	}

	async login(setUser: Function)
	{
		let user = await ServerAPI.post<User>('/auth/login', {
			'user': this.state.user,
			'pass': this.state.pass,
		}) as any;

		if (user)
		{
			this.setState({
				errorText: '',
			});
			setUser(user);

			saveData(SESSION_TOKEN, JSON.stringify(user));
			saveData('WasLogin', 'true')

			this.props.doneCallback(user);
		}
		else
		{
			this.setState({
				errorText: 'User or password are incorrect',
			});
		}
	}

	isEmail(v: string)
	{
		if (!v)
			return false;
		let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
		return email.test(v.trim());
	}

	async register(setUser: Function)
	{
		if (!this.isEmail(this.state.user))
			return this.setState({errorText: 'Incorrect email address'})


		this.setState({errorText: ''})

		let user = await ServerAPI.post<User>('/auth/register', {
			currentEmail: this.state.currentEmail,
			email: this.state.user,
			firstName: this.state.name,
			password: this.state.pass,
			device: getDeviceInfo(),
			tz: new Date().getTimezoneOffset() / 60,
			lang: getCurrentLang()
		} as RegisteringUser) as any;

		if (user)
		{
			ServerAPI.track("SignUp")

			this.setState({
				errorText: '',
			});
			setUser(user);

			saveData(SESSION_TOKEN, JSON.stringify(user));
			saveData('WasLogin', 'true')

			this.props.doneCallback(user);
		}
		else
		{
			this.setState({
				errorText: TRANSLATES['UserAlreadyExistsException'],
			});
		}
	}

	async onSendEmailRestorePass()
	{
		this.setState({loading: true})

		let res = await ServerAPI.post<{ error: string }>('/restore-password', {email: this.state.restoreEmail});

		if (res.error)
			this.setState({errorText: res.error, loading: false})
		else
			this.setState({codeRestoreVisible: true, restoreVisible: false, loading: false, errorText: ''})
	}

	async onSubmitCodeRestorePassClick()
	{
		this.setState({loading: true})
		let res = await ServerAPI.post<{ error: string }>('/restore-password/confirm', {
			email: this.state.restoreEmail,
			code: this.state.restoreCode
		});

		if (res.error)
			this.setState({errorText: res.error, loading: false})
		else
			this.setState({codeRestoreVisible: false, restoreVisible: false, updatePassVisible: true, loading: false, errorText: ''})
	}

	async onUpdatePasswordClick(setUser: Function)
	{
		if (this.state.newPass !== this.state.confirmNewPass)
			return this.setState({errorText: TRANSLATES['PasswordsDontMatch']})

		this.setState({errorText: '', loading: false})

		let user = await ServerAPI.post< User>('/update-password', {
			email: this.state.restoreEmail,
			code: this.state.restoreCode,
			newPass: this.state.newPass,
			userAgent: getDeviceInfo
		});

		setUser(user)

		saveData(SESSION_TOKEN, JSON.stringify(user));
		saveData('WasLogin', 'true')
		this.props.doneCallback(user);
	}

	onRestoreCancelClick()
	{
		if (this.props.onTitleChanged)
			this.props.onTitleChanged(TRANSLATES['SignIn'])
		this.setState({
			restoreVisible: false,
			codeRestoreVisible: false,
			updatePassVisible: false,
			errorText: '',
			loading: false,
			confirmNewPass: '',
			newPass: '',
			restoreCode: '',
			restoreEmail: ''
		})
	}

	onRestoreClick()
	{
		if (this.props.onTitleChanged)
			this.props.onTitleChanged(TRANSLATES['ForgotPassword'])
		this.setState({restoreVisible: true})
	}

	renderSignIn(setUser: Function)
	{

		if (this.state.loading)
			return <CircularProgress/>

		if (this.state.codeRestoreVisible)
			return this.renderSubmitCode(setUser);

		if (this.state.restoreVisible)
			return this.renderRestorePass();

		if (this.state.updatePassVisible)
			return this.renderUpdatePass(setUser);

		return <>
			<SInput value={this.state.user}
					  placeHolder={"Email"}
					  autofocus={true}
					  onFocus={(focus: boolean) => this.props.onFocus && this.props.onFocus(focus)}
					  style={{width: '100%'}}
					  onChangeText={(e: string) => this.setState({user: e})}/>


			<SInput value={this.state.pass}
					  style={{marginTop: 16, width: '100%'}}
					  placeHolder={"Password"}
					  onFocus={(focus: boolean) => this.props.onFocus && this.props.onFocus(focus)}
					  isPassword={true}
					  onChangeText={(e: string) => this.setState({pass: e})}/>

			<TPressable name="ForgotPassword" onPress={() => this.onRestoreClick()}>
				<Text style={[styles.forgotLink, styles.linkStyle, styles.signUpLink]}>{TRANSLATES['ForgotPassword']}</Text>
			</TPressable>

			<View style={{marginTop: 32, width: '100%'}}>
				<FButton
					 width={'100%'}
					 backgroundColor={MAIN_BAR_COLOR}
					 color="#FFFFFF"
					 margin={0}
					 text={TRANSLATES['LoginIn']} onClick={() => this.login(setUser)}/>

			</View>

			<Text style={{color: 'red'}}>{this.state.errorText}</Text>


			<View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 24}}>
				<Text style={styles.text}>{TRANSLATES['HAccount']}</Text>
				<TPressable name="SignUp" onPress={() =>
				{
					this.setState({isSignIn: false})
					if (this.props.onTitleChanged)
						this.props.onTitleChanged(TRANSLATES['SignUp'])
				}}>
					<Text style={[styles.linkStyle, styles.signUpLink]}>{TRANSLATES['SignUp']}</Text>
				</TPressable>
			</View>
		</>
	}

	renderSubmitCode(setUser: Function)
	{
		return <>

			<Text style={styles.text}>{TRANSLATES['CheckEmailCode']}</Text>


			<View style={{marginTop: 16, width: '100%'}}>
				<SInput value={this.state.restoreCode}
						placeHolder={"Code"}
						autofocus={true}
						style={{width: '100%'}}
						onChangeText={(e: string) => this.setState({restoreCode: e})}/>

				<Text style={{color: 'red'}}>{this.state.errorText}</Text>

            </View>
            <View style={{marginTop: 16, width: '100%'}}>
				<FButton
					width={'100%'}
					backgroundColor={MAIN_BAR_COLOR}
					color="#FFFFFF"
					margin={0}
					text={TRANSLATES['Submit']} onClick={() => this.onSubmitCodeRestorePassClick()}/>

			</View>

			<View style={{marginTop: 16, width: '100%'}}>
				<FButton
					width={'100%'}
					margin={0}
					text={TRANSLATES['Cancel']} onClick={() => this.onRestoreCancelClick()}/>

			</View>
		</>
	}

	renderRestorePass()
	{
		return <>
			<SInput value={this.state.restoreEmail}
					placeHolder={"Email"}
					autofocus={true}
					style={{width: '100%'}}
					onChangeText={(e: string) => this.setState({restoreEmail: e})}/>


			<View style={{marginTop: 16, width: '100%'}}>
				<FButton
					width={'100%'}
					backgroundColor={MAIN_BAR_COLOR}
					color="#FFFFFF"
					margin={0}
					text={TRANSLATES['Submit']} onClick={() => this.onSendEmailRestorePass()}/>

			</View>

			<View style={{marginTop: 16, width: '100%'}}>
				<FButton
					width={'100%'}
					margin={0}
					text={TRANSLATES['Cancel']} onClick={() => this.onRestoreCancelClick()}/>

			</View>

			<Text style={{color: 'red'}}>{this.state.errorText}</Text>

		</>
	}

	renderUpdatePass(setUser: Function)
	{
		return <>
			<SInput value={this.state.newPass}
					placeHolder={TRANSLATES["NewPassword"]}
					isPassword={true}
					autofocus={true}
					style={{width: '100%'}}
					onChangeText={(e: string) => this.setState({newPass: e})}/>

			<View style={{marginTop: 16, width: '100%'}}>
                <SInput value={this.state.confirmNewPass}
                        placeHolder={TRANSLATES["ConfirmNewPassword"]}
                        isPassword={true}
                        style={{width: '100%'}}
                        onChangeText={(e: string) => this.setState({confirmNewPass: e})}/>
			</View>

			<View style={{marginTop: 16, width: '100%'}}>
				<FButton
					width={'100%'}
					backgroundColor={MAIN_BAR_COLOR}
					color="#FFFFFF"
					margin={0}
					text={TRANSLATES['Submit']} onClick={() => this.onUpdatePasswordClick(setUser)}/>

			</View>


			<Text style={{color: 'red'}}>{this.state.errorText}</Text>

		</>
	}

	renderSignUp(setUser: Function)
	{
		return <>

			<SInput value={this.state.name}
					  placeHolder={"Name"}
					  autofocus={true}
					  onFocus={(focus: boolean) => this.props.onFocus && this.props.onFocus(focus)}
					  style={{width: '100%'}}
					  onChangeText={(e: string) => this.setState({name: e})}/>

			<SInput value={this.state.user}
					  placeHolder={"Email"}
					  onFocus={(focus: boolean) => this.props.onFocus && this.props.onFocus(focus)}
					  style={{marginTop: 16, width: '100%'}}
					  onChangeText={(e: string) => this.setState({user: e})}/>


			<SInput value={this.state.pass}
					  style={{marginTop: 16, width: '100%'}}
					  placeHolder={"Password"}
					  onFocus={(focus: boolean) => this.props.onFocus && this.props.onFocus(focus)}
					  isPassword={true}
					  onChangeText={(e: string) => this.setState({pass: e})}/>

			<View style={{marginTop: 24}}>
				<Text style={styles.text}>
					{TRANSLATES['SignUpText1']}
					<Text style={styles.linkStyle}>{TRANSLATES['TermsAndCond']}</Text>
					{TRANSLATES['And']}
					<Text style={styles.linkStyle}>{TRANSLATES['PrivacyPolicy']}</Text>
				</Text>


			</View>

			<View style={{marginTop: 32, width: '100%'}}>
				<FButton
					 width={'100%'}
					 backgroundColor={MAIN_BAR_COLOR}
					 color="#FFFFFF"
					 margin={0}
					 text={TRANSLATES['CreateAccount']} onClick={() => this.register(setUser)}/>

			</View>

			<Text style={{color: 'red'}}>{this.state.errorText}</Text>


			{this.props.isSignInVis !== false &&
			 <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 24}}>
				 <Text style={styles.text}>{TRANSLATES['HAccount2']}</Text>
				 <Pressable onPress={() =>
					  {
						  this.setState({isSignIn: true, errorText: ''})
						  if (this.props.onTitleChanged)
							  this.props.onTitleChanged(TRANSLATES['SignIn'])
					  }}>
					 <Text style={[styles.linkStyle, styles.signUpLink]}>{TRANSLATES['SignIn']}</Text>
				 </Pressable>
			 </View>
			}
		</>
	}


	render()
	{
		return (
			 <UserContext.Consumer>
				 {({user, setUser}) => (
					  <View style={styles.container}>


						  <View style={styles.inputСontainer}>

							  {this.state.isSignIn ? this.renderSignIn(setUser)
									:
									this.renderSignUp(setUser)
							  }


						  </View>
					  </View>
				 )}
			 </UserContext.Consumer>
		);
	}
}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		padding: 16
	},
	text: {
		color: '#2E2D2D',
		fontSize: 12,
		fontWeight: '500',
	},
	inputСontainer: {
		marginTop: 24,
		width: '100%',
		alignItems: 'center',
		flex: 1,
	},
	forgotLink: {
		marginTop: 8,
		textAlign: 'right',
	},
	signUpLink: {
		marginLeft: 8,
		textAlign: 'right',
	},
	linkStyle: {
		color: '#405CBF',
		fontSize: 12,
		fontWeight: '500',
		textDecorationLine: 'underline'
	},
	input: {
		color: 'black',
		width: '80%',
		height: 40,
		borderWidth: 1,
		padding: 10,
	},
});

import {StyleSheet, Text, View} from "react-native";
import TRANSLATES from "../../translates/translates";
import React from "react";
import {COLORS} from "../../colors";
import {SubmissionStat, TCSStat} from "./compare-types";
import ManIcon2 from "./ManIcon2";


export default function SubAttemptZone(props: {
    landedColor: string,
    totalColor: string,
    zoneStat: SubmissionStat
}) {


    const renderZoneStat = (stat: TCSStat) => {
        return <>
            <Text style={[styles.valueText, {color: props.totalColor}]}>
                {TRANSLATES['compare-stat.punches-stat.attempts']}
                {"\n"}
                {stat.total}
            </Text>
            <Text style={[styles.valueText, {color: props.landedColor}]}>
                {TRANSLATES['compare-stat.punches-stat.successful']}
                {"\n"}
                {stat.success}
            </Text>
        </>
    }

    return <View style={{alignItems: 'center', position: 'relative'}}>
        <ManIcon2 color={props.totalColor}/>

        <View style={{position: 'absolute', top: 5, right: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.zone.chokehold']}
            </Text>
            {renderZoneStat(props.zoneStat.P1)}
        </View>

        <View style={{position: 'absolute', top: 110, right: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.zone.left-arm']}
            </Text>
            {renderZoneStat(props.zoneStat.P4)}
        </View>

        <View style={{position: 'absolute', top: 110, left: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.zone.right-arm']}
            </Text>
            {renderZoneStat(props.zoneStat.P3)}
        </View>

        <View style={{position: 'absolute', top: 222, right: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.zone.left-leg']}
            </Text>
            {renderZoneStat(props.zoneStat.P6)}
        </View>

        <View style={{position: 'absolute', top: 222, left: 22}}>
            <Text style={styles.zonNameText}>
                {TRANSLATES['compare-stat.zone.right-leg']}
            </Text>
            {renderZoneStat(props.zoneStat.P5)}
        </View>

    </View>
}


const styles = StyleSheet.create({
    zonNameText: {
        fontSize: 12,
        fontWeight: '700',
        color: COLORS.BLACK,
        textAlign: 'center',
    },
    valueText: {
        marginTop: 8,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '500',
    }
})

import {Dimensions, Text, View} from "react-native";
import TRANSLATES from "../../translates/translates";
import React from "react";
import {PunchesStat} from "./compare-types";
import {round} from "../../func";
import {COLORS} from "../../colors";
import BarChart from "./BarChart";

interface IProps {
    punchesStat: PunchesStat,
    landedColor: string,
    totalColor: string
}

export default function PunchesChart(props: IProps) {


    let stat = props.punchesStat


    let screenWidth = Dimensions.get('window').width

    let arr = [
        {name: TRANSLATES['compare-stat.punches-stat.critical'], value: stat.critical, color: COLORS.BLACK, percent: 0},
        {name: TRANSLATES['compare-stat.punches-stat.landed'], value: stat.hit, color: props.landedColor, percent: 0},
        {name: TRANSLATES['compare-stat.punches-stat.total'], value: stat.total, color: props.totalColor, percent: 0}
    ]


    return <View>
        <BarChart sortedValues={arr} width={screenWidth} leftAxisMargin={10}/>
        <View style={{width: '100%', alignItems: 'center', marginTop: 16}}>
            <Text style={{fontSize: 12, color: COLORS.BLACK, fontWeight: '500'}}>
                {TRANSLATES['compare-stat.punches-stat.punches-efficiency']}
            </Text>

            <View style={{flexDirection: 'row', marginTop: 8, width: 110, justifyContent: 'space-around'}}>
                <Text style={{color: props.landedColor, fontSize: 12, fontWeight: '500', textAlign: 'center'}}>
                    {TRANSLATES['compare-stat.punches-stat.landed']}
                    {"\n"}
                    {stat.total > 0 ? round(100 * stat.hit / stat.total) : 0}%
                </Text>
                <Text style={{color: COLORS.BLACK, fontSize: 12, fontWeight: '500', textAlign: 'center'}}>
                    {TRANSLATES['compare-stat.punches-stat.critical']}
                    {"\n"}
                    {stat.total > 0 ? round(100 * stat.critical / stat.total) : 0}%
                </Text>
            </View>
        </View>
    </View>
}

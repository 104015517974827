import {SafeAreaView, Text, StyleSheet, View, FlatList, Dimensions, Pressable} from 'react-native';
import React from 'react';
import {ServerAPI} from '../ServerAPI';
import TRANSLATES from '../translates/translates';
import {MAIN_BAR_COLOR} from "../colors";


interface PTSHistoryItem {
	fantasyId: number,
	eventName: string,
	date: string,
	place: number,
	pts: number
}


interface IProps {
	onEventSelected: Function
}

interface IState {
	list: PTSHistoryItem[]
}


export default class PTSUserHistory extends React.Component<IProps, IState> {

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			list: []
		};
	}


	componentDidMount()
	{
		this.refresh();
	}

	async refresh()
	{
		let list = await ServerAPI.get<PTSHistoryItem[]>(`/fantasy/getPTSHistoryOfUser`);
		this.setState({
			list: list
		});
	}

	renderRow(ur: PTSHistoryItem)
	{
		return <View style={[styles.row]}>
			<Text style={styles.dateText}>{ur.date}</Text>
			<Pressable onPress={() => this.props.onEventSelected && this.props.onEventSelected(ur.fantasyId)}>
				<Text style={styles.nameText}>
					{ur.eventName}
				</Text>
			</Pressable>
			<Text style={[styles.placeText]}>{ur.place}</Text>
			<Text style={[styles.ptsText]}>{ur.pts}</Text>
		</View>;
	}

	renderHeader()
	{
		return <View style={[styles.row]}>
			<Text style={styles.dateText}>{TRANSLATES['Date']}</Text>
			<Text style={[styles.nameText, {color: '#2E2D2D'}]}>{TRANSLATES['EventName']}</Text>
			<Text style={styles.placeText}>{TRANSLATES['Place']}</Text>
			<Text style={styles.ptsText}>{TRANSLATES['PTS']}</Text>
		</View>
	}


	render()
	{
		return (<SafeAreaView style={styles.root}>
			{this.renderHeader()}
			<FlatList
				 data={this.state.list}
				 style={{height: 460, width: '100%'}}
				 renderItem={({item}) => <View style={styles.item}>
					 {this.renderRow(item)}
				 </View>}
			/>
		</SafeAreaView>);
	}
}


const styles = StyleSheet.create({
	dateText: {
		fontWeight: '700',
		fontSize: 12,
		color: '#2E2D2D',
		width: 69 + 12,
	},
	nameText: {
		color: MAIN_BAR_COLOR,
		fontWeight: '700',
		width: Dimensions.get('window').width - 64 - 64 - 12 - 69,
		fontSize: 12,
	},
	placeText: {
		textAlign: 'center',
		color: '#2E2D2D',
		fontWeight: '700',
		fontSize: 12,
		width: 64,
	},
	ptsText: {
		color: '#2E2D2D',
		fontSize: 12,
		fontWeight: '700',
		width: 64,
		textAlign: 'center',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: 48,
		width: '100%'
	},
	selectedRow: {
		backgroundColor: '#EFF1F7'
	},
	root: {
		marginLeft: 12
	},
	item: {
		marginTop: 12,
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		height: 50,
	},
});

import React from 'react';
import {GestureResponderEvent, Pressable} from 'react-native';
import MessageToolTip from "./MessageToolTip";


interface IProps {
    text?: string,
    children?: React.ReactNode,
    style?: any
}

interface IState {
    modalVisible: boolean,
    y: number,
    x: number
}

export default class ToolTipBox extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            modalVisible: false,
            x: 0,
            y: 0
        };
    }

    onShowToolTipClick(e: GestureResponderEvent) {
        this.setState({modalVisible: true, x: e.nativeEvent.pageX, y: e.nativeEvent.pageY})
    }

    render() {

        return <Pressable
            style={this.props.style}
            onPress={(e) => this.onShowToolTipClick(e)}>
            {this.props.children}
            {this.state.modalVisible &&
                <MessageToolTip message={this.props.text}
                                onClose={() => this.setState({modalVisible: false})}>
                </MessageToolTip>
            }
        </Pressable>
    }
}
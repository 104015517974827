import {Modal} from 'react-native';


const CModal = (props: { visible: boolean, children: any }) => {
    return <Modal
        animationType="slide"
        transparent={true}
        visible={props.visible}
    >
        {props.children}
    </Modal>
}

export default CModal

import {SafeAreaView, Text, StyleSheet, View, FlatList, Dimensions} from 'react-native';
import React from 'react';
import {ServerAPI} from '../ServerAPI';
import {User, UserRating} from '../types';
import TRANSLATES from '../translates/translates';
import {UserContext} from "../func";
import {MAIN_BAR_COLOR} from "../colors";
import StatusProgress from "../profile/StatusProgress";


interface IProps {

}

interface IState {
    list: UserRating[]
}


export default class PTSRatingUsers extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            list: []
        };
    }


    componentDidMount() {
        this.refresh();
    }

    async refresh() {
        let list = await ServerAPI.get<UserRating[]>(`/fantasy/getPTSUsersRating`);
        this.setState({
            list: list
        });
    }

    renderRow(ur: UserRating, user: User) {
        //PTS берется из того какое он место занял в свой 20ке
        //Coins сколько он набрал в самой игре
        return <View style={[styles.row, ur.Id == user.Id ? styles.selectedRow : {}]}>
            <Text style={[styles.placeText, {marginLeft: 16}]}>{ur.Place}</Text>
            <Text style={styles.nameText}>{ur.Name}</Text>
            <Text style={styles.pointText}>{ur.PTS}</Text>
        </View>;
    }

    renderHeader() {
        return <><View style={[styles.row, {marginLeft: 16}]}>
            <Text style={[styles.placeText, styles.placeHeader]}>{TRANSLATES['Rank']}</Text>
            <Text style={styles.nameText}>{TRANSLATES['Player']}</Text>
            <Text style={styles.pointText}>{TRANSLATES['PTS']}</Text>
        </View>
            <View style={styles.line}/>
        </>;
    }


    render() {
        return (<UserContext.Consumer>
                {({user, setUser}) => (<SafeAreaView style={styles.container}>
                        {user &&
                            <StatusProgress nextStep={user.nextLevelPTS || 500} current={user.PTS || 0}
                                            prevStep={user.prevLevelPTS || 0}
                                            rightText={(TRANSLATES as any)['Level' + (user.currentLevel + 1)]}/>
                        }
                        {this.renderHeader()}
                        <FlatList
                            data={this.state.list}
                            style={{height: 460, width: '100%'}}
                            renderItem={({item}) => <View style={styles.item}>
                                {this.renderRow(item, user)}
                            </View>}
                        />
                    </SafeAreaView>
                )}
            </UserContext.Consumer>
        );
    }
}


const styles = StyleSheet.create({
    line: {
        backgroundColor: 'rgba(202, 205, 222, 1)',
        height: 1,
        width: 600,
        left: -28,
        position: 'relative'
    },
    placeHeader: {
        color: '#2E2D2D'
    },
    placeText: {
        color: MAIN_BAR_COLOR,
        fontWeight: '700',
        fontSize: 15,
        width: 48,
    },
    nameText: {
        color: '#2E2D2D',
        fontWeight: '700',
        width: Dimensions.get('window').width - 64 - 64 - 12,
        fontSize: 15,
    },
    pointText: {
        textAlign: 'center',
        color: '#2E2D2D',
        fontWeight: '700',
        fontSize: 15,
        width: 62,
    },
    coinText: {
        textAlign: 'center',
        color: '#2E2D2D',
        fontSize: 15,
        fontWeight: '700',
        width: 64,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        width: '100%'
    },
    selectedRow: {
        backgroundColor: '#EFF1F7'
    },
    container: {},
    item: {
        marginTop: 12,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 50,
    },
    textStyle: {
        fontSize: 16,
        marginLeft: 16,
    },
});

export const MAIN_BAR_COLOR = '#405CBF';

export const ORANGE_COLOR = '#FF9400';

export const RED = '#ED7372'


export const COLORS = {
    DARK_RED: '#BC2F2D',
    RED: RED,
    DARK_BLUE: MAIN_BAR_COLOR,
    BLUE: '#99ACEF',
    BLACK: '#2E2D2D',
    GRAY: "#CACDDE",
    MAIN_BAR_COLOR: MAIN_BAR_COLOR,
    ORANGE_COLOR: ORANGE_COLOR,
    LIGHT_BLACK: '#797B87',
    TEXT_COLOR: '#2E2D2D',
    SECONDARY_ACCESS_COLOR: '#5D76D1',
    YELLOW: '#FFBC60'
}
